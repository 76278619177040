<!-- FIXME K3 non va con ngModel --- ng-template appStructureFieldHost></ng-template-->

<ng-container *ngIf="structureField" >

  <ng-container [ngSwitch]="structureField.source">
    <app-structure-field-look-up [disabled]="disabled" [modifyData]="expansionSort"
                                 [structureDataFilter]="structureDataFilter" [name]="structureField.field || ''"
                                 [(ngModel)]="value" *ngSwitchCase="'expansion'"
                                 [structureField]="structureField"></app-structure-field-look-up>
  
    <ng-container *ngSwitchDefault [ngSwitch]="structureField.type">

      <!-- {{structureField.field}} - {{structureField.type}} -->

      <app-structure-field-look-up [mult]="mult" [disabled]="disabled" [structureDataFilter]="structureDataFilter"
                                   [name]="structureField.field || ''" [(ngModel)]="value" *ngSwitchCase="'LOOKUP'"
                                   [structureField]="structureField"></app-structure-field-look-up>
                                   
      <app-structure-field-flag [disabled]="disabled" [structureDataFilter]="structureDataFilter"
                                [name]="structureField.field || ''" [(ngModel)]="value"
                                *ngSwitchCase="'FLAG'" [structureField]="structureField"></app-structure-field-flag>


      <span *ngSwitchDefault> {{structureField | json}} </span>
    </ng-container>
  </ng-container>

</ng-container>

<!-- <ng-template  #noSource >
  no source
</ng-template> -->


