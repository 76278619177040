import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { fromEvent, map, debounceTime, distinctUntilChanged, take } from 'rxjs';
import { MaterialModule } from 'src/app/material/material/material.module';
import { DynaCrudRequest, selectConf, DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-default-search-header',
  templateUrl: './default-search-header.component.html',
  styleUrls: ['./default-search-header.component.scss'],
  imports: [CommonModule, MaterialModule, TranslateModule],
  standalone: true
})
export class DefaultSearchHeaderComponent implements OnInit, OnDestroy {

  private subs = new SubSink();
  private featureCode!: string;
  private crudRequest!: DynaCrudRequest;

  @ViewChild('defaultSearch', {static: true}) defaultSearch!: ElementRef;

  @Input() public placeholder!: string;

  @Input() defaultFeatureCode: string | undefined = undefined;

  private isFocus = false;

  constructor(private store: Store<State>) {}

  ngOnInit() {

    const resetSearch = true;
    let first = true;
    this.subs.add(this.store.pipe(select(selectConf), distinctUntilChanged()).subscribe(conf => {
        if (conf && conf.route) {
          // se c'è il conf.route --> c'è sempre il route
          this.featureCode = this.defaultFeatureCode ? this.defaultFeatureCode : (conf.route.code as string);
          
          if (conf.dynaData && conf.dynaData[this.featureCode] ) {
            this.crudRequest = conf.dynaData[this.featureCode].request || {searchRequest: {filter: {}}, customData: null};
          } else {
            this.crudRequest = {searchRequest: {filter: {}}, customData: null};
          }

          this.crudRequest.searchRequest = this.crudRequest.searchRequest || {filter: {}};
          this.crudRequest.searchRequest.filter = this.crudRequest.searchRequest.filter || {};
          if (resetSearch && !this.isFocus && this.crudRequest.searchRequest.filter.text != this.defaultSearch.nativeElement.value) {
            this.defaultSearch.nativeElement.value = this.crudRequest.searchRequest.filter.text || '';
          } else if ( first) {
            this.defaultSearch.nativeElement.value = this.crudRequest.searchRequest.filter.text || '';
            first = false;
          }
        }
      }
    ));


    fromEvent(this.defaultSearch.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      // Time in milliseconds between key events
      , debounceTime(300)
      // If previous query is diffent from current
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {

      let searchText = '';
      if (text.length > 1){
        searchText = text;

        // this.resetTable();
        
      }
      if ( this.featureCode && this.crudRequest.searchRequest && this.crudRequest.searchRequest.filter) {
        if (searchText !== this.crudRequest.searchRequest.filter.text) {
          this.crudRequest.searchRequest.filter.text = searchText;
          this.crudRequest.searchRequest.pagination = {page: 0 , size: 20};
          console.log(this.crudRequest);
          this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));
        }
      }

    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  resetTable() {
    if (this.crudRequest.searchRequest && this.crudRequest.searchRequest.pagination && parseInt(this.crudRequest.searchRequest.pagination.page.toString())  > 0) {
      const valuePagination = {page: 0, size: 20};
      this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, valuePagination));
    }
  }

  setFocus(b: boolean) {
    this.isFocus = b;
  }

}
