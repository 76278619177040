import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DynacrudApi} from '../../core/models/dynacrud';
import {environment} from '../../../environments/environment';
import { Movement, WarehouseSessionMovement } from '../models/entities';
import { Motivation } from 'src/app/features/warehouse/models/entities';
import _ from 'lodash';
import { SessionDetail } from 'src/app/features/warehouse/components/bodies/detail-session-wrapper/detail-session-wrapper.component';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
 

  constructor(private http: HttpClient) { }

  public sessionMov : any;

  get isSession() {
    return this.sessionMov;
  }

  set isSession(s : any) {
    this.sessionMov = s;
  }

  public saveMotivation(id: number, motivationValue: Motivation | undefined) {

    if ( motivationValue && motivationValue.name != undefined ) {
      return this.http.patch(`${environment.apiUrl}/v2/sessions/${id}/motivation`, null, {params: { motivation: motivationValue.name } } );
    }

    return this.http.patch(`${environment.apiUrl}/v2/sessions/${id}/motivation`, null, {params: { motivation: '' } } );

  }


  savePurchasePrice(idSession: number, movementId: number, priceA: any): Observable<DynacrudApi<Movement>> {

    return this.http.patch<DynacrudApi<Movement>>(environment.apiUrl + `/v2/sessions/${idSession}/movement/${movementId}/purchasePrice`, null, {params: {price: (priceA != null && priceA != undefined ) ? priceA : '' }});
  }


  getCurrentSession(id: number): Observable<DynacrudApi<WarehouseSessionMovement>> {
    return this.http.get<DynacrudApi<WarehouseSessionMovement>>(`${environment.apiUrl}/v2/sessions/${id}`);
  }

  changeCurrentSessionIn(sessionId: number , status: number): Observable<DynacrudApi<WarehouseSessionMovement>> {
    return this.http.patch<DynacrudApi<WarehouseSessionMovement>>(`${environment.apiUrl}/v2/sessions/${sessionId}/status`, status);
  }

  public completeSessionIn(sessionId: number , movementId: number): Observable<DynacrudApi<WarehouseSessionMovement>> {
      return this.http.patch<DynacrudApi<WarehouseSessionMovement>>(`${environment.apiUrl}/v2/sessions/${sessionId}/movements/${movementId}/complete`, null);
  }

  getSession (id: any) {
    this.sessionMov = id;
  }

  // changeCurrentSessionIn(currWarehouseId: number): Observable<DynacrudApi<WarehouseSessionStatus>> {
  //   return this.http.put<DynacrudApi<WarehouseSessionStatus>>(`${environment.apiUrl}/v2/warehouses/current`, currWarehouseId ).pipe( tap(w => this.sessionsChangeSub.next(w.data)));
  // }
}
