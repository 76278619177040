import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, map, throwError } from 'rxjs';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { TableEvent } from 'src/app/core/models/table-config';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { SubSink } from 'subsink';
import { WarehouseSessionService } from '../../../services/warehouse-session.service';
import { WarehouseSessionStatuses } from '../../../models/entities';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { WarehouseSession, WarehouseSessionMovement } from 'src/app/shared/models/entities';

@Component({
  selector: 'app-complete-session-action',
  templateUrl: './complete-session-action.component.html',
  styleUrls: ['./complete-session-action.component.scss']
})
export class CompleteSessionActionComponent implements OnInit, TableActionComponent, OnDestroy {

  subs = new SubSink();

  constructor(private sessionCompleteService: WarehouseSessionService, private sessionService: SessionService, private dialogSnack: DialogService, private store: Store<State>, private route: ActivatedRoute) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  data!: ActionData;
  event?: EventEmitter<TableEvent>;

  statusSession = WarehouseSessionStatuses;
  currSession!: WarehouseSessionMovement;

  ngOnInit(): void {
    this.sessionCompleteService.updatedDataSessionCompleted(false);

  }

  completeSession(sessionId: number, movementId: number) {
    // if (this.currSession && (this.currSession.stateId == this.statusSession.APPROVED) ) {

      this.subs.add(
        this.sessionService.completeSessionIn(sessionId, movementId).pipe(
          catchError(err => {
            return throwError(err);
          })
        ).subscribe( data => {
          if (data?.data) {
            this.dialogSnack.openSnack('dettaglo_sessione.notification.SessionCompleteSingle', 'update');
            this.sessionCompleteService.updatedDataSessionCompleted(true);
            this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
          }
        })
      );

    // }
  }

}
