import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { Observable, take, map } from 'rxjs';
import { DialogComponent } from '../components/dialog/dialog.component';
import { SnackDeleteComponent } from '../components/snack-delete/snack-delete.component';

export interface OptionsDialogOpen { title: string, message: string, cancelText: string, confirmText: string, content?: any }

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog, private snack: MatSnackBar) {}
  dialogRef!: MatDialogRef<DialogComponent>;
  snackBarRef!: MatSnackBarRef<SnackDeleteComponent>;

  
  public openSnack(option: any, icon?: string, time?: number, verticalPosition?: MatSnackBarVerticalPosition, horizontalPosition?: MatSnackBarHorizontalPosition) {
    this.snackBarRef = this.snack.openFromComponent(SnackDeleteComponent, {
      data: {
        content: option,
        icon
      },
      verticalPosition: verticalPosition ? verticalPosition : 'bottom',
      horizontalPosition: horizontalPosition ? horizontalPosition : 'center',

      duration: time ? time : 4000,
      panelClass: 'toastConfig',
    
    });
  }

  public closeBarRef() {
    this.snack.dismiss();
  }

  public cancel(): Observable<any>  {
    return this.dialogRef?.afterClosed().pipe(take(1), map(res => {
      return res;
    }
  ));
  }

  public open(options : OptionsDialogOpen ) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        content: options.content
      },
      hasBackdrop: true
    });
  }
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }
  ));
  }
}
