<!-- da fare test -->

<ng-container *ngIf="imageUrl">

  <img #trigger="cdkOverlayOrigin" cdkOverlayOrigin (click)="showimage($event); isOpen = !isOpen"
       [src]="imageUrl" [alt]="imageUrl" width="24" height="30" (error)="noImage(imageUrl)">
  
  <ng-template #cdkConOverlay="cdkConnectedOverlay" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
               [cdkConnectedOverlayOpen]="isOpen">
  
    <div class="d-flex panel_onfire justify-content-center flex-column align-items-center w-100">
  
      <div class="d-flex w-100" style="max-width: 200px;">
  
        <img [ngSrc]="imageUrl" NgOptimizedImage [alt]="imageUrl" width="200" height="300">
  
      </div>
  
    </div>
  </ng-template>
</ng-container>
