import { SearchRequest } from "src/app/core/models/dynacrud";
import { PaymentSatus, Customer, OrderStatusId } from "src/app/features/shop/models/entities";
import { Motivation } from "src/app/features/warehouse/models/entities";

export const KEYCONSTANTSETINSERT = '_' + 'svalidFor:' + 'test';
export const keyDialog = 'onIconfirmed';

// export const MotivationsSessions = ['acquistoInNegozio', 'sbustamentoBox',  'acquistoInMassa', 'acquistoInFiera', 'acquistoOnline', 'carteRegalate'];

/**
 * acquistoInNegozio,
    sbustamentoBox,
    acquistoInMassa,
    acquistoInFiera,
    acquistoOnline,
    carteRegalate */

// icons


export const back_to_tab = '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M331-492 160-663v143H80v-280h280v80H216l172 171-57 57ZM160-160q-33 0-56.5-23.5T80-240v-200h80v200h320v80H160Zm640-280v-280H440v-80h360q33 0 56.5 23.5T880-720v280h-80Zm80 80v200H560v-200h320Z"/></svg>';
export const pip = '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M80-520v-80h144L52-772l56-56 172 172v-144h80v280H80Zm80 360q-33 0-56.5-23.5T80-240v-200h80v200h320v80H160Zm640-280v-280H440v-80h360q33 0 56.5 23.5T880-720v280h-80ZM560-160v-200h320v200H560Z"/></svg>';
export const foward_2 = '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m640-280-57-56 184-184-184-184 57-56 240 240-240 240ZM80-200v-160q0-83 58.5-141.5T280-560h247L383-704l57-56 240 240-240 240-57-56 144-144H280q-50 0-85 35t-35 85v160H80Z"/></svg>';



export enum StoreNames {
  LOGS = 'logs'
}

export interface Logs extends Entity {
  name: string;
  code: string;
  search: {text: string, filters: any[]};
}

export interface Selectable {
  selected?: boolean;
}

export interface Entity extends Selectable {
  id?: any;
  obsolete?: boolean;
  dateCreated?: Date;
  modifiedDate?: Date;
  createdBy?: string;
  modifiedBy?: string;
  name?: string;
}

export function getFormatLingua(): string {
  let value: string | undefined = navigator ? navigator.language: undefined;
  let valueReal: string[] = [];
  if (value && value.includes('-')) {
    valueReal = value.split('-');
  } else {
    valueReal.push(navigator?.language || '');
  }
  return valueReal[0];


}




export interface Branded {
  brandId: number;
}

export interface Categoried {
  categoryId: number;
}


export enum TransferType {
  TRANS = "TRANS",
  UNLOAD = "OUT",
  products_setInsert = 'products_setInsert'
}

export interface Brand extends Entity {
  code?: string;
  name?: string;
}

export interface Category extends Entity {
  name: string;
  priceAnalysis?: boolean;
  macroCategory?: Category;
}

export interface Language extends Entity {
  name: string;
  englishName: string;
}

export interface Rarity extends Entity, Branded {
  description: string;
  brandId: number;
}

export interface WarehouseSessionMovement extends Entity {
  movement: Movement;
  stateId: number;
  status: WarehouseSessionStatus;
}

export interface WarehouseSessionStatus extends Entity {
  description: string | undefined;
  count: number;
}

export interface Movement extends Stockable {
  status: number;
  quantity: number;
  warehouseSession?: WarehouseSession;
  purchasePrice?: number;
}

export interface WarehouseSession extends Entity {
  name: string;
  status: WarehouseSessionStatus;
  priority: number;
  user: User;
  motivation?: Motivation;
  systemSession: boolean;
  stateId?: number;
}

export interface WarehouseSessionToPush extends Entity {
  name: string;
  status: WarehouseSessionStatus;
  priority: number;
  user: User;
  motivation?: string;
  systemSession: boolean;
  stateId?: number;
}

export interface User extends Entity {
  name: string;
  surname: string;
}

export interface Stockable extends Entity {
  combination: Combination;
  product: Product;
  comments: string;
  warehouse: Warehouse;
  itemKey: string;
}


export interface Warehouse extends Entity {
  name: string;
  description: string;
  priority: number;
  virtual: boolean;
  refill: boolean;
  manageMpOrders?: any;
}

export interface Combination extends Entity {
  [key: string]: any;
}

export interface Product extends Entity, Branded, Categoried, ExtensibleRows {
  name: string;
  number: number;
  color: string;
  expansion: Expansion;
  rarity: Rarity;
  category: Category;
  brand: Brand;
  imageName: string;
  cardData?: CardData;
  card_data: CardData;
  idMetaProduct?: number;
  purchasePrice?: number
}

export interface ExtensibleRows {
  languages?: Language[]; // for data
  conditions?: Condition[]; // for data
  warehouses?: Warehouse[]; // for data

  warehouseRow?: Warehouse;
  holoRow?: Holo;
  conditionRow?: Condition;
  languageRow?: Language;

  holos?: Holo[];
  active?: boolean;
  comment?: string;
  editable?: {[key: string]: any};
  quantity?: number
}

export interface CardData {
  edition: string;
  image: string;
  name: string;
}

export interface Order extends Entity {
  [key: string]: any;
  expiration: Date;
  description: string;
  paymentStatus?: PaymentSatus;
  size: number;
  count: number;
  dateCreated?: Date;
  marketReferenceStatus?: number;
  marketRefId?: string;
  warehouse: Warehouse;
  orderId?: number;
  customer?: Customer;
  orderDetails?: OrderDetail;
  orderStatusId?: OrderStatusId;
}

export interface OrderDetail extends Stockable {
  [key: string]: any;
  status: OrderStatusId;
  productDescription: string;
  price: number;
  errStockRefid?: number;
  origProductId?: number;
  whMovements?: Movement[];
  quantity?: number;
  actualPrice?: number;
  stock?: Stock;
  deletable: boolean;


}


export interface Expansion extends Entity, Branded {
  name: string;
  abbreviation: string;
  releaseDate: Date;
  englishName: string;
  isReleased: boolean;
  brandId: number;
  icon?: string | number;
  enName?: string;
}

export interface Holo extends Entity {
  code: string;
  name: string;
}

export interface Stock extends Stockable {
  [key: string]: any;
  quantity: number;
  tempQuantity?: number;
  avaible: number;
  actualPrice: number;
  tempQtyMkm?: number;
  tempQtyBalance?: number;
}

export interface Condition extends Entity {
  code: string;
  description: string;
}

export enum singleFeatureCode {
  dashboard = 'orders',
  carrello = 'cart',
  checkout = 'checkout',
  completedCart = 'viewComplete'
}

export interface CategoryStructure extends Entity {
  categoryId: number;
  brandId: number;
  productStructureId: number;
}

export interface ProductStructure extends Entity {
  version?: number;
  name?: string;
  fields?: ProductStructureField[]; // a livello prodotto
  stockfields?: ProductStructureField[];  // a livello stock
  allFields?: ProductStructureField[];
}

export interface ProductStructure extends Entity {
  version?: number;
  name?: string;
  fields?: ProductStructureField[]; // a livello prodotto
  stockfields?: ProductStructureField[];  // a livello stock
  allFields?: ProductStructureField[];
}

export interface ProductStructureField {
  field?: string;
  type?: ProductStructureFieldType;
  values: any[] | string;
  source?: string;
  enabled?: boolean;
  order?: number;
  orderKey?: any;
  stock?: boolean;
}

export enum ProductStructureFieldType {
  FLAG= 'FLAG',
  LOOKUP= 'LOOKUP',
  STRING_RANGE= 'STRING_RANGE'
}

export interface WarehouseSessionEdit {
  id: number;
  name: string;
  priority: number;
}

export interface ChangeMovement extends WarehouseChangeMovement {
  [key: string]: any;
}

export interface WarehouseChangeMovement extends Entity {
  cardId: number;
  cardData: CardData;
  combination: Combination;
  nextMovement?: ChangeMovement;
  previousMovement?: ChangeMovement;
  priority: number;
  warehouseMovementNumber: number;
  warehouseMovementRegistrationNumber: number;
  productId: number;
  quantity: number;
  status: number;
  warehouseSession: WarehouseSession;
  product: Product;
  warehouse: Warehouse;
}


export interface WorkingMovement extends Stockable {
  description: string;
  warehouseId: number;
  priority: number;
  quantity: number;
  user: User;
  systemSession: boolean;
  orderId: number;
  orderDetailId: number;
  sessionId: number;
  warehouseMovementNumber: number;
  chain: WorkingMovementChainItem[];
}

export interface WorkingMovementChainItem extends Entity {
  status: number;
  warehouseMovementRegistrationNumber: number;
  quantity: number;
  warehouseId: number;
  description: string;
}




export interface MenuSidenav {
  name: string
  route: string
  selectable: boolean
  children: ChildrenMenu[]
}

export interface ChildrenMenu {
  name: string
  route: string
  selectable: boolean
}


// Objeto con nombres de colores comunes y sus códigos hexadecimales
const nombresDeColores: {[k: string]: string} = {
  white: '#FFFFFF',
  black: '#000000',
  red: '#FF0000',
  green: '#008000',
  blue: '#0033ff',
  multicolor: '#FC7308',
  land: '#742701',
  colorless: '#808080'

};

export function convertirAHexadecimal(texto: string): any {
  const regexHexadecimal = /^#([A-Fa-f0-9]{6})$/;
  const regexRGB = /^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/;
  const regexHSL = /^hsl\((\d{1,3}), (\d{1,3})%, (\d{1,3})%\)$/;

  let valores: any;

  if (regexHexadecimal.test(texto)) {
    return texto.toUpperCase();
  } else if ((valores = regexRGB.exec(texto))) {
    const r = parseInt(valores[1]);
    const g = parseInt(valores[2]);
    const b = parseInt(valores[3]);
    return `#${componenteHexadecimal(r)}${componenteHexadecimal(g)}${componenteHexadecimal(b)}`;
  } else if ((valores = regexHSL.exec(texto))) {
    const h = parseInt(valores[1]);
    const s = parseInt(valores[2]);
    const l = parseInt(valores[3]);
    const rgb = hslARgb(h, s, l);
    return convertirAHexadecimal(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`);
  } else {
    const nombreColor = texto.toLowerCase();
    if (nombresDeColores.hasOwnProperty(nombreColor)) {
      return nombresDeColores[nombreColor];
    } else {
      return null;
    }
  }
}

function componenteHexadecimal(valor: number) {
  const componente = Math.min(255, Math.max(0, valor)); // Asegurarse de que el valor esté en el rango de 0 a 255
  return componente.toString(16).padStart(2, '0'); // Convertir a hexadecimal y asegurarse de que tenga dos dígitos
}

function hslARgb(h: number, s: number, l: number) {
  h = h / 360;
  s = s / 100;
  l = l / 100;

  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hueToRgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}

