import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { State } from 'src/app/store/shared/shared.reducers';

@Injectable({
  providedIn: 'root'
})
// fixme santiago possiamo aggiungere un Type of <COMPONENT>
export class HostAllRowsInColumnsService {

  constructor(private store: Store<State>) { }

  private dataSourceColumns = new BehaviorSubject<any>(null);
  dataRowToAll = this.dataSourceColumns.asObservable();


  private dataSourceColumns2 = new BehaviorSubject<any>(null);
  dataRowToAll2 = this.dataSourceColumns2.asObservable();

  updatedDataSelectionDATASELECT(data: any) {
    this.dataSourceColumns.next(data);
  }

  updatedDataSelection2(data: any) {
    this.dataSourceColumns2.next(data);
  }
}
