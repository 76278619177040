<ng-container>

  <div class="d-flex justify-content-between align-items-center w-100 gap-1 " (click)="$event.stopPropagation();">



    <div class="input-group input-group-sm d-flex p-0 h-100 ">

      <span *ngIf="!isEdit" class="input-group-text" id="inputGroup-sizing-sm">*</span>

      <!-- (input)="limitarDecimales($event)" -->

      <ng-container *ngIf="!isEdit ; else onlyPlainNumber">
        <input #myInputElement type="text" [disabled]="forzaRoma || isEdit"
          (input)="limitarDecimales($event)"
        
          
          (keyup.enter)="save(); $event.stopPropagation(); " (click)="$event.stopPropagation();"
          [matTooltip]="isEdit ? 'abilitare il salvataggio' : 'premere Invio per salvare'"
          class="form-control form-control-sm" placeholder="Aggiunge prezzo" [min]="-1"

          onkeypress=" return event && (event.charCode != 8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57)
                          || event.charCode == 46 || event.charCode == 44 ) "

          [ngClass]="{'has_error': applyPriceGenerated['active']  != undefined && applyPriceGenerated['active'] 
          == false && (priceToEdit == undefined || priceToEdit == null ) }"

          [(ngModel)]="priceToEdit" aria-label="Small" aria-describedby="inputGroup-sizing-sm">

      </ng-container>

      <ng-template #onlyPlainNumber>
        <ng-container *ngIf="priceToEdit != undefined; else undefinedValue">
          {{priceToEdit}}

        </ng-container>

        <ng-template #undefinedValue>
          -
        </ng-template>
      </ng-template>



    </div>

    <div class="d-flex edit">
      <button color="primary" matTooltip="edita o aggiunge prezzo di acquisto" [disabled]="forzaRoma || isMovement()"
        (click)="isEdit = !isEdit; !isEdit ?
        priceToEdit = row.purchasePrice || null :
        priceToEdit = this.purchaseValueDefault != undefined ?
        this.purchaseValueDefault : undefined ; $event.stopPropagation();" mat-icon-button>
        <mat-icon>
          euro
        </mat-icon>
      </button>
    </div>

  </div>
</ng-container>