import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Filter, OperationType } from 'src/app/core/models/dynacrud';
import { DynaCrudRequest, DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';

@Component({
  selector: 'app-stock-template-filters',
  templateUrl: './stock-template-filters.component.html',
  styleUrls: ['./stock-template-filters.component.scss']
})
export class StockTemplateFiltersComponent implements OnInit {

  private crudRequest: DynaCrudRequest = { searchRequest: {} };
  constructor(private store: Store<State>) { }

  ngOnInit(): void {
    let first = true;

    if (first) {
      this.search();
      first = false;
    }

  }

  private search() {
    const possibleFilters: Filter[] = [{value: 'false', operation: OperationType.EQ, field: 'obsolete'}];

    for (const f of possibleFilters) {
      if (f.operation === OperationType.IN) {
        const arrayValue = f.value as any[];
        if (arrayValue.length === 1) {
          f.operation = OperationType.EQ;
          f.value = arrayValue[0];
        }
      }
    }
    let filterByObj: Filter | undefined = undefined;
    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = { operation: OperationType.AND, filters: possibleFilters };
      } else {
        filterByObj = possibleFilters[0];
      }
    }


    this.crudRequest.searchRequest = { filter: { text: '', filterBy: filterByObj } };
    this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));

  }

}
