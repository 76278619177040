import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {BaseAction} from '../store.actions';
import * as _ from 'lodash';
import * as rootReducers from '../store.reducers';
import {DynacrudApi, OperationType, SearchRequest} from '../../core/models/dynacrud';
import {RouteConf} from '../../core/models/route-config';
import {StatusCount} from '../../features/shop/models/entities';

export const selectConf = createFeatureSelector<State, DynaConf>('conf');

export const selectRouteConf: MemoizedSelector<State, RouteConf> = createSelector(selectConf, (state: DynaConf) => state?.route);
export const selectFeatureCode: MemoizedSelector<State, string | undefined> = createSelector(selectRouteConf, (state: RouteConf) => state?.code);

export const selectCurrentDynaData: MemoizedSelector<State, CurrentDynaData> = createSelector(selectConf, (state: DynaConf) => {
  if (state && state.route && state.route.code) {
    const feature: CurrentDynaData = _.cloneDeep(state.dynaData[state.route.code]) as CurrentDynaData;
    feature.route = state.route;
    return feature;

  } else {
    const elseFesture: CurrentDynaData = {
      route: state.route,
      request: null as any,
      data: null as any,
      routeData: null as any
    };
    return elseFesture;
  }
});

export const selectCurrentDynaDataOffSessions: MemoizedSelector<State, CurrentDynaData> = createSelector(selectConf, (state: DynaConf) => {
  if (state && state.route && state.route.code) {
    // const feature: CurrentDynaData = _.cloneDeep(state.dynaData['session']) as CurrentDynaData;
    const featureWrappsessions =  _.cloneDeep(state.dynaData['sessions_wrappers']) as CurrentDynaData;
    featureWrappsessions.route = state.route;
    // if ( featureWrappsessions.routeData && featureWrappsessions.routeData.selection ) {

    //   (feature.routeData.selection as {all: boolean, rows: {selected: boolean, row: any}[] }).rows.push(...featureWrappsessions.routeData.selection.rows);
    // }
    return featureWrappsessions;

  } else {
    const elseFesture: CurrentDynaData = {
      route: state.route,
      request: null as any,
      data: null as any,
      routeData: null as any
    };
    return elseFesture;
  }
});


export const selectDynaCrudRequest: MemoizedSelector<State, DynaCrudRequest | undefined> = createSelector(selectCurrentDynaData, (state: CurrentDynaData) => state.request);

export const selectDynaCrudData: MemoizedSelector<State, DynaCrudRequest | undefined> = createSelector(selectCurrentDynaData, (state: CurrentDynaData) => state.data);

export const selectRouteData: MemoizedSelector<State, DynaCrudData | undefined> = createSelector(selectCurrentDynaData, (state: CurrentDynaData) => state.routeData);

export const selectCurrentSelection: MemoizedSelector<State, any> = createSelector(selectCurrentDynaData, (state: CurrentDynaData) => {
  return state?.routeData?.selection;
});

export const selectCurrentSelectionOffSessions: MemoizedSelector<State, any> = createSelector(selectCurrentDynaDataOffSessions, (state: CurrentDynaData) => {
  return state?.routeData?.selection;
});


export const selectCurrentItems: MemoizedSelector<State, any> = createSelector(selectCurrentDynaData, (state: CurrentDynaData) => {
  return state?.routeData?.items;
});
export const selectCurrentRow: MemoizedSelector<State, any> = createSelector(selectCurrentDynaData, (state: CurrentDynaData) => {
  return state?.routeData?.rowSelect;
});

export const selectReloadPage: MemoizedSelector<State, any> = createSelector(selectCurrentDynaData, (state: CurrentDynaData) => {
  return state?.routeData?.resetPage;
});


export interface DynaConf {
  route: RouteConf;
  dynaData: DynaData;
}

export interface RouteData {
  selection?: any;
  selectionItem?: any;
  customData?: any;
  items?: StatusCount[];
  rowSelect?: RowSelect;
  resetPage?: boolean;
  warehouses?: any | any[];
}

export interface RowSelect {
  select: boolean;
  row: any;

  [key: string]: any;
}

export interface FeatureData {
  request?: DynaCrudRequest;
  data?: DynaCrudData;
  routeData?: RouteData;
}

export interface DynaData {
  [featureCode: string]: FeatureData;
}

export interface CurrentDynaData extends FeatureData {
  route: RouteConf;
}

export interface State extends rootReducers.State {
  conf: DynaConf;
}

export interface DynaCrudRequest {
  customData?: any;
  searchRequest?: SearchRequest;
}

export interface DynaCrudRequestOnlyWithCode {
  customData?: any;
  searchRequest?: SearchRequest;
  code: string
}

export interface DynaCrudData {
  response?: DynacrudApi<any>;
  customData?: any;
}

const initialState: DynaConf = {route: null as any, dynaData: {}};

export enum DynaConfActionType {
  ConfChange = '[DynaConf] ConfChange',
  FilterChange = '[DynaConf] FilterChange',
  FilterWithCode = '[DynaConf] FilterWithCode',
  PaginationSortChange = '[DynaConf] PaginationSortChange',
  SelectionChange = '[DynaConf] SelectionChange',
  SelectionItem = '[DynaConf] SelectionItem',
  // DeletedItems = '[DynaConf] DeletedItems',
  Reset = '[DynaConf] Reset',
  Refresh = '[DynaConf] Refresh',
  SearchSuccess = '[DynaConf] SearchSuccess',
  ChangeItems = '[DynaConf] ChangeItems',
  RowSelect = '[DynaConf] RowSelect',
  ResetPage = '[DynaConf] ResetPage',
  /*
    WarehousesSelect = '[DynaConf] WarehousesSelect',
    Add =  '[Dynacrud] Add',
    AddSuccess =  '[Dynacrud] Add',
    Edit =  '[Dynacrud] Edit',
    EditSuccess =  '[Dynacrud] EditSuccess',
    Remove = '[Dynacrud] Remove',
    RemoveSuccess = '[Dynacrud] RemoveSuccess',
    HasChanges = '[Dynacrud] HasChanges',

    // FIXME a che serve il singolo get?
    Load =  '[Dynacrud] Load',
    LoadSuccess =  '[Dynacrud] LoadSuccess',
  */
}

const defualtDynaCrudRequest: DynaCrudRequest = {searchRequest: {filter: {}}};

export function dynaConfReducer(state: DynaConf = initialState, action: BaseAction): DynaConf {
  switch (action.type) {

    case DynaConfActionType.Reset: {
      const newState = {...initialState};
      return newState;
    }

    case DynaConfActionType.RowSelect: {

      const newState = {...state};
      newState.dynaData[newState.route.code as string] = newState.dynaData[newState.route.code as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
      const feature: FeatureData = newState.dynaData[newState.route.code as string];
      feature.routeData = feature.routeData || {};
      feature.routeData.rowSelect = action.payload;
      return newState;

    }
    case DynaConfActionType.ResetPage: {
      const newState = {...state};
      newState.dynaData[newState.route.code as string] = newState.dynaData[newState.route.code as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
      const feature: FeatureData = newState.dynaData[newState.route.code as string];
      feature.routeData = feature.routeData || {};
      (feature.routeData.resetPage as boolean) = action.payload;
      if (feature.request && feature.routeData.resetPage) {
        feature.request.customData = _.pick(feature.request.customData, ['brand', 'category', 'categorys', 'selectedStatus']);
      }
      return newState;
    }

    case DynaConfActionType.Refresh: {
      const newState = {...state};
      return newState;
    }
    case DynaConfActionType.ConfChange: {
      const newState = {...state};
      newState.route = action.payload;
      if (newState.route) {
        newState.dynaData[newState.route.code as string] = newState.dynaData[newState.route.code as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
      }

      return newState;
    }
    case DynaConfActionType.PaginationSortChange: {
      const newState = {...state};
      // FIXME: questo non va bene
      if (newState && newState.route && newState.route.code && !action.payload['code']) {


        newState.dynaData[newState.route.code as string] = newState.dynaData[newState.route.code as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
        const feature: FeatureData = newState.dynaData[newState.route.code as string];
        const searchRequest = (feature.request as DynaCrudRequest).searchRequest = feature.request?.searchRequest || {};

        searchRequest.pagination = action.payload.pagination;
        searchRequest.sort = action.payload.reset ? searchRequest.sort : action.payload.sort;
      } else {


        if (newState && newState.route && newState.route.code && action.payload['code']) {

          newState.dynaData[action.payload['code'] as string] = newState.dynaData[action.payload['code'] as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
          const feature: FeatureData = newState.dynaData[action.payload['code'] as string];
          const searchRequest = (feature.request as DynaCrudRequest).searchRequest = feature.request?.searchRequest || {};


          searchRequest.pagination = action.payload.pagination;
          searchRequest.sort = action.payload.reset ? searchRequest.sort : action.payload.sort;

        }

      }
      return newState;
    }
    case DynaConfActionType.SelectionChange: {
      

      const newState = {...state};
      newState.dynaData[action.payload?.code || newState.route.code as string] = newState.dynaData[action.payload?.code || newState.route.code as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
      const feature: FeatureData = newState.dynaData[action.payload?.code || newState.route.code as string];
      feature.routeData = feature.routeData || {};
      feature.routeData.selection = action.payload ? action.payload : {all: false, rows: []};
      return newState;
    }

    case DynaConfActionType.SelectionItem: { // fixme santiago MI SERVE PER TUTTE DUE MODI --> TRANSFER TYPE "TRANSFER" e TYPE "UNLOAD" ??
      const newState = {...state};
      newState.dynaData[newState.route.code as string] = newState.dynaData[newState.route.code as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
      const feature: FeatureData = newState.dynaData[newState.route.code as string];
      feature.routeData = feature.routeData || {};


      if (feature.routeData.selection.rows.length >= 1) {
        feature.routeData.selection.rows.filter((item: {
          row: { id: any; };
        }) => item.row.id !== action.payload.row.id);
        feature.routeData.selection.rows.push(action.payload.row.id ? action.payload : {selected: false, row: {}});
        feature.routeData.selection.rows = _.uniqWith(feature.routeData.selection.rows, _.isEqual); // necessariamente block tutto quello che arriva ?
      } else {
        feature.routeData.selection.rows.push(action.payload.row.id ? action.payload : {selected: false, row: {}});
        feature.routeData.selection.rows = _.uniqWith(feature.routeData.selection.rows, _.isEqual); // necessariamente block tutto quello che arriva ?

      }

      return newState;
    }

    case DynaConfActionType.ChangeItems: {
      const newState = {...state};
      newState.dynaData[newState.route.code as string] = newState.dynaData[newState.route.code as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
      const feature: FeatureData = newState.dynaData[newState.route.code as string];
      feature.routeData = feature.routeData || {};
      feature.routeData.items = action.payload;
      return newState;
    }

    case DynaConfActionType.FilterWithCode: {
      // TODO: mi serve if code ? 
      const code = (action.payload.code as string);

      const newState = {...state};
      newState.dynaData[code as string] = newState.dynaData[code as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
      const feature: FeatureData = newState.dynaData[code as string];

      if (feature && feature.request) {
        feature.request.customData = action.payload.customData ? _.cloneDeep(action.payload.customData) : null;
        if (feature.request.searchRequest) {
          feature.request.searchRequest.filter = action.payload.searchRequest ? _.cloneDeep(action.payload.searchRequest.filter) : null;

          if (feature.request.searchRequest.pagination && parseInt(feature.request.searchRequest.pagination.length as string) > 20 && (parseInt(feature.request.searchRequest.pagination.page as string) > 0) && !action.payload.searchRequest.pagination?.active) {
            feature.request.searchRequest.pagination = {...feature.request.searchRequest.pagination};
          } else {
            feature.request.searchRequest.pagination = {
              ...action.payload.searchRequest.pagination || {
                page: 0,
                size: 20
              }
            };
          }

        }
      }


      return newState;

    }

    case DynaConfActionType.FilterChange: {

      const newState = {...state};
      newState.dynaData[newState.route.code as string] = newState.dynaData[newState.route.code as string] || {request: _.cloneDeep(defualtDynaCrudRequest)};
      const feature: FeatureData = newState.dynaData[newState.route.code as string];

      if (feature && feature.request) {
        feature.request.customData = action.payload.customData ? _.cloneDeep(action.payload.customData) : null;
        if (feature.request.searchRequest) {
          feature.request.searchRequest.filter = action.payload.searchRequest ? _.cloneDeep(action.payload.searchRequest.filter) : null;

          if (feature.request.searchRequest.pagination && parseInt(feature.request.searchRequest.pagination.length as string) > 20 && (parseInt(feature.request.searchRequest.pagination.page as string) > 0) && !action.payload.searchRequest.pagination?.active) {
            feature.request.searchRequest.pagination = {...feature.request.searchRequest.pagination};
          } else {
            feature.request.searchRequest.pagination = {
              ...action.payload.searchRequest.pagination || {
                page: 0,
                size: 20
              }
            };
          }

        }
      }


      return newState;
    }
    default:
      return state;
  }

}


