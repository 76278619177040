<ng-container *ngIf="multiple">
    <ng-container *ngIf="!readonly; else readonlyOut" class="reset">
        <ng-container *ngIf="source == 'expansion' || source == 'language'; else noExpansion">
            <mat-form-field *ngIf="!readonly; " class="selectOPT" appearance="outline">
                <mat-label style="text-transform: capitalize;" *ngIf="label">{{label | translate}}</mat-label>
                <mat-select (openedChange)="isOpenBro($event)" [(ngModel)]="value" [disabled]="disabled" #selectRef
                    multiple [compareWith]="compareEntity">

                    <mat-option>
                        <ngx-mat-select-search #valueNgxSelectSearch  [hideClearSearchButton]="true"  ngModel placeholderLabel="Search espansione"
                            noEntriesFoundLabel="'Nessun resultato'"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let item of itemsMemory" [value]="item">
                        <div class="item_cont__">
                            <span>{{showValue(item)}}</span>
                            <app-column-icon-field-select [item]="item"
                                [brandId]="item.brandId"></app-column-icon-field-select>
                        </div>
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="value?.length > 1 && !hideHint">
                    ({{value?.length }} {{ 'total' | translate}})
                </mat-hint>

                <button mat-icon-button matSuffix (click)="clearValuesExpansion(); $event.preventDefault()">
                    <mat-icon>
                        clear
                    </mat-icon>
                </button>
            </mat-form-field>
        </ng-container>

        <ng-template #noExpansion>
            <mat-form-field *ngIf="!readonly; " class="selectOPT" appearance="outline">
                <mat-label style="text-transform: capitalize;" *ngIf="label">{{label | translate}}</mat-label>
                <mat-select [required]="isRequiredVal" [(ngModel)]="value" [disabled]="disabled" #selectRef multiple
                    [compareWith]="compareEntity">

                    <!-- <mat-option *ngIf="source == 'language'">
                        <ngx-mat-select-search [hideClearSearchButton]="true"  #valueNgxSelectSearch ngModel  placeholderLabel="Search {{label}}"
                            noEntriesFoundLabel="'Nessun resultato'"></ngx-mat-select-search>
                    </mat-option> -->

                    <mat-option *ngFor="let item of itemsMemory" [value]="item">
                        {{ showValue(item) }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="value?.length > 1 && !hideHint">
                    ({{value?.length }} {{ 'total' | translate}})
                </mat-hint>

                <!-- <button *ngIf="source == 'language'" mat-icon-button matSuffix (click)="clearValuesExpansion(); $event.preventDefault()">
                    <mat-icon>
                        clear
                    </mat-icon>
                </button> -->
            </mat-form-field>

        </ng-template>

    </ng-container>
</ng-container>



<ng-container *ngIf="!multiple">
    <ng-container *ngIf="!readonly; else readonlyOut">
        <mat-form-field class="selectOPT" appearance="outline">
            <mat-label style="text-transform: capitalize;" *ngIf="label">{{label | translate}}</mat-label>
            <mat-select [compareWith]="compareEntity" [disabled]="disabled" [(ngModel)]="value">
                <mat-option *ngFor="let item of itemsMemory" [value]="item">
                    {{showValue(item)}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</ng-container>

<ng-template #readonlyOut>
    <mat-form-field class="selectOPT" appearance="outline">
        <mat-label style="text-transform: capitalize;" *ngIf="label">{{label | translate}}</mat-label>
        <input [readonly]="true" matInput type="text" value="{{showValue(value)}}">
    </mat-form-field>
</ng-template>