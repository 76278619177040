import * as rootReducers from '../store.reducers';
import { Auth, AuthToken } from "src/app/auth/models/auth";
import { environment } from "src/environments/environment";
import { BaseAction } from "../store.actions";
import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';


export interface State extends rootReducers.State {
  auth: Auth;
}


const initialState: Auth = getInitialState();

function getInitialState() {
  const defaultAuth = {
    isAuthenticated: false,
    username: null,
    token: {
      type: null,
      value: null
    },
    roles: []
  };
  const authInStorage = sessionStorage.getItem(environment.appVersion + '_auth');
  if (authInStorage) {
    try {
      return JSON.parse(authInStorage) || defaultAuth;
    } catch (e) {
      return defaultAuth;
    }
  } else {
    return defaultAuth;
  }
}

export enum AuthActionType {
  isAuthenticated = '[Auth] isAuthenticated',
  Token = '[Auth] Token',
  Roles = '[Auth] Roles',
  FULL = '[Auth] Full'
}


export function authReducer(state: Auth = initialState, action: BaseAction): Auth {
  switch (action.type) {
    case AuthActionType.FULL: {

      return action.payload || initialState as Auth;

    }
    case AuthActionType.isAuthenticated: {

      return {
        ...state,
        isAuthenticated: action.payload || !initialState.isAuthenticated
      } as Auth;
    }
    case AuthActionType.Token: {
      return {
        ...state,
        token: action.payload || initialState.token
      } as Auth;

    }
    case AuthActionType.Roles: {
      return {
        ...state,
        roles: action.payload || initialState.roles
      } as Auth;

    }
    default:
      return state;
  }

}


export const FEATURE_NAME = 'auth';
export const selectAuth = createFeatureSelector<Auth>(
  FEATURE_NAME
);

export const selectIsAutenticated: MemoizedSelector<State, boolean> = createSelector(selectAuth, (state: Auth) => state.isAuthenticated);
export const selectAuthToken: MemoizedSelector<State, AuthToken> = createSelector(selectAuth, (state: Auth) => state.token);
export const selectAuthRoles: MemoizedSelector<State, string[]> = createSelector(selectAuth, (state: Auth) => state.roles);
