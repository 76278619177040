<ng-container>
    <div class="container-add-button">

        <button mat-icon-button [matMenuTriggerFor]="addMenu" id="user-dropdown-toggler" class="add-button">
            <mat-icon>add_circle_outline</mat-icon>
        </button>

        <mat-menu #addMenu="matMenu" xPosition="before" class="addMenu">

            <ng-container *ngFor="let item of SELECTS_AVAIVABLE">

                <button class="d-flex w-100 bs_cs" *ngIf="item.valid" mat-menu-item matTooltip="{{item.tooltip}}" (click)="newGo(item.route)">
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <div class="d-flex align-items-center">
                            <mat-icon> {{item.icon}} </mat-icon>
                            {{'newPulsanti.'+item.translate | translate}}

                        </div>

                        <div class="d-flex align-items-center">
                            <span style="text-transform: uppercase; font-size: 12px;">
                                {{item.tooltip}}
                            </span>

                        </div>

                    </div>
                </button>

            </ng-container>

        </mat-menu>
    </div>


</ng-container>