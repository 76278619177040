import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface TypeDialog {
  cancelText: string,
  confirmText: string,
  message: string,
  title: string,
  content?: any;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  
  constructor( @Inject(MAT_DIALOG_DATA) public data:TypeDialog, private mdDialogRef: MatDialogRef<DialogComponent> ) { }

  ngOnInit() { }

  public cancel() {
    this.closeDialog(true);
  }

  closeDialog(val: any) {
    if (val) {
      this.mdDialogRef.close(false);
    }
  }

  public close(value: any) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }

}
