import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

export interface reloadToTable {reload: boolean, crudRequest: any}


@Injectable({
  providedIn: 'root'
})
export class SharedService {


  // FIXME: for holo, condition, language on setInsert
  private nextReload = new BehaviorSubject(false);

  public nextReloadData = this.nextReload.asObservable();

  public nextReloadSave(v: boolean) {
    this.nextReload.next(v);
  }


  // FIXME: para saber cuando deshabilitar paginacion y sort y retornar a la normalidad
  private nextNormal = new BehaviorSubject<reloadToTable>({reload: false, crudRequest: {}});

  public nextNormalData = this.nextNormal.asObservable();

  public nextNormalDataSave(v: reloadToTable ) {
    this.nextNormal.next(v);
  }


  constructor() { }
}
