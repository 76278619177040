<div class="searchGenerale noHint">
 
    <mat-form-field>
      <input (focusin)="setFocus(true)" (focusout)="setFocus(false)" matInput #defaultSearch
        [placeholder]="placeholder ? (placeholder | translate) : ('default.search' | translate)">
      <button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
      <mat-hint *ngIf="defaultSearch.value.length > 0 && defaultSearch.value.length < 3 ">{{'search_min_length' |
        translate: {numCharacters: 3} }}</mat-hint>
    </mat-form-field>


</div>