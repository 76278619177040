
<mat-form-field class="selectOPT mar" appearance="outline">
  <mat-label style="text-transform: capitalize;">{{structureField.field || '' | translate}}</mat-label>
  <mat-select [name]="structureField.field || ''" [(ngModel)]="value" [disabled]="disabled" [required]="isRequired">
    <mat-option
      *ngFor="let item of [{value: null, name: 'all'}, {value: true, name: 'yes'} , {value: false, name: 'no'}]"
      [value]="item.value">
      {{item.name | translate}}
    </mat-option>
  </mat-select>
</mat-form-field>