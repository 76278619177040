import { Component } from '@angular/core';

@Component({
  selector: 'app-back-page-button',
  templateUrl: './back-page-button.component.html',
  styleUrls: ['./back-page-button.component.scss']
})
export class BackPageButtonComponent {

  constructor() { }
  ngOnInit(): void { }
  back() { window.history.back(); }

}
