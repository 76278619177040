import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectAuth, State } from '../../store/auth/auth.reducers';
import * as moment from 'moment';
import jwtDecode from 'jwt-decode';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SubSink } from 'subsink';
import { Auth } from 'src/app/auth/models/auth';
import { DialogService } from '../services/dialog.service';

@Injectable()
export class SharedHttpInterceptor implements HttpInterceptor {
  private whitelistedUnauthorizedRoutes = /^.*(login|register).*$/;
  private auth!: Auth;
  // private rimani: boolean = undefined;
  subs = new SubSink();

  constructor(private store: Store<State>, private router: Router, private dialog: DialogService, private authService: AuthService) {
    this.store.pipe(select(selectAuth)).subscribe(auth => {


      this.auth = auth;
      if (this.auth?.token?.value && this.auth.isAuthenticated) {
        const decodedJWT: { [key: string]: any } = jwtDecode(this.auth?.token?.value);
        const currentExpDate = timeConverter(decodedJWT['exp']);
        localStorage.setItem('credentialsDate', currentExpDate);

      }


    });
  }

  first = true;
  auth2: any = undefined;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {
      'Content-Type': 'application/json',
      'api-key': environment.apiKey,
      'cache-control': environment.cacheControl,
      'context': "User"
    };

    const dateFromSessionStorage = localStorage.getItem('credentialsDate');

    if (dateFromSessionStorage) {

      const fullVeryDate = new Date(dateFromSessionStorage);
      const read = moment( fullVeryDate);
      const today = new Date();
      let diff = moment(read).diff(moment(today), 'minutes');
      const nCookies = localStorage.getItem('paramsToauthgAlactus');


      if (nCookies) {

        const objectUserCookies = nCookies;
        const readObject1 = objectUserCookies.indexOf('{');
        const readObject2 = objectUserCookies.indexOf('}');

        const valTree = objectUserCookies.substring(readObject1, readObject2 + 1);
        const credentials = JSON.parse(valTree);

        if (moment(fullVeryDate).isValid() && diff >= 0 && diff < 80) {
          // ''
          localStorage.removeItem('credentialsDate');
          this.login(credentials.username, credentials.password, false);
        } else {

          // ''
          if (diff < 0) {

            // ''
            this.authService.logout();
          }


        }

      }
    }


    request = request.clone({
      setHeaders: this.auth && this.auth.isAuthenticated ?
        {
          ...{ Authorization: `${this.auth.token.type} ${this.auth.token.value}` },
          ...headers
        } :
        headers
    });

    // if (this.auth?.isAuthenticated) {

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error('Error Event');
          } else {
            if (error.status === 401) {
              this.handleUnauthorizedAccess(request);
              this.dialog.openSnack(error.status + ' credenziali non valide ', 'info');
            } else if (error.status === 403) {
              this.router.navigateByUrl('/unauthorized').then();
            } else if (error.status !== 401 && error.status !== 403 && error.status !== 404) {
              this.dialog.openSnack(error.status + ' ' + error.message, 'info');
            }
          }
        } else {
          console.error('some thing else happened');
        }
        return throwError(error);
      })
    );

    // }


  }

  handleUnauthorizedAccess(req: HttpRequest<any>) {

    // Regex check
    if (req.url.match(this.whitelistedUnauthorizedRoutes)) {
      return;
    }

    this.router.navigateByUrl('/auth/login').then();
  }

  private login(username: any, pass: any, isOnloginPage: boolean) {
    //
    this.authService.login(username, pass).pipe(take(1), catchError(err => {
      return throwError(err);
    })).subscribe((res: any) => {

      const decodedJWT: { [key: string]: any } = jwtDecode(res.token);

      const currentExpDate = timeConverter(decodedJWT['exp']);
      localStorage.setItem('credentialsDate', currentExpDate);

      if (isOnloginPage) {
        this.router.navigateByUrl('app/shop');
      }

    });
  }
}

function timeConverter(UNIX_timestamp: number) {
  let a = new Date(UNIX_timestamp * 1000);
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let year = a.getFullYear();
  let month = months[a.getMonth()];
  let date = a.getDate();
  let hour = a.getHours();
  let min = a.getMinutes();
  let sec = a.getSeconds();
  return date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
}


