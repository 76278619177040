import { Action, ActionReducer } from "@ngrx/store";
import { merge } from "lodash";
import { environment } from "src/environments/environment";


function setSavedState(state: any, localStorageKey: string) {
  localStorage.setItem(localStorageKey, JSON.stringify(state));

}
function getSavedState(localStorageKey: string): any {
  const state = localStorage.getItem(localStorageKey);
  if (state) {
    return JSON.parse(localStorage.getItem(localStorageKey) || '{}');
  } else {
    return {};
  }

}


// the keys from state which we'd like to save.
//const stateKeys = ['layout.theme'];
// the key for the local storage.
const localStorageKey = environment.appVersion + '_stat ';


export function storageMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
  let onInit = true; // after load/refresh…
  return function (state: S, action: A): S {
    // reduce the nextState.
    // ''
    const nextState = reducer(state, action);
    // init the application state.
    if (onInit) {
      onInit = false;
      const savedState = getSavedState(localStorageKey);
      savedState.init = false;
      return merge(nextState, savedState);
    }
    // save the next state to the application storage.
    //const stateToSave = pick(nextState, stateKeys);
    setSavedState(nextState, localStorageKey);
    return nextState;
  };
}
