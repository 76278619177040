import { Component, HostListener } from '@angular/core';
import { WindowRef } from 'src/app/window';

@Component({
  selector: 'app-back-top-button',
  templateUrl: './back-top-button.component.html',
  styleUrls: ['./back-top-button.component.scss']
})
export class BackTopButtonComponent {

  windowScrolled!: boolean;

  @HostListener('window:scroll', ['$event']) onWindowScroll() {
      if (window.pageYOffset > 200) {
        this.windowScrolled = true;
      }
      if (this.windowScrolled && window.pageYOffset < 180) {
        this.windowScrolled = false;
      }
  }
  constructor(private winRef: WindowRef) { }
  gotoTop() {
    this.winRef.nativeWindow.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
