import { Component, OnInit } from '@angular/core';
import { DefaultColumnComponent } from '../default-column/default-column.component';

@Component({
  selector: 'app-default-date-column',
  templateUrl: './default-date-column.component.html',
  styleUrls: ['./default-date-column.component.scss']
})
export class DefaultDateColumnComponent extends DefaultColumnComponent implements OnInit {

  format = 'medium';

  override ngOnInit(): void {
    if (this.data.field.attributes ) {
      this.format = this.data.field.attributes['dateFormat'] || this.format;
    }
  }

}
