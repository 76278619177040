import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, distinctUntilChanged, catchError, throwError, debounceTime } from 'rxjs';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { TableEvent } from 'src/app/core/models/table-config';
import { TransferType } from 'src/app/shared/models/entities';
import { HostAllRowsInColumnsService } from 'src/app/shared/services/host-all-rows-in-columns.service';
import { HostColumnEditService } from 'src/app/shared/services/host-column-edit.service';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { selectFeatureCode, DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { WarehouseMovementDTO } from '../../../models/entities';
import { AddMovementService } from '../../../services/add-movement.service';
import { CountRowsService } from '../../../services/count-rows.service';
import { SuccesComponent } from '../../columns/select-warehouse-change-trans-column/select-warehouse-change-trans-column.component';
import { whMovements } from '../add-movement-session-action/add-movement-session-action.component';

@Component({
  selector: 'app-add-movement-session-action-all',
  templateUrl: './add-movement-session-action-all.component.html',
  styleUrls: ['./add-movement-session-action-all.component.scss']
})
export class AddMovementSessionActionAllComponent implements OnInit, TableActionComponent, OnDestroy {

  private subs = new SubSink();
  private result: any;
  data!: ActionData;
  event?: EventEmitter<TableEvent>;
  currentSelectProp: { [key: string]: any } = {};
  currentvalue: any;
  idSessionResult!: number;
  private idSession!: number; // lo elimino?
  codeFeature: string | undefined;

  rows$!: Observable<any[]>;
  arrayRows: any[] = [];


  constructor(private store: Store<State>, private hostServiceColumnSingle: HostColumnEditService, private _countRowsService: CountRowsService,
    private hostallRows: HostAllRowsInColumnsService, private addMovementService: AddMovementService, private _cdref: ChangeDetectorRef, // todo santiago un change detector custom ?**/
    private activatedRoute: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar) {
    // fixme santiago custom aggiungere delete :)
    this._countRowsService.deleteRow();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this._countRowsService.deleteRow();
  }

  private getCode(code: string): string | undefined {
    if (code) {
      return code;
    }
    return undefined;
  }


  public valid(loadPush?: boolean) : any | undefined {

    const TYPE_ = TransferType;

    if (TYPE_.TRANS == this.codeFeature) {
      if (this.currentvalue?.rows?.length > 0) {
        if (loadPush) { // pootrei usarlo per una gestione in cui non serve piu il service behavior :)
          return (this.currentvalue.rows as []).map(item => {
            const newItem: any = item;
            newItem.isSelect = false;
            return newItem;
          }).filter((data: any) => (data?.row?.instanceValue <= data?.row?.avaible) && (data?.row?.instanceValue > 0)
            && (data?.row?.instanceValueWarehouse?.id && data?.row?.instanceValueWarehouse?.name) &&
            (data?.row?.instanceValueWarehouse?.id != data?.row?.warehouse.id));
        } else {
          return (this.currentvalue.rows as []).filter((data: any) => (data?.row?.instanceValue <= data?.row?.avaible) && (data?.row?.instanceValue > 0)
            && (data?.row?.instanceValueWarehouse?.id && data?.row?.instanceValueWarehouse?.name) &&
            (data?.row?.instanceValueWarehouse?.id != data?.row?.warehouse.id));
        }
      }

    } else if (TYPE_.UNLOAD == this.codeFeature) {

      if (this.currentvalue?.rows?.length > 0) {
        return (this.currentvalue.rows as []).filter((data: any) => (data?.row?.instanceValue <= data?.row?.avaible) && (data?.row?.instanceValue > 0) );
      }

    }

    return undefined;
  }


  private getValue(value: any) {
    this.currentSelectProp = {};
    (value.rows as []).forEach((element: any /* questo e il row? */) => {
      this.currentSelectProp[element.row.id] = element.row;
    });
  }


  ngOnInit(): void {
    this.codeFeature = '';
    this.subs.add(

      this.store.pipe(select(selectFeatureCode), distinctUntilChanged()).subscribe(data => {
        if (data) {
          this.codeFeature = this.getCode(data);
        }
      })
    );

    this.activatedRoute.paramMap.subscribe(param => {
      const id = param.get('id');
      if (id != null) { this.idSessionResult = parseInt(id); }
    });

    this.subs.add(
      this._countRowsService.state.pipe(catchError(err => { return throwError(err); })).subscribe(data => {

        let currRowsNormalized: { rows: any[], all: boolean } = { rows: [], all: false };
        this.arrayRows = _.uniqWith(data.row, _.isEqual);
        currRowsNormalized.rows = this.arrayRows;
        this.currentvalue = currRowsNormalized;
        this.getValue(this.currentvalue);
        this._cdref.detectChanges(); /**muy potente detectChanges util ANGULAR --> https://angular.io/api/core/ChangeDetectorRef */
      })
    );
  }

  private preSave(): any[] | undefined {
    const arrayFor: any[] = [];
    this.currentvalue.rows = this.valid(); // grande santiago
    if (this.currentSelectProp) {

      this.currentvalue?.rows?.forEach((element: any) => {

        const rowInsert = <WarehouseMovementDTO>Object.assign(new whMovements(), {
          productId: this.currentSelectProp[element.row.id]?.product.id,
          quantity: this.currentSelectProp[element.row.id]?.instanceValue,
          conditionId: this.currentSelectProp[element.row.id]?.combination?.conditionId || null,
          languageId: this.currentSelectProp[element.row.id]?.combination?.languageId || null,
          holoId: this.currentSelectProp[element.row.id] && this.currentSelectProp[element.row.id].combination && this.currentSelectProp[element.row.id].combination.holo && this.currentSelectProp[element.row.id].combination.holo.id != null && this.currentSelectProp[element.row.id]?.combination?.holo?.id != undefined ? this.currentSelectProp[element.row.id]?.combination.holo?.id : null,
          signed: this.currentSelectProp[element.row.id]?.combination.signed != null ? this.currentSelectProp[element.row.id].combination.signed : null,
          stamped: this.currentSelectProp[element.row.id]?.combination.stamped != null ? this.currentSelectProp[element.row.id].combination.stamped : null,
          inked: this.currentSelectProp[element.row.id]?.combination.inked != null ? this.currentSelectProp[element.row.id].combination.inked : null,
          altered: this.currentSelectProp[element.row.id]?.combination.altered != null ? this.currentSelectProp[element.row.id].combination.altered : null,
          foil: this.currentSelectProp[element.row.id]?.combination.foil != null ? this.currentSelectProp[element.row.id].combination.foil : null,
          firstEdition: this.currentSelectProp[element.row.id]?.combination.firstEdition != null ? this.currentSelectProp[element.row.id].combination.firstEdition : null,
          shadowless: this.currentSelectProp[element.row.id]?.combination.shadowless != null ? this.currentSelectProp[element.row.id].combination.shadowless : null,
          staff: this.currentSelectProp[element.row.id]?.combination.staff != null ? this.currentSelectProp[element.row.id].combination.staff : null,
          warehouseFromId: this.currentSelectProp[element.row.id]?.warehouse?.id,
          warehouseToId: this.currentSelectProp[element.row.id]?.instanceValueWarehouse?.id || null,
          comments: this.currentSelectProp[element.row.id]?.comments || null,
        })

        arrayFor.push(rowInsert);

      });

      return arrayFor;
    }

    return undefined;

  }
  openSnackBar() {
    this._snackBar.openFromComponent(SuccesComponent, {
      duration: 5000,
    });

  }

  setAll() {
    const val = this.preSave();
    if (val) {
      this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));


      this.subs.add(
        this.addMovementService.saveMovementBulk(val, this.idSessionResult != null ? this.idSessionResult : undefined).pipe(
          distinctUntilChanged(),
          debounceTime(environment.defaultDebounceTime),
          catchError(err => {
            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
            return throwError(err)
          })
        ).subscribe(result => {
          if (result) {
            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
            this.result = result;
            // FIXME SANTIAGO REFRESH

            if ((this.result.data as []).length > 0) {
              this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
              this.hostServiceColumnSingle._resetData.next(false);
              this.valid();
            }

            if (!this.idSessionResult) {
              this.idSessionResult = this.getValueSessionId(this.result) as number;
            }
            // invia session id
            // this.hostServiceColumnSingle._newIdSession.next(this.idSessionResult);

            this.openSnackBar();

            this.hostServiceColumnSingle._newIdSession.next({ id: this.idSessionResult, code: this.codeFeature });

            this.subs.add(
              this.activatedRoute.paramMap.pipe(distinctUntilChanged()).subscribe(param => {
                const id = param.get('id');
                if (id) this.idSession = parseInt(id);

                if (!id) {

                  this.router.navigate([`app/warehouse/session/movement/${this.codeFeature}/${this.idSessionResult}`], {
                    replaceUrl: true,
                    queryParamsHandling: 'merge',
                    skipLocationChange: false
                  }).then(() => {
                    this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
                    this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));

                    if ((this.result.data as []).length > 0) {

                      this._countRowsService.deleteRow();
                      this.hostServiceColumnSingle._resetData.next(false);
                      this.hostServiceColumnSingle.hostSetAllQuantity(false);
                      this.hostServiceColumnSingle._newIdSession.next({ id: this.idSessionResult, code: this.codeFeature });
                      this.valid();

                    }

                  });
                } else {
                  this.arrayRows = [];
                  this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
                  this.getSessionsDetails();
                }
              })
            );

          }

        })
      );

    }
  }

  getValueSessionId(val: any): number | undefined {
    if (val.data.length > 0) {
      this.idSessionResult = val.data[0]?.warehouseSession?.id;
      return val.data[0]?.warehouseSession?.id
    }

    return undefined;

  }

  private getSessionsDetails() {
    this._countRowsService.deleteRow();

  }

}
