<ng-container *ngIf="valid() && valid().length > 0">
<!--  matBadgeOverlap matBadgeSize="small" matBadge="{{valid()?.length}}"-->
  <button  class="btn  btn__ons" type="button" (click)="setAll()" matTooltip="save all movements" >
    <span style="font-size: 11px" >Save</span>
    <span  style="font-size: 11px ; font-weight: 400; color: black; " class="badge badge-warning _spn">{{valid()?.length}}</span>

<!--      <mat-icon [color]=" currentvalue?.rows?.length > 0 ? 'primary': 'warn'">-->
<!--          playlist_add-->
<!--      </mat-icon>-->
  </button>
</ng-container>

<!-- test santiago <span>{{valid()?.length}}</span> -->
