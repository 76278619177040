<div class="header">
  <h1 mat-dialog-title>{{ data.title | translate }}</h1>
</div>
<div mat-dialog-content>
  <p class="dialog-message">{{ data.message | translate}}</p>
</div>

<ng-container>
  {{data.content}}
</ng-container>


<div mat-dialog-actions>
  <button class="btn btn-cancel" style="margin-right:10px;" (click)="cancel()">{{ data.cancelText | translate }}</button>
  <button class="btn btn-confirm" (click)="confirm()">{{ data.confirmText | translate }}</button>
</div>
