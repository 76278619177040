import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, fromEvent, map, Observable, switchMap } from 'rxjs';
import { DynacrudApiWrapper } from '../../api/dynacrud-api';
import { ControlValueAccessorBaseImpl } from '../control-value/ControlValueAccessorBaseImpl';

import { isNullOrUndefined } from 'is-what';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { SubSink } from 'subsink';
import _ from 'lodash';

export const DEFAULT_SELECT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DefaultSelectComponent),
  multi: true
};

@Component({
  selector: 'app-default-select',
  templateUrl: './default-select.component.html',
  styleUrls: ['./default-select.component.scss'],
  providers: [DEFAULT_SELECT_CONTROL_VALUE_ACCESSOR]
})
export class DefaultSelectComponent extends ControlValueAccessorBaseImpl<any> implements OnInit, OnDestroy {

  @Input()
  label!: string;

  @Input()
  idFilter!: number;

  @Input()
  source!: string;

  @Input()
  isRequiredVal!: boolean;

  @Input()
  multiple!: boolean;

  @Input()
  filterBy!: CallableFunction;

  @Input()
  modifyData!: CallableFunction;

  items!: any[];

  @Input() hideHint!: boolean;

  @Input()
  override readonly!: boolean;

  subs = new SubSink();

  constructor(private dynacrudApiWrapper: DynacrudApiWrapper) { super(); }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @ViewChild('valueNgxSelectSearch') valueNgxSelectSearch: MatSelectSearchComponent | undefined;

  override ngOnInit() {
    super.ngOnInit();
    // potremmo dover passare per gli stati... FIXME K3 ragioanci bene

    this.dynacrudApiWrapper.getFor(this.source).search({ pagination: { size: 10000, page: 0 } }).pipe(map(ret => {
      let retdata = ret.data;

      if (this.filterBy) {
        retdata = retdata.filter((item: any) => this.filterBy(item));
      }

      if (this.modifyData) {
        retdata = this.modifyData(retdata);
      }

      if (this.idFilter) {
        let valueOn = retdata.find((item: any) => item.id === this.idFilter);
        if (valueOn) {
          this.value = valueOn;
        }
      }

      return retdata;
    })).subscribe(d => {
      this.items = d;
      this.itemsMemory = _.cloneDeep(this.items);
    });

    setTimeout(() => {

      if (this.valueNgxSelectSearch) {
        this.subs.add(

          fromEvent(this.valueNgxSelectSearch.searchSelectInput.nativeElement, 'keyup').pipe(
            distinctUntilChanged(),
            debounceTime(300),
            map((d: any) => {

              if (d.target.value) {
                if ((d.target.value as string).length > 2) {
                  return d.target.value;
                }
              }

            })).subscribe(res => {
              this.filterMyOptions(res as string);
            })
        );

      }

    }, 2000);
  }

  showValue(item: any) {
    if (item) {
      return item.name || item.description || item.code || item.id || item;
    }
    return item;
  }

  getFloor(x: any) {
    return Math.floor(x);
  }



  getPositionSprite(i: any): { x?: number, y?: number } {
    if (i) {
      let x = this.getFloor((i % 10)) * 21;
      let y = this.getFloor((i / 10)) * 21;
      return { x: x, y: y };
    }

    return { x: undefined, y: undefined }
  }

  itemsMemory: any[] = [];

  isOpenBro(ev: any) {

    if (ev === false) {

      this.itemsMemory = _.cloneDeep(this.items);


    }

  }

  filterMyOptions(v: string | undefined): void {

    this.itemsMemory = _.cloneDeep(this.items);
    const cacheItems = _.cloneDeep(this.items);

    if (!v) {
      v = '';
    }

    this.itemsMemory = cacheItems.filter(d => (d.name as string).toLowerCase().includes((v as string).toLowerCase()));
  }

  clearValuesExpansion() {
    this.value = [];
  }

  public compareEntity(o: any, o2: any) {
    if (isNullOrUndefined(o) && isNullOrUndefined(o2)) {
      return true;
    }

    if ((isNullOrUndefined(o) && !isNullOrUndefined(o2)) || (isNullOrUndefined(o2) && !isNullOrUndefined(o))) {
      return false;
    }

    return o === o2 || o.id === o2.id;
  }

}


