import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'app-scroll-component-for',
  templateUrl: './scroll-component-for.component.html',
  styleUrls: ['./scroll-component-for.component.scss']
})
export class ScrollComponentForComponent {

  @Output() eventName = new EventEmitter<Event>();
  ev: any;

  // @HostListener('scroll', ['$event']) // for scroll events of the current element
@HostListener('window:scroll', ['$event']) // for window scroll events
onScroll(event: Event) {

  this.ev = event;
}

}
