<!-- normal -->

<div class="d-flex flex-row p-0 m-0 contingenci">

  <div class="d-flex inp_">
    <!-- (focusout)="onFocusOutEventEntity($event)" -->
    <!-- [ngClass]="{'errorCust': errorCustom }" -->
    <input required name="{{structureField.field + '_' + idRowName}}" type="number" [min]="0" #genericField="ngModel"
           class="w-100" [(ngModel)]="entityValue" placeholder="{{((structureField.field || '') | translate) | titlecase}}" autocomplete="off" />

    <span *ngIf="(entity && entity.id != undefined && entity.name)" class="label_sot">{{ entity.name || '' }}</span>

  </div>

  <div class="d-flex selt_">
    <select required="" class="form-select form-select-sm w-100" [(ngModel)]="entityValue" [compareWith]="compareEntity"
            aria-label=".form-select-sm example">
      <option selected></option>
      <option *ngFor="let ent of entities" [value]="ent.id">
        {{ent.id}} - {{getEntitiesDescription(ent)}}
      </option>
    </select>
  </div>

</div>
