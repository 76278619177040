import {Injectable, Type} from '@angular/core';
import {DynacrudWeakDataService} from './DynacrudWeakDataService';
import {Expansion} from '../models/entities';
import {DynacrudApiWrapper} from '../../core/api/dynacrud-api';

@Injectable({
  providedIn: 'root'
})
export class ExpansionService extends DynacrudWeakDataService<Expansion> {

  constructor(dynacrudApiWrapper: DynacrudApiWrapper) {
    super('expansions', ExpansionService, dynacrudApiWrapper);
  }

  getType(): Type<Expansion> | string {
    return 'expansion';
  }
}
