import { Component } from '@angular/core';
import { ColumnData, ColumnFieldComponent } from 'src/app/core/components/table/Column';
import { WarehouseChangeMovement } from 'src/app/shared/models/entities';

@Component({
  selector: 'app-warehouse-change-column',
  templateUrl: './warehouse-change-column.component.html',
  styleUrls: ['./warehouse-change-column.component.scss']
})
export class WarehouseChangeColumnComponent implements ColumnFieldComponent {

  data!: ColumnData;

  get sessionMovementChange(): WarehouseChangeMovement {
    return this.data?.row as WarehouseChangeMovement;
  }

}
