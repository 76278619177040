import { Component } from '@angular/core';
import { ColumnData, ColumnFieldComponent } from 'src/app/core/components/table/Column';

@Component({
  selector: 'app-quantity-movement-chain',
  templateUrl: './quantity-movement-chain.component.html',
  styleUrls: ['./quantity-movement-chain.component.scss']
})
export class QuantityMovementChainComponent  implements ColumnFieldComponent{
  data!: ColumnData;

}
