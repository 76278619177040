<ng-container *ngIf="enabled != null; else noOption">
  <span>
    <mat-checkbox disableRipple [color]=" enabled ?  'primary': '' " [checked]="enabled" name="check">
      {{''}}
    </mat-checkbox>
  </span>

</ng-container>

<ng-template #noOption>
  <span> {{ 'no-item' | translate }} </span>
</ng-template>