import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgModel, NumberValueAccessor} from '@angular/forms';
import {isArray, isNullOrUndefined} from 'is-what';
import * as _ from 'lodash';
import {DynaCrudService, DynacrudApiWrapper} from 'src/app/core/api/dynacrud-api';
import {OperationType} from 'src/app/core/models/dynacrud';
import {ProductStructureField, Entity} from 'src/app/shared/models/entities';
import {ControlValueAccessorBaseImpl} from '../../../control-value/ControlValueAccessorBaseImpl';
import {SharedService} from "../../../../../shared/services/shared.service";
import {Observable, distinctUntilChanged, take} from "rxjs";

export const STRUCTURE_RAPID_LOOKUP_ACCESSOR_CONTROL_VALUE_ACCESSOR_NORMAL: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StructureRapidLookUpFieldNormalComponent),
  multi: true
};

@Component({
  selector: 'app-structure-rapid-look-up-field-normal',
  templateUrl: './structure-rapid-look-up-field-normal.component.html',
  styleUrls: ['./structure-rapid-look-up-field-normal.component.scss'],
  providers: [STRUCTURE_RAPID_LOOKUP_ACCESSOR_CONTROL_VALUE_ACCESSOR_NORMAL]
})
export class StructureRapidLookUpFieldNormalComponent extends ControlValueAccessorBaseImpl<Entity> implements OnInit, AfterViewInit {

  @Input()
  structureField!: ProductStructureField;

  private service!: DynaCrudService<Entity>;

  @Input()
  valueSet!: number | undefined;

  @Input()
  showName!: boolean;

  @Input()
  entireData!: any[] | undefined;

  @Input()
  entireDataForce!: boolean;

  @Input() idRowName!: any;


  @Output() getValues = new EventEmitter();

  @Input() resetAll : Observable<boolean> | undefined;

  @ViewChild('genericField') genericField!: NgModel;

  constructor(private dynacrudApiWrapper: DynacrudApiWrapper, public _elementRef: ElementRef, private sharedService: SharedService) {
    super();
  }

  ngAfterViewInit(): void {
    this.resetAll?.pipe( distinctUntilChanged() ).subscribe(d => {
      if (d) {
        this.value = undefined;
        this.genericField.valueAccessor?.writeValue('');
        this.entity = undefined as any;
        // this.errorCustom = false;

       

      }

    });
  }

  // errorCustom = false;

  entity!: Entity;
  entities!: Entity[];

  set entityValue(ida: number) {
    
    const id = ida != undefined ? parseInt(ida.toString()) : '';

    this.entity = this.entities.find(e => e.id?.toString() == id.toString()) as Entity;
    this.value = this.entity?.id;

    if (this.entity && this.entity.id != undefined) {


      this.genericField?.valueAccessor?.writeValue(this.value);
      
      if (this.genericField && (this.genericField.valueAccessor as {
        [k: string]: any
      })['_elementRef']?.nativeElement?.value.toString().length == this.entity.id?.toString().length) {

      } else {

        if ( this.genericField && (this.genericField.valueAccessor as { [k: string]: any })['_elementRef']?.nativeElement) {

          // this.errorCustom = true;
          this.entity = (this.entities.map(n => {

            if (id && (n.id as number)?.toString().includes(id.toString() as string)) {
              const a: any = {id: '9999999999___santiagolegustaelrealmadriddepeque', name: ''};
              return a;
            }
            return;
          })).find(d => d && d.id != undefined);

          setTimeout(() => {

            this.genericField.valueAccessor?.writeValue(undefined);
            this.value = undefined;

          }, 0);

        }

      }


    } else {

      // this.errorCustom = true;
      this.entity = (this.entities.map(n => {

        if (id && (n.id as number)?.toString().includes(id.toString() as string)) {
          const a: any = {id: '9999999999___santiagolegustaelrealmadriddepeque', name: ''};
          return a;
        }
        return;
      })).find(d => d && d.id != undefined);

      setTimeout(() => {

        this.genericField.valueAccessor?.writeValue(undefined);
        this.value = undefined;
        // this.errorCustom = true;

      }, 0);
    }

  }

  get entityValue(): any {
    if (this.entity && this.entity.id != undefined) {
      return this.entity.id;
    }
    
    return this.valueSet;
   

  }

  onFocusOutEventEntity(e: FocusEvent) {
    const id = (e.target as any)?.value || '';

    this.entity = this.entities.find(w => w.id.toString() == id.toString()) as Entity;

    if (!this.entity) {
      setTimeout(() => {
        this.genericField.valueAccessor?.writeValue(undefined);
        this.value = undefined;
        // this.errorCustom = true;

      }, 0);

    }

  }

  override ngOnInit(): void {
    super.ngOnInit();
    const permittedValuse = this.getPermittedValues();

    if ((!this.entireDataForce)) {
      
      if (this.structureField.source) {
       
        this.service = this.dynacrudApiWrapper.getFor(this.structureField.source);
      }

      this.service.search(permittedValuse.length > 0 ? {
        filter: {
          filterBy: {
            field: 'id',
            value: permittedValuse,
            operation: OperationType.IN
          }
        }
      } : undefined).subscribe(ret => {
     
        this.entities = ret.data;
        this.getValues.emit(this.entities);

        if (this.valueSet != undefined && typeof this.valueSet == 'number') {
          this.entityValue = this.valueSet;
        }

      });

    } else {
      this.entities = this.entireData as Entity[];
    }


    this.sharedService.nextReloadData.pipe(distinctUntilChanged()).subscribe(data => {
      if (data) {
        this.getValues.emit(this.entities);
      }

    });


  }

  public compareEntity(o: any, o2: any) {
    if (isNullOrUndefined(o) && isNullOrUndefined(o2)) {
      return true;
    }

    if ((isNullOrUndefined(o) && !isNullOrUndefined(o2)) || (isNullOrUndefined(o2) && !isNullOrUndefined(o))) {
      return false;
    }

    return o === o2 || o.id === o2.id;
  }

  private getPermittedValues(): number[] {
    let permittedValues: any[] = [];
    let fieldValues = this.structureField.values;
    if (fieldValues && !isArray(fieldValues)) {
      fieldValues = JSON.parse(fieldValues as string);
      if (isArray(fieldValues)) {
        permittedValues = fieldValues as [];
      }
    }
    return permittedValues;
  }


  getDescription() {

    let value: any = this.entity;

    let nomeInitial: any = this.entities?.find(d => (d.id == this.value));

    // (_.isNumber(this.value) || typeof this.value === 'number')
    if ((value && (value.id !== null && value.id !== undefined)) || (_.isNumber(this.value) || typeof this.value as unknown === 'number')) {
      value = value ? value.defaultDescription || value.name || value.description || value.code || value.id : nomeInitial?.name || undefined;
      // value  =  value.defaultDescription || value.name || value.description || value.code || value.id  ;
    }


    return value;
  }

  getEntitiesDescription(ent: Entity) {
    let value: any = ent;
    if (value && value.id != null) {
      value = value.defaultDescription || value.name || value.description || value.code || value.id;
    }
    return value;
  }


}
