import { Component, Input } from '@angular/core';
import { ColumnData } from 'src/app/core/components/table/Column';
import { DefaultColumnComponent } from 'src/app/core/components/table/column-impl/default-column/default-column.component';
import { WorkingMovement } from 'src/app/shared/models/entities';

@Component({
  selector: 'app-movement-chain-column',
  templateUrl: './movement-chain-column.component.html',
  styleUrls: ['./movement-chain-column.component.scss']
})
export class MovementChainColumnComponent extends DefaultColumnComponent {

  @Input()
  override data!: ColumnData;

  get workingMovement(): WorkingMovement {
    return this.data?.row as WorkingMovement;
  }


}
