import { Component } from '@angular/core';
import { of, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  reload(url: string) {
    window.location.replace(url);
  }

  ngOnInit() {

    let first = true;
    if (first) {

      of(1).pipe(take(1)).subscribe(d => { this.reload(`${environment.homePage}`); });
      first = false;
    }
    // TODO: sporcata per caricare il home con reload per chiamare una sola volta il constructor del servizio ProductStructureService
    // this.router.navigateByUrl(environment.homePage, {skipLocationChange: false}).then();
  }

}
