import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, select } from '@ngrx/store';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { HostColumnEditService } from 'src/app/shared/services/host-column-edit.service';
import { DynaConfActionType, State, selectFeatureCode } from 'src/app/store/shared/shared.reducers';
import { CountRowsService } from '../../../services/count-rows.service';
import { SubSink } from 'subsink';
import { TransferType } from 'src/app/shared/models/entities';
import { distinctUntilChanged, debounceTime, catchError, throwError } from 'rxjs';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { WarehouseMovementDTO } from '../../../models/entities';
import { whMovements } from '../add-movement-session-action/add-movement-session-action.component';
import { SuccesComponent } from '../../columns/select-warehouse-change-trans-column/select-warehouse-change-trans-column.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDMODALSESSION } from '../edit-session-record/edit-session-record.component';
import * as _ from 'lodash';
import { DialogEditService } from '../../../services/dialog-edit.service';

@Component({
  selector: 'app-edit-movement-session-button-action-all',
  templateUrl: './edit-movement-session-button-action-all.component.html',
  styleUrls: ['./edit-movement-session-button-action-all.component.scss']
})
export class EditMovementSessionButtonActionAllComponent implements TableActionComponent, OnInit, OnDestroy {

  public data!: ActionData;
  private subs = new SubSink();
  currentSelectProp: { [key: string]: any } = {};
  codeFeature: string | undefined;
  currentvalue: any;
  // idSessionResult!: number;
  arrayRows: any[] = [];
  private result: any;

  itemSessionId!: number | undefined;
  instanceDialog: MatDialogRef<any, any> | undefined;
  instanceDataDialog: any;
  codeFromDialog = '';

  constructor(
    private store: Store<State>, private hostServiceColumnSingle: HostColumnEditService, private _countRowsService: CountRowsService, private dialogServiceEditSession: DialogEditService,
    private _cdref: ChangeDetectorRef, // todo santiago un change detector custom ?**/
    private _snackBar: MatSnackBar, private dialog: MatDialog
  ) {
    this.instanceDialog = this.dialog.getDialogById(IDMODALSESSION);
    this._countRowsService.deleteRow();

  }

  private getCode(code: string): string | undefined {
    if (code) {
      return code;
    }
    return undefined;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this._countRowsService.deleteRow();

  }

  ngOnInit() {

    this.instanceDialog = this.dialog.getDialogById(IDMODALSESSION);

    this.itemSessionId = this.instanceDialog?.componentInstance?.dataOver2?.idSession || '';

    this.instanceDataDialog = this.instanceDialog?.componentInstance?.dataOver2;

    this.codeFromDialog = this.instanceDataDialog?.featureType || '';


    this.codeFeature = '';
    this.subs.add(

      this.store.pipe(select(selectFeatureCode), distinctUntilChanged()).subscribe(data => {
        if (data) {
          this.codeFeature = this.getCode(data);
        }
      })
    );

    /*this.activatedRoute.paramMap.subscribe(param => {
      const id = param.get('id');
      if (id != null) { this.idSessionResult = parseInt(id); }
    });*/

    this.subs.add(
      this._countRowsService.state.pipe(catchError(err => { return throwError(err); })).subscribe(data => {

        let currRowsNormalized: { rows: any[], all: boolean } = { rows: [], all: false };
        this.arrayRows = _.uniqWith(data.row, _.isEqual);
        currRowsNormalized.rows = this.arrayRows;
        this.currentvalue = currRowsNormalized;
        this.getValue(this.currentvalue);
        this._cdref.detectChanges(); /**muy potente detectChanges util ANGULAR --> https://angular.io/api/core/ChangeDetectorRef */
      })
    );

  }

  private getValue(value: any) {
    this.currentSelectProp = {};
    (value.rows as []).forEach((element: any /* questo e il row? */) => {
      this.currentSelectProp[element.row.id] = element.row;
    });
  }

  public valid(loadPush?: boolean) : any | undefined {

    const TYPE_ = TransferType;

    if (TYPE_.TRANS == this.codeFeature  || ((this.codeFeature == 'session_detail' || this.codeFeature == 'session') && this.codeFromDialog == TYPE_.TRANS) ) {

      if (this.currentvalue?.rows?.length > 0) {
        if (loadPush) { // pootrei usarlo per una gestione in cui non serve piu il service behavior :)
          return (this.currentvalue.rows as []).map(item => {
            const newItem: any = item;
            newItem.isSelect = false;
            return newItem;
          }).filter((data: any) => (data?.row?.instanceValue <= data?.row?.avaible) && (data?.row?.instanceValue > 0)
            && (data?.row?.instanceValueWarehouse?.id && data?.row?.instanceValueWarehouse?.name) &&
            (data?.row?.instanceValueWarehouse?.id != data?.row?.warehouse.id));
        } else {
          return (this.currentvalue.rows as []).filter((data: any) => (data?.row?.instanceValue <= data?.row?.avaible) && (data?.row?.instanceValue > 0)
            && (data?.row?.instanceValueWarehouse?.id && data?.row?.instanceValueWarehouse?.name) &&
            (data?.row?.instanceValueWarehouse?.id != data?.row?.warehouse.id));
        }
      }

    } else if (TYPE_.UNLOAD == this.codeFeature  || ((this.codeFeature == 'session_detail' || this.codeFeature == 'session') && this.codeFromDialog == 'UNLOAD') ) {

      if (this.currentvalue?.rows?.length > 0) {
        return (this.currentvalue.rows as []).filter((data: any) => (data?.row?.instanceValue <= data?.row?.avaible) && (data?.row?.instanceValue > 0)  /**&& (data?.row?.instanceValueWarehouse?.id && data?.row?.instanceValueWarehouse?.name) */);
      }

    }

    return undefined;
  }

  openSnackBar() {

    this._snackBar.openFromComponent(SuccesComponent, {
      duration: 5000,
    });

  }

  private preSave(): any[] | undefined {
    const arrayFor: any[] = [];
    this.currentvalue.rows = this.valid(); // grande santiago
    if (this.currentSelectProp) {

      this.currentvalue?.rows?.forEach((element: any) => {

        const rowInsert = <WarehouseMovementDTO>Object.assign(new whMovements(), {
          productId: this.currentSelectProp[element.row.id]?.product.id,
          quantity: this.currentSelectProp[element.row.id]?.instanceValue,
          conditionId: this.currentSelectProp[element.row.id]?.combination?.conditionId || null,
          languageId: this.currentSelectProp[element.row.id]?.combination?.languageId || null,
          holoId: this.currentSelectProp[element.row.id] && this.currentSelectProp[element.row.id].combination && this.currentSelectProp[element.row.id].combination.holo && this.currentSelectProp[element.row.id].combination.holo.id != null && this.currentSelectProp[element.row.id]?.combination?.holo?.id != undefined ? this.currentSelectProp[element.row.id]?.combination.holo?.id : null,
          signed: this.currentSelectProp[element.row.id]?.combination.signed != null ? this.currentSelectProp[element.row.id].combination.signed : null,
          stamped: this.currentSelectProp[element.row.id]?.combination.stamped != null ? this.currentSelectProp[element.row.id].combination.stamped : null,
          inked: this.currentSelectProp[element.row.id]?.combination.inked != null ? this.currentSelectProp[element.row.id].combination.inked : null,
          altered: this.currentSelectProp[element.row.id]?.combination.altered != null ? this.currentSelectProp[element.row.id].combination.altered : null,
          foil: this.currentSelectProp[element.row.id]?.combination.foil != null ? this.currentSelectProp[element.row.id].combination.foil : null,
          firstEdition: this.currentSelectProp[element.row.id]?.combination.firstEdition != null ? this.currentSelectProp[element.row.id].combination.firstEdition : null,
          shadowless: this.currentSelectProp[element.row.id]?.combination.shadowless != null ? this.currentSelectProp[element.row.id].combination.shadowless : null,
          staff: this.currentSelectProp[element.row.id]?.combination.staff != null ? this.currentSelectProp[element.row.id].combination.staff : null,
          warehouseFromId: this.currentSelectProp[element.row.id]?.warehouse?.id,
          warehouseToId: this.currentSelectProp[element.row.id]?.instanceValueWarehouse?.id || null,
          comments: this.currentSelectProp[element.row.id]?.comments || null,
        })

        arrayFor.push(rowInsert);

      });

      return arrayFor;
    }

    return undefined;

  }

  setAll() {
    const itemToSave = this.preSave();
    if (itemToSave) {
      this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));


      this.subs.add(

       this.dialogServiceEditSession.updateItemModal(this.itemSessionId as number, this.instanceDataDialog.item.id, [...itemToSave] ).pipe(debounceTime(50), catchError(err => {
          this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
          return throwError(err);
        })).subscribe(result => {
          if (result) {
            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
            this.result = result;
            // FIXME SANTIAGO REFRESH

            /*if ((this.result.data as []).length > 0) {
              this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
              this.hostServiceColumnSingle._resetData.next(false);
              this.valid();
            }*/

            /*if (!this.idSessionResult || this.idSessionResult == null || this.idSessionResult == undefined) {
              this.idSessionResult = this.getValueSessionId(this.result) as number;
            } else {
              this.idSessionResult = this.idSessionResult;
            }*/
            // invia session id
            // this.hostServiceColumnSingle._newIdSession.next(this.idSessionResult);

            this.openSnackBar();

            this.hostServiceColumnSingle._newIdSession.next({ id: this.itemSessionId, code: this.codeFeature });

            if ((this.result.data as []).length > 0) {

              this._countRowsService.deleteRow();
              this.hostServiceColumnSingle._resetData.next(false);
              this.hostServiceColumnSingle.hostSetAllQuantity(false);
              this.hostServiceColumnSingle._newIdSession.next({ id: this.itemSessionId, code: this.codeFeature });
              this.valid();



            }

            this.getSessionsDetails();
            /*this.subs.add(
              this.activatedRoute.paramMap.pipe(distinctUntilChanged()).subscribe(param => {
                const id = param.get('id');
                if (id) this.idSession = parseInt(id);

                if (!id) {

                  this.router.navigate([`app/warehouse/session/movement/${this.codeFeature}/${this.idSessionResult}`], {
                    replaceUrl: true,
                    queryParamsHandling: 'merge',
                    skipLocationChange: false
                  }).then(() => {
                    this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
                    this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));

                    if ((this.result.data as []).length > 0) {

                      this._countRowsService.deleteRow();
                      this.hostServiceColumnSingle._resetData.next(false);
                      this.hostServiceColumnSingle.hostSetAllQuantity(false);
                      this.hostServiceColumnSingle._newIdSession.next({ id: this.idSessionResult, code: this.codeFeature });
                      this.valid();

                    }

                  });
                } else {
                  this.arrayRows = [];
                  this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
                  this.getSessionsDetails();
                }
              })
            );*/

            this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
            this.dialogServiceEditSession.setReload(true);

            setTimeout(() => {

              this.instanceDialog?.close();
            }, 50);

          }

        })
      );

    }
  }

  /*private getValueSessionId(val: any): number | undefined {
    if (val.data.length > 0) {
      this.idSessionResult = val.data[0]?.warehouseSession?.id;
      return val.data[0]?.warehouseSession?.id
    }

    return undefined;

  }*/

  private getSessionsDetails() {
    this._countRowsService.deleteRow();

  }




}
