import { Component, ViewEncapsulation } from '@angular/core';
import { ColumnData, ColumnFieldComponent } from 'src/app/core/components/table/Column';

@Component({
  selector: 'app-description-tooltip',
  templateUrl: './description-tooltip.component.html',
  styleUrls: ['./description-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DescriptionTooltipComponent implements  ColumnFieldComponent {
  data!: ColumnData;

}
