import {Injectable, Type} from '@angular/core';
import {DynacrudWeakDataService} from './DynacrudWeakDataService';
import {DynacrudApiWrapper} from '../../core/api/dynacrud-api';
import { Rarity } from '../models/entities';

@Injectable({
  providedIn: 'root'
})
export class RarityService extends DynacrudWeakDataService<Rarity> {

  constructor(dynacrudApiWrapper: DynacrudApiWrapper) {
    super('rarities', RarityService, dynacrudApiWrapper);
  }

  getType(): Type<Rarity> | string {
    return 'rarity';
  }
}
