import { CurrentDynaData, DynaConfActionType, DynaCrudRequest, selectCurrentDynaData, State } from 'src/app/store/shared/shared.reducers';
import { DynacrudApi, OperationType, SearchRequest, Sort, SortDirection } from '../../models/dynacrud';
import { catchError, debounceTime, distinctUntilChanged, map, Observable, Subject, switchMap, take, tap, throwError } from 'rxjs';
import { SelectionMode, TableActionType, TableConfig, TableEvent } from '../../models/table-config';
import { HostColumnEditService } from 'src/app/shared/services/host-column-edit.service';
import { customPaginatiorMode, TableData, TableSelection, TableStatus } from '../table/table/table.component';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { DynacrudApiWrapper, DynaCrudService } from '../../api/dynacrud-api';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ProductStructure, TransferType } from 'src/app/shared/models/entities';
import { BaseAction } from 'src/app/store/store.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { _isNumberValue } from '@angular/cdk/coercion';
import { RouteConf } from '../../models/route-config';
import { select, Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import * as _ from 'lodash';
import { strategySelect } from 'src/app/features/warehouse/components/bodies/all-sessions-into-wrap/all-sessions-into-wrap.component';

export interface DefaultListComponentConf {
  tableConfig?: TableConfig;
  first?: boolean;
  searchRequestDefault?: SearchRequest;
  displayedColumns?: string[];
  baseRoute?: string;
  isTemplate?: boolean;
  loadOnInit?: boolean;
  apiUrl?: string;
}


export enum TypeTable {
  'NORMAL' = 'NORMAL',
  'EDIT' = 'EDIT',

}

// const itemsRoutes = environment.menu;

@Component({
  selector: 'app-default-list',
  templateUrl: './default-list.component.html',
  styleUrls: ['./default-list.component.scss']
})
export class DefaultListComponent implements OnInit, OnDestroy {
  private dynaCrudRequestSub: Subject<DynaCrudRequest> = new Subject<DynaCrudRequest>();

  private dataSourcetSub: Subject<TableData> = new Subject<TableData>();
  readonly dataSource: Observable<TableData> = this.dataSourcetSub.asObservable();
  private lastDynaCrudRequest!: DynaCrudRequest;
  private service!: DynaCrudService<any>;
  private subs = new SubSink();

  // private newPaginationOld!: Meta<PaginationResponse> | null;
  // itemsRoutes_: MenuSidenav[] = itemsRoutes;


  constructor(
    private router: Router,
    private _activ: ActivatedRoute,
    private dynacrudService: DynacrudApiWrapper,
    private store: Store<State>,
    private dialog: DialogService,
    private hostServiceColumnSingle: HostColumnEditService,
    // private httpPrivate: HttpClient,
  ) {
    // super(httpPrivate)
  }

  @HostListener('click', ['$event']) onClick(btn: MouseEvent) {

    if ((this.conf.tableConfig?.selectionMode != SelectionMode.multiple) && this.selection && this.selection.rows && this.selection.rows[0] && (this.selection.rows[0].id || this.selection.rows[0].row.id)) {

      const url = this.router.serializeUrl(
        this.router.createUrlTree([`${this.conf.baseRoute}${this.selection.rows[0].id || this.selection.rows[0].row.id}`])
      );

      this.hotClick = btn.ctrlKey;
      if (btn.ctrlKey) {
        window.open(url);


      }
    }
  }


  get tableConfig(): TableConfig {
    return {
      ...this.conf.tableConfig || {
        actions: [
          { type: TableActionType.GLOBAL, icon: 'add', event: 'add' },
          { type: TableActionType.RECORD, icon: 'edit', event: 'edit' },
          { type: TableActionType.RECORD, icon: 'delete', event: 'delete' },
        ]
      }
    };
  }

  get displayedColumns(): string[] {
    return this.conf.displayedColumns || ['id'];
  }

  @Input()
  routeConf: RouteConf | undefined;

  @Input()
  conf!: DefaultListComponentConf;

  @Input()
  typeTable!: TypeTable;

  @Input()
  typeTableViewCondition: ProductStructure | undefined;

  @Input() strategySelect: strategySelect | undefined;

  @Input() notInputPage!: boolean;


  @Input()
  getRowTable: boolean | undefined;

  dataExtra: { [key: string]: any[] } = {};



  private lastDynaData: CurrentDynaData | undefined = undefined;
  transFerType = TransferType;
  selection: any;
  hotClick = false;

  select(tableSelection: TableSelection) {


    this.selection = tableSelection;

    // fixme santiago controlar la seleccion ... porcata per cablare il routeconf
    // FIXME: si deve aggiungere il controllo anche per quelli routeConf dove ho aggiunto [trans e unload]

    if ((!(this.routeConf?.code == this.transFerType.TRANS || this.routeConf?.code == this.transFerType.UNLOAD))) {
      this.store.dispatch(new BaseAction(DynaConfActionType.SelectionChange, tableSelection || { all: false, rows: [] }));
    }
    // per config table
    const selectionMode = this.conf?.tableConfig?.selectionMode || SelectionMode.single;
    const isTrusted = tableSelection.rows.length > 0 && this.conf.baseRoute && (((selectionMode === SelectionMode.single)
      || (selectionMode === SelectionMode.multipleWithBaseRoute)));


    if (isTrusted) {
      setTimeout(() => {
        if (!this.hotClick) {
          if (!tableSelection.rows[0]?.not) {
            let toGo = `${this.conf.baseRoute}${tableSelection.rows[0]?.id || tableSelection.rows[0]?.row?.id}`;
            if (!tableSelection.ctrlKey) {
              toGo = `${this.conf.baseRoute}${tableSelection.rows[tableSelection.rows.length - 1]?.id || tableSelection.rows[tableSelection.rows.length - 1]?.row?.id}`
            }

            this.router.navigateByUrl(toGo).then(() => {
              let pagination = { pagination: { page: 0, size: 20, }, sort: [], reset: true };
              //FIXME: LO DEVE FARE SEMPRE ?  PENSIAMOCI AD UN ALTRO MODO GRANDE ( il reset (quello aggiunto) forse mi serve sempre per sapere quando togliere/rimuovere un item intero dal searchRequest ) :)

              this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, { ...pagination }));
            });

          }

        }
      }, 10);
    }

  }



  action(event: TableEvent) {
    if (this.conf.baseRoute) {
      if (event.data) {
        if (event.name === 'delete') {
          // FIXME SANTIAGO FINIRE DEFAULT LIST - UNIRE IL ACTION CON LO STATO

          // FIXME SANTIAGO ant-lab/galactus/v2/galactus-ui#10
          const options = {
            title: 'per segnare',
            message: 'dialog.deleted_template.message_delete',
            cancelText: 'dialog.deleted_template.cancel',
            confirmText: 'dialog.deleted_template.confirm'
          };
          this.dialog.open(options);
          this.dialog.confirmed().subscribe(confirmed => {
            if (confirmed) {
              this.service.delete(event.data).subscribe(() => this.dynaCrudRequestSub.next(this.lastDynaCrudRequest));
            }
          });
        } else {
          if (event.name) {
            this.router.navigateByUrl(`${this.conf.baseRoute}${event.name}/${event.data.id}`).then();
          } else {
            this.router.navigateByUrl(`${this.conf.baseRoute}${event.name}`).then();
          }
        }
      } else {
        this.router.navigateByUrl(`${this.conf.baseRoute}${event.name}`).then();
      }
    }
  }

  tableStatusChange(tableStatus: TableStatus) {
    const searchRequest: SearchRequest = {};
    if (tableStatus && tableStatus.sort) {
      searchRequest.sort = tableStatus.sort.map(s => {
        return { field: s.field, direction: SortDirection[s.direction.toUpperCase() as SortDirection] };
      });
    }

    if (tableStatus && tableStatus.page && (tableStatus.page.length != undefined)) {
      searchRequest.pagination = { page: tableStatus.page.pageIndex, size: tableStatus.page.pageSize, length: tableStatus.page.length };
    }

    this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, searchRequest));
  }

  private featureCodeNoS(code: string): { value: string, s: boolean } {
    return {
      value: code,
      s: true
    };
  }

  currentSearch: any;

  private init(currentDynaData: CurrentDynaData) {

    if (!this.routeConf) {
      const routeConf = currentDynaData.route;

      if (this.conf) {
        if (routeConf?.attributes?.['DefaultListComponent']) {
          this.conf = _.merge(this.conf, routeConf.attributes['DefaultListComponent'] as DefaultListComponentConf);
        }
      } else {
        this.conf = routeConf.attributes?.['DefaultListComponent'] as DefaultListComponentConf;
      }

      this.routeConf = routeConf;


      this.service = this.dynacrudService.getFor((this.conf.apiUrl) || (this.routeConf?.noEndWithS ? this.featureCodeNoS(this.routeConf.featureType as string) : this.routeConf.featureType) || this.routeConf.code || '', (this.routeConf.params || undefined));
      const currentCrudRequest: DynaCrudRequest = currentDynaData.request || { searchRequest: { filter: {} } };
      const firstTableStatus: TableStatus = { page: { pageIndex: 0, pageSize: 20, length: 0 }, sort: [] };

      if (currentCrudRequest && currentCrudRequest.searchRequest && currentCrudRequest.searchRequest.pagination) {
        firstTableStatus.page.length = 0;
        firstTableStatus.page.pageSize = currentCrudRequest.searchRequest.pagination.size as number;
        firstTableStatus.page.pageIndex = currentCrudRequest.searchRequest.pagination.page as number;
        firstTableStatus.sort = currentCrudRequest.searchRequest.sort as Sort[];

      }


      this.dataSourcetSub.next({ data: [], tableStatus: { ...firstTableStatus } });
      this.dynaCrudRequestSub.pipe(
        switchMap((dynaCrudRequest: DynaCrudRequest) => {

          // FIXME: vedere il searchRequest che passa prima
          const searchRequest: SearchRequest = dynaCrudRequest.searchRequest ? dynaCrudRequest.searchRequest : { filter: { filterBy: { field: 'obsolete', value: false, operation: OperationType.EQ } } };
          this.lastDynaCrudRequest = _.cloneDeep(dynaCrudRequest);
          this.store.dispatch(new BaseAction(LayoutActionType.Loading, true));


          // FIXME K3 non deve stare insieme al DynaConf
          if (this.lastDynaData?.routeData?.selection && (this.lastDynaData.routeData.selection.rows.length > 0 || this.lastDynaData.routeData.selection.all)) {
            if (this.routeConf?.code != 'transferType') {
              this.store.dispatch(new BaseAction(DynaConfActionType.SelectionChange, { rows: [], all: false }));
            }
          }

          if (this.routeConf && this.routeConf.first) {
            searchRequest.filter = this.routeConf.searchRequestDefault?.filter;
          }

          this.currentSearch = dynaCrudRequest;

          return this.service.search(searchRequest).pipe(distinctUntilChanged(), tap(() => this.routeConf && this.routeConf.first != undefined ? this.routeConf.first = false : '')); // FIXME SANTIAGO CONTROLLA SI PASSA

        }),
        map((data: DynacrudApi<any[]>) => {
          // Flip flag to show that loading has finished.

          const tableStatusRet: TableStatus = { ...firstTableStatus };

          if (data && data.meta && data.meta.pagination) {
            tableStatusRet.page.length = data.meta.pagination.totalElements as number;
            tableStatusRet.page.pageSize = data.meta.pagination.pageSize as number;
            tableStatusRet.page.pageIndex = data.meta.pagination.currentPage as number;
            tableStatusRet.sort = data.meta.sort as Sort[];
          }


          return { data: data.data, tableStatus: tableStatusRet } as TableData;
        }),
        catchError(err => {
          this.store.dispatch(new BaseAction(LayoutActionType.Loading, false));
          return throwError(err);
          // FIXME K3 che facciamo qua ? Catch if the GitHub API has reached its rate limit. Return empty data.
          // return new Observable((obs) => obs.next({data: [], tableStatus: {...firstTableStatus}} as TableData));
        })
      ).subscribe((ret) => {

        this.store.dispatch(new BaseAction(LayoutActionType.Loading, false));
        this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));

        // cablato la chiamata only here ? chiama dal cache, mettere la validazione in un metodo e poi controlla se lo fa generico. (LO FA)

        if (currentDynaData.route.code == TransferType.TRANS || currentDynaData.route.code == TransferType.UNLOAD || currentDynaData.route.code == TransferType.products_setInsert) {
          this.dynacrudService.getFor('warehouse').search({
            filter: {
              filterBy: {
                operation: OperationType.AND,
                filters: [
                  { field: 'obsolete', value: 'false', operation: OperationType.EQ },
                  { field: 'virtual', value: 'false', operation: OperationType.EQ }
                ]
              }
            }
          })
            .pipe(map(d => d.data), take(1), debounceTime(environment.defaultDebounceTime)).subscribe(dataWarehouses => {

              if (dataWarehouses && dataWarehouses.length > 0 && ret && ret.data.length > 0) {
                ret.data.forEach((element: any) => {
                  Object.defineProperty(element, 'warehouses', { value: dataWarehouses, writable: false, configurable: true, enumerable: true });
                });


                this.dataExtra['warehouses'] = dataWarehouses;
              }
            });
        }


        this.dataSourcetSub.next(ret as TableData);
      });

    }

  }


  ngOnInit(): void {

    this.dataExtra = {};

    this.subs.add(
      this.store.pipe(select(selectCurrentDynaData), debounceTime(environment.defaultDebounceTime), distinctUntilChanged()).subscribe(currentDynaData => {


        if (currentDynaData && currentDynaData.route) {
          // cablo per sort e code routeconfigcode


          this.init(currentDynaData);

          const featureCode: string | undefined = this.routeConf ? this.routeConf.code : undefined;

          if (featureCode) {

            const dynaCrudRequest: DynaCrudRequest = currentDynaData.request || { searchRequest: { filter: {} } };
            if ((dynaCrudRequest || this.conf.loadOnInit == null || !this.conf.loadOnInit)) {

              // FIXME K3 di seguito una porcata fino a cambiamento della gestione stato ( selection non può stare in dynadata)

              const isSelectionChange = !_.isEqual(currentDynaData?.routeData?.selection, this.lastDynaData?.routeData?.selection);
              const isDynaCrudRequestChange = !_.isEqual(this.lastDynaCrudRequest, dynaCrudRequest);
              const isNotDynaData = !this.lastDynaData;
              this.lastDynaData = _.cloneDeep(currentDynaData);
              if (isNotDynaData || (isDynaCrudRequestChange) || (!isDynaCrudRequestChange && !isSelectionChange)) {
                this.dynaCrudRequestSub.next(dynaCrudRequest);
              }

            }
          }
          if ((featureCode === this.transFerType.TRANS || featureCode === this.transFerType.UNLOAD)) {


            this.subs.add(
              this._activ.paramMap.pipe(distinctUntilChanged()).subscribe(idS => {
                const idSession: number = Number.parseInt(idS.get('id') as string);
                if (idSession) {
                  if (_isNumberValue(idSession)) { // true == 1, false == 1s
                    this.hostServiceColumnSingle._newIdSession.next({ id: idSession, code: featureCode });
                  }
                } else {
                  this.hostServiceColumnSingle._newIdSession.next({ id: undefined, code: null });
                }
              })
            );
          }
        }
      }
      ));

    const emptySelection: TableSelection = { rows: [], all: false };
    if (this.routeConf?.code != 'transferType') {
      this.store.dispatch(new BaseAction(DynaConfActionType.SelectionChange, emptySelection));
    }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
