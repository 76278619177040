import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubSink} from "subsink";
import {PartialLayout} from "../../models/layout-models";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {select, Store} from "@ngrx/store";
import {LayoutActionType, selectLayout, State} from "../../../store/layout/layout.reducers";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {BaseAction} from "../../../store/store.actions";
import {debounceTime, distinctUntilChanged} from "rxjs";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-main-set-insert',
  templateUrl: './main-set-insert.component.html',
  styleUrls: ['./main-set-insert.component.scss']
})
export class MainSetInsertComponent implements  OnInit, OnDestroy {

  private subs = new SubSink();
  layout: PartialLayout = { sidenav: { opened: false, mode: 'side'}, loading: {normal: false, block: false} } ;
  block!: boolean;
  noClose () {
    return ;
  }

  mobile = false;

  constructor(private ngUiload: NgxUiLoaderService, private store: Store<State>, private breakpointObserver: BreakpointObserver) {}


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.pipe(select(selectLayout), distinctUntilChanged(), debounceTime(environment.defaultDebounceTime)).subscribe(s => {
        this.layout = s;
        if (this.layout.loading.block) {
          this.ngUiload.start();
        } else {
          this.ngUiload.stop();
        }
      }),
      this.breakpointObserver.observe(['(max-width: 599px)']).subscribe((state: BreakpointState) => {
        this.mobile = state.matches;
        this.store.dispatch(new BaseAction(LayoutActionType.SidenavMode, state.matches ? 'overlay' : 'side'));
      })
    );
  }


  opened(evt: any) {
    if (this.layout.sidenav.mode === 'over') {

      this.store.dispatch(new BaseAction(LayoutActionType.SidenavButtonClick, evt));
    }
  }


}
