import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, throwError } from 'rxjs';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { CurrentDynaData, DynaConfActionType, DynaCrudRequest, RowSelect, State, selectCurrentDynaData } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { WarehouseSessionService } from '../../../services/warehouse-session.service';

@Component({
  selector: 'app-delete-session',
  templateUrl: './delete-session.component.html',
  styleUrls: ['./delete-session.component.scss']
})
export class DeleteSessionComponent implements TableActionComponent {

  data!: ActionData;
  routeCurrent!: CurrentDynaData;
  toRow = false;
  private subs = new SubSink();

  constructor(private store: Store<State>, private dialog: DialogService, private service: WarehouseSessionService) {}

  ngOnInit(): void {
    let first = true;
    this.subs.add(
      this.store.pipe(select(selectCurrentDynaData), debounceTime(environment.defaultDebounceTime), distinctUntilChanged()).subscribe(currentDynaData => {
        if (currentDynaData && currentDynaData.route) {
          if (first) {
            first = false;
            this.init(currentDynaData);
          }
          // const dynaCrudRequest: DynaCrudRequest = currentDynaData.request || { searchRequest: { filter: {} } };
          // this.dynaCrudRequestSub.next(dynaCrudRequest);
        }
      })
    );
  }

  init(currentDynaData: CurrentDynaData) {
    const routeConf = currentDynaData?.route;
    this.routeCurrent = currentDynaData;
    

  }






  deletedRow(e: Event) {
    e.stopPropagation();
    const dataRow: RowSelect = { row: this.data.item, select: this.toRow = !this.toRow };
    this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, dataRow));

    const options = {
      title: `dialog.deleted_${this.routeCurrent?.route?.code}.title`,
      message: `dialog.deleted_${this.routeCurrent?.route?.code}.message_delete`,
      cancelText: `dialog.deleted_${this.routeCurrent?.route?.code}.cancel`,
      confirmText: `dialog.deleted_${this.routeCurrent?.route?.code}.confirm`
    };

    
    this.dialog.open(options);
    this.dialog.confirmed().subscribe(confirmed => {
      if (confirmed) {
        if (this.data.item.id) {
          this.subs.add(
            this.service.deleteSession(this.data.item).pipe(catchError(err => {
              this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));
              return throwError(() => err);
            }), debounceTime(environment.defaultDebounceTime)).subscribe((data) => {
              // this.dynaCrudRequestSub.next(this.lastDynaCrudRequest);
              this.dialog.openSnack(`msg.${this.routeCurrent?.route?.code}.deleteSuccess`, 'delete');
              this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
              this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));

            })
          );
        }
      } else {
        this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));
      }
    });
    this.dialog.dialogRef.backdropClick().subscribe(data => {
      this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));
    });
  }

}
