import {Type} from '@angular/core';
import {TableActionType} from './table-config';
import {TitleComponent} from '../../layout/components/title/title.component';

import { DefaultSearchHeaderComponent } from '../components/default-search-header/default-search-header.component';
import { DeleteRowComponent } from '../components/delete-row/delete-row.component';
import { SearchRequest } from './dynacrud';

export interface PositionChild {
  component: Type<any> | null;
  attributes?: {[key: string]: any };
}

export interface paramsCustom {
  [key: string]: string | boolean
}

export interface PositionConf {
  children: PositionChild[];
}
// https://stackoverflow.com/questions/15338610/dynamically-loading-a-typescript-class-reflection-for-typescript
export interface RouteConf {
  code: string | undefined;
  params?: paramsCustom;
  first?: boolean;
  searchRequestDefault?: SearchRequest;
  noEndWithS?: boolean;
  featureType?: Type<any> | string;
  label?: string; // default localizzata su code || label
  roles?: string[]; // fixme AuthGuard  array?
  authenticated?: boolean; // fixme AuthGuard  array?
  position?: {[key: string]: PositionConf };
  attributes?: {[key: string]: any };
//  services: ..Services// servizi di base ... abbinabili?
}

export const DefaultRootCond: RouteConf = {
  code: undefined,
  position: {
    title: {
      children: [ {component: TitleComponent}]
    }
  },
};

export const DynamicListConf: RouteConf = {
  code: undefined,
  authenticated: true,
  position: {
    title: {
      children: [ {component: TitleComponent}]
    },
    header: {
      children: [ {component: DefaultSearchHeaderComponent}]
    }
  },
  attributes: { 
    DefaultListComponent : {
      tableConfig: {
        actions: [
          {type: TableActionType.GLOBAL, icon: 'add', event: 'add'},
          {type: TableActionType.RECORD, icon: 'edit', event: 'edit'},
          {type: TableActionType.RECORD, event: 'delete', actionComponent: DeleteRowComponent},
        ]
      }
    }
  }
};
