import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { ColumnFieldComponent, ColumnData } from 'src/app/core/components/table/Column';
import { ProductStructure, ProductStructureField, ProductStructureFieldType } from 'src/app/shared/models/entities';
import { ProductStructureService } from 'src/app/structure/services/product-structure.service';
import { SubSink } from 'subsink';

export interface FullField {
  obj: any;
  structureField: ProductStructureField;
}


interface FullFieldObject {
  obj: any;
  structureField: any;
}



@Component({
  selector: 'app-structure-info-first-fields-column',
  templateUrl: './structure-info-first-fields-column.component.html',
  styleUrls: ['./structure-info-first-fields-column.component.scss']
})
export class StructureInfoFirstFieldsColumnComponent  implements ColumnFieldComponent, OnInit, OnDestroy {

  private readonly iconColumns = ['foil', 'altered', 'inked', 'stamped', 'firstEdition', 'shadowless', 'signed', 'staff', 'holo'];
  // private readonly iconColumnsLanguage = ['American', 'Ceco', 'Cinese-S', 'Cinese-T', 'Coreano', 'Francese', 'Giapponese', 'Indonesiano', 'Inglese', 'Italiano', 'Olandese', 'Polacco', 'Portoghese', 'Russo', 'Spagnolo', 'Svedese', 'Tailandese', 'Tedesco', 'Ungherese'];
  // private readonly iconColumnsCondition = ['Excellent', 'Good', 'Light Played', 'Near Mint', 'Played', 'Poor', 'Mint'];


  @Input()
  data!: ColumnData;

  private subs = new SubSink();

  productPath = '';
  stockPath: string[] = [];
  omitFields: string[] = [];
  productStructure!: ProductStructure;
  fields: FullField[] = [];
  icons!: any[];

  constructor(private productStructureService: ProductStructureService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) { }


  ngOnInit() {
    this.subs.add(this.productStructureService.isReadyObs().subscribe(ready => {
      if (ready) {
        if (this.data.field.attributes) {
          this.productPath = this.data.field.attributes['productPath'] || this.productPath;
          // this.stockPath = this.data.field.attributes['stockPath'] || this.stockPath;
          this.stockPath = this.data.field.attributes['inventoryStockPath'] as string[] || this.stockPath;
       
          this.omitFields = this.data.field.attributes['omitFields'] || this.omitFields;
        }

        if (this.data.row) {
          const row = this.data.row;

          const categoryId: number | null = _.result(row, 'category.id', null) as number | null;
          const brandId: number | null = _.result(row, 'brand.id', null) as number | null;

          this.productStructure = this.productStructureService.findStructure(categoryId as number, brandId as number);
      

          if (this.productStructure) {
            const fieldsToshow = this.productStructure.allFields?.filter(f => this.omitFields.findIndex(s => s === f.field) === -1);
            if (fieldsToshow) {
              this.fields = fieldsToshow.map(f => ({ obj: _.result(row, '', row), structureField: f }));
          

            }
          }
        }
      }
    }));

  }


  getPositionSprite(i: any): { x?: number, y?: number } {
    if (i) {
      let x = this.getFloor((i % 10)) * 21;
      let y = this.getFloor((i / 10)) * 21;
      return { x: x, y: y };
    }

    return { x: undefined, y: undefined }
  }

  getFloor(x: any) {
    return Math.floor(x);
  }

  isImage(structureField: ProductStructureField) {
    return this.iconColumns.findIndex(s => s === structureField.field) !== -1;
  }

  // isImageLanguage(lingua: string | undefined) {
  //   return this.iconColumnsLanguage.findIndex(s => s === lingua) !== -1;
  // }

  // isImageCondition(condition: string | undefined) {
  //   return this.iconColumnsCondition.findIndex(s => s === condition) !== -1;
  // }

  getType(ff: FullField): FullField | undefined {
    if (ff && ff.structureField.type == ProductStructureFieldType.LOOKUP) {
      if ((ff.structureField.field == 'expansion') || (ff.structureField.field == 'rarity')) {
        return ff;
      }

    }

    return undefined;
  }

  getValueBool(ff: any): boolean | undefined {
    if (ff && ff.obj) {
      const val = ff.obj[ff.structureField.field as string];
      const formatValue = {[ff.structureField.field]: val };
      return formatValue[ff.structureField.field];
    }
    return undefined;
  }


  getValue(ff: FullField | undefined): string | undefined {
    if (ff) {
      const val = ff.structureField.type == ProductStructureFieldType.FLAG ? ff.structureField.field : ff.obj[ff.structureField.field as string];
      if (val) {
        return val.name || val.description || val.code || val.id || val;
      }

      return val;
    }
    return undefined;
  }

  getImagePath(ff: FullField): string | null {
   
    const val = ff.obj[ff.structureField.field as string];
    let id = '';

    if (val != undefined) {
  
      if (val && (val.id !== undefined)) {
        if (val.id != undefined && val.id != null) {
          id = '_' + val.id;
        } else {
          return null;
        }
  
      }
      this.matIconRegistry.addSvgIcon(
        `${ff.structureField.field}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/structure/${ff.structureField.field}${id}.svg`)
      );
      return `${ff.structureField.field}`;
    }

    return null;

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
