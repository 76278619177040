<div class="container-fluid extra-container">
  <div class="row">
    <div class="col-8">
  
  
      <mat-chip-listbox class="list-options">
        
  
        <div *ngFor="let item of statusCount ; index as i;">

          <mat-chip-option [disableRipple]="true" class="options" *ngIf="item"
                           color="{{customData.selectedStatus && customData.selectedStatus.id == item.status?.id ? 'primary' : ''}}"
                           [selected]="(customData && customData.selectedStatus && (customData.selectedStatus.id == item.status?.id)) || false"
                           [highlighted]="(customData && customData.selectedStatus && (customData.selectedStatus.id == item.status?.id)) || false"
                           (selectionChange)="search(item.status, $event); ">
    
            <div class="d-flex gap-2  justify-content-between w-100 " >
    
              <div class="d-flex">
                <span class="d_ron"
                      [ngStyle]="{color: customData && customData.selectedStatus?.id == item.status?.id ? 'black' : '#ffc107' }">

                      {{ item.count || '0'}}
                    </span>
              </div>
    
              <div class="d-flex">
                <span> {{item.status?.description}} </span>
              </div>
            </div>
    
    
          </mat-chip-option>
        </div>
      </mat-chip-listbox>
  
  
    </div>
  
    <div class="col-4">
  
      <mat-form-field class="d-flex">
        <mat-label>Select Motivazione</mat-label>
        <mat-select [(ngModel)]="motivationModel" (ngModelChange)="searchMotivation()" name="motivation" [compareWith]="compareEntityName">
          <mat-option [value]="undefined">All</mat-option>
          <mat-option *ngFor="let item of motivations; index as m" [value]="item" >
            {{item.description}}
  
          </mat-option>
        </mat-select>
      </mat-form-field>
  
  
    </div>

  </div>
</div>
