<div class="icon_lclose">
  <mat-dialog-actions align="end">
    <button mat-icon-button mat-dialog-close (keyup.esc)="onWindowsKey($event)" >
      <mat-icon>
        close
      </mat-icon>
    </button>
  </mat-dialog-actions>
</div>

<div class="title_ d-flex align-items-center justify-content-between ">
  <!-- <div class="d-flex ">
    View Report
  </div> -->
  <div class="d-flex ">
    <mat-slide-toggle [disabled]="loading" (change)="changeToogle($event)">
      <span style="min-width: 90px; width: 100%; display: flex;">
        {{ showNormal ? 'Normal' : 'Con extra info' }}

      </span>
    </mat-slide-toggle>
  </div>
</div>

<div class="container_dialog__ justify-content-center">

  <div class="row_list_product align-items-center flex-column d-flex w-100">
    <div class="d-flex ">
      <h5>Stai guardando: </h5>
    </div>
    <div class="d-flex detail_product" *ngIf="row && row.id && row.product && row.product.id">
      <table class="table" style="color:white;">
        <thead>
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Product</th>
            <th scope="col">Brand</th>
            <th *ngIf="!filter.groupByValue" scope="col">Extra Info</th>
            <th scope="col">Venduto</th>
            <th scope="col">Total</th>
            <th scope="col">Price Rules</th>

          </tr>
        </thead>
        <tbody>
          <tr class="custom_tr">
            <th scope="row">


              <img (click)="isOpen = !isOpen; $event.stopPropagation(); " #trigger="cdkOverlayOrigin" cdkOverlayOrigin src="{{row['product'].card_data.image}}" width="105" [alt]="row['product'].card_data.name">

              <ng-template #cdkConOverlay="cdkConnectedOverlay" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">

                <div class="d-flex panel_onfire justify-content-center flex-column align-items-center w-100">

                  <div class="d-flex w-100" style="max-width: 200px;">

                    <img src="{{row.product.card_data.image}}" [alt]="row.product.card_data.name" width="200">

                  </div>

                </div>
              </ng-template>


            </th>
            <td>
              <span>
                {{row.product.card_data.name}}

              </span>
            </td>
            <td>
              <span>
                {{row.product.brand.name}}

              </span>
            </td>

            <td *ngIf="!filter.groupByValue">
              <span>
                {{row.product.expansion.englishName}}

              </span>
            </td>

            <td>
              <span>
                {{row.totalQuantity}}
              </span>
            </td>

            <td>
              <span>
                {{row.stockTotal}}
              </span>
            </td>

            <td>
              <span>
                <cdk-virtual-scroll-viewport itemSize="20" #vp class="example-viewport" [ngStyle]="{'height': '150px'    }  "  >
                  <div  *cdkVirtualFor="let item of row.priceRules" #container_item_sc  class="example-item"  >

                    <a  target="_blank" [href]="getUrl(item.ruleId)" (click)="$event.stopPropagation();" class="list_link list_link_ho overflow-hidden">
                      {{item.productName}}
                    </a>

                  </div>
                </cdk-virtual-scroll-viewport>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<hr>

<ng-container *ngIf="!loading ; else noLoading">
  <ng-container *ngIf="defaultListConf">
    <ng-container *ngIf="showNormal; else NoNormal">
      <app-table  (status)="tableStatusChangeCart($event)" [displayedColumns]="defaultListConf.displayedColumns"
        [config]="defaultListConf.tableConfig" [dataSource]="dataSource"  class="table_onDialog_report" >
      </app-table>
    </ng-container>
    <ng-template #NoNormal>
      <app-table (status)="tableStatusChangeCartNoExtra($event)"  [displayedColumns]="defaultListConf.displayedColumns"
        [config]="defaultListConf.tableConfig" [dataSource]="dataSource" class="table_onDialog_report">
      </app-table>
    </ng-template>

  </ng-container>
</ng-container>

<ng-template #noLoading>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <mat-spinner color="primary"></mat-spinner>
  </div>
</ng-template>
