export interface DynacrudApi<T> {
  messages: Messages[] | null;
  meta: Meta<PaginationResponse> | null;
  data: T;
}

export interface SearchRequest extends Meta<PaginationRequest> {}

// only for customer search
export interface MovementRequest extends Meta<PaginationRequest> {}

export interface Meta<T> {
  filter?: SearchFilter;
  sort?: Sort[];
  pagination?: T;
}

export enum USER_ROLES {admin = 'admin', user = 'user'};


export interface PaginationResponse {
  pageElements?: number;
  pageSize?: number;
  length?: number;
  currentPage?: number;
  totalElements?: number;
  totalPages?: number;
  first: boolean;
  last: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
}

export interface Messages {
  code: string;
  field: string;
  message: string;
  type: string;
}

export interface PaginationRequest {
  page: number | string;
  size: number | string;
  length?: number | string;
  active?: boolean;
}

export interface SearchFilter {
  text?: string;
  filterBy?: Filter;
  parameters?: any;
  havingFilter?: Filter | Filter[];
}

export interface Sort {
  field: string;
  direction: SortDirection | string;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

/* EMPTY VALUES FOR MODEL */

/* TODO: Do we need to replace it in another file ? */

export function getEmptyMeta(): Meta<null> {
  return {
    filter: undefined,
    sort: undefined,
    pagination: undefined
  };
}

export interface Filter {
  field?: string;
  value?: string | any[] | Date | boolean | number;
  options?: any;
  filters?: Filter[];
  operation?: OperationType;
}

export enum OperationType {
  OR = 'OR',
  LIKE = 'LIKE',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  LTE = 'LTE',
  GTE = 'GTE',
  AND = 'AND',
  EQ = 'EQ',
  NEQ = 'NEQ',
  IS_NULL= 'IS_NULL',
  IS_NOT_NULL= 'IS_NOT_NULL',
  GT= 'GT',
  LT= 'LT'
}
