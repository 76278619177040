import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, throwError, Observable } from 'rxjs';
import { DynacrudApi } from 'src/app/core/models/dynacrud';
import { WarehouseChangeMovement, WarehouseSessionEdit, WarehouseSessionStatus } from 'src/app/shared/models/entities';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseSessionService {
  private dataSource = new BehaviorSubject<boolean>(false);
  dataAggiorna = this.dataSource.asObservable();

  constructor(private http: HttpClient) { }

  updatedDataSessionCompleted(data: boolean) {
    this.dataSource.next(data);
  }

  private reloadSessionSubject = new BehaviorSubject<boolean>(false);
  reloadSessionObs = this.reloadSessionSubject.asObservable();

  private getSessionSubject = new BehaviorSubject<any>({});
  getSessionObs = this.getSessionSubject.asObservable();

  getListSession(v: boolean) {
    this.reloadPriority(v);
    




  }

  setSessionMemory(v: any) {
    this.getSessionSubject.next(v);

  }

  reloadPriority(v: boolean) {
    this.reloadSessionSubject.next(v);

  }

  private fullReloadSessionSubject = new BehaviorSubject<boolean>(false);

  public fullReloadSessionObs = this.fullReloadSessionSubject.asObservable();

  fullReloadSession(v: boolean) {
    this.fullReloadSessionSubject.next(v);
  }


  public saveEditSession(session: WarehouseSessionEdit) {
    return this.http.put<DynacrudApi<WarehouseSessionEdit>>(`${environment.apiUrl}/v2/sessions/${session.id}`, session).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public changeStatus(sessionId: number , status: number): Observable<DynacrudApi<WarehouseSessionStatus>> {
    return this.http.patch<DynacrudApi<WarehouseSessionStatus>>(`${environment.apiUrl}/v2/sessions/${sessionId}/status`, status);
  }

  public completeSession(sessionId: number , movementId?: number): Observable<DynacrudApi<WarehouseSessionStatus>> {
    if (movementId) {
      return this.http.patch<DynacrudApi<WarehouseSessionStatus>>(`${environment.apiUrl}/v2/sessions/${sessionId}/movements/${movementId}/complete`, null);
    } else {
      return this.http.patch<DynacrudApi<WarehouseSessionStatus>>(`${environment.apiUrl}/v2/sessions/${sessionId}/movements/complete`, null);
    }
  }

  public deleteMovement(sessionId: number , movementId: number): Observable<DynacrudApi<WarehouseSessionStatus>> {
    return this.http.delete<DynacrudApi<WarehouseSessionStatus>>(`${environment.apiUrl}/v2/sessions/${sessionId}/movements/${movementId}`);
  }

  public getListSessionMovements(sessionId: number): Observable<DynacrudApi<WarehouseChangeMovement>> {
    return this.http.get<DynacrudApi<WarehouseChangeMovement>>(`${environment.apiUrl}/v2/sessions/${sessionId}/movements`);

  }

  public getSingleSession(id: number): Observable<DynacrudApi<any>> {
    return this.http.get<DynacrudApi<any>>(`${environment.apiUrl}/v2/sessions/${id}`);
  }

  public deleteSession(sessionId: number) {
    return this.http.delete<DynacrudApi<WarehouseSessionStatus>>(`${environment.apiUrl}/v2/sessions/${sessionId}`);
  }
}
