<mat-toolbar class="header-cell__">

  <mat-toolbar-row>
    <div class="d-flex w-100 justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <button mat-icon-button (click)="toogleSideNav()" id="menu-toggler">
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-icon-button routerLink="/" id="home">
          <mat-icon>home</mat-icon>
        </button>
        <app-title [isEditTitle]="edita"></app-title>
      </div>

      <div class="d-flex align-items-center">
        <app-add-session-movement></app-add-session-movement>
        <app-layout-position position="search"></app-layout-position>
        <button matTooltip="{{username}}" class="custom_btn_" mat-button [matMenuTriggerFor]="menu" id="user-dropdown-toggler">
          <mat-icon>account_circle</mat-icon>
          <span class="nome_email">
            {{username}}
          </span>
        </button>

        <mat-menu #menu="matMenu" x-position="before">
          <button mat-menu-item disabled> Refresh</button>
          <button mat-menu-item disabled> Profilo</button>
          <button mat-menu-item (click)="logout()"> Logout</button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
  <!--mat-toolbar-row>
  <small>FIXME K3 qui vorrei un array di tollbar dinamiche per le azioni disponibile sul record selezionato ... selezione multipla ecc... ordinamenti , indicatori di filtro</small>
</mat-toolbar-row-->

</mat-toolbar>