<mat-form-field class="col-12 contenedor_prodMoon">
  <mat-label>{{ value?.product ? value?.product?.name : ('default.product_search' | translate) }}</mat-label>
  <input class="search_label_general" #productsAutocompleteTrigger="matAutocompleteTrigger" type="text"
    [required]="RequiredValue" matInput #productSuggestInput [disabled]="value?.product?.id"
    [matAutocomplete]="productsAutocomplete"
    [placeholder]="value?.product ? value?.product?.name : ('default.product_search' | translate )">

  <!-- posso fare un caricamento al click -->

  <!-- (mouseover)="hover()" -->
  <button *ngIf="value?.product?.imageName" disableRipple="true" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
    (mouseover)="hover(); $event.preventDefault();" mat-icon-button matPrefix color="primary">
    <mat-icon class="icon-image">image</mat-icon>

  </button>


  <div *ngIf="value && showImg && value.product && value.product?.imageName"
    [ngClass]="{'boxImage' : showImg && value.product.imageName }" (click)="onMouseLeave()">
    <img [src]="value.product.imageName" [alt]="value.product.imageName" (error)="noImage(value.product)">
  </div>


  <!-- productSuggestInput.value = value?.product?.name; -->
  <button mat-icon-button matSuffix color="warn" *ngIf="value?.product" (click)="clear(); $event.preventDefault(); ">
    <mat-icon class="icon_x">delete_forever</mat-icon>
  </button>

  <button (click)="$event.preventDefault();" mat-icon-button matSuffix color="primary"
    *ngIf="!value?.product && !loading">
    <mat-icon class="icon_x">search</mat-icon>
  </button>
  <button mat-icon-button matSuffix color="warning" *ngIf="loading">
    <mat-spinner diameter="20"></mat-spinner>
  </button>

  <mat-hint
    *ngIf="!value?.product && productSuggestInput && productSuggestInput.value && productSuggestInput.value.length > 0 && productSuggestInput.value.length < 3 ">{{'search_min_length'
    | translate: {numCharacters: 3} }}</mat-hint>

  <mat-autocomplete class="panelHover_" #productsAutocomplete="matAutocomplete"
    (optionSelected)="selectProduct( $event.option.value);" (opened)="isOpened($event)">
    <mat-option *ngFor="let product of product$ | async" [value]="product" class="opt_list_"
      (click)="getProduct($event); $event.preventDefault(); ">
      <div class="container_list">

        <div class="img__" *ngIf="product?.imageName">

          <img [alt]="product?.name || ('default image with id' + product.id)" [src]="product.imageName"
            #trigger="cdkOverlayOrigin" cdkOverlayOrigin width="24" (error)="noImage(product)"
            (mouseover)="hoverProduct($event, product)" (mouseleave)="onMouseLeave()">

          <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOpen[product.id]">

            <div class="d-flex panel_onfire_ow justify-content-center flex-column align-items-center w-100">
              <div class="d-flex w-100">
                <img [src]="product.imageName" (error)="noImage(product)" [alt]="product.imageName" width="200">
              </div>
            </div>
          </ng-template>

          <div class="item_cont__Pm">
            <div style="display: flex; align-items: center;">
              <span>{{product.name }} {{' ' + (product.expansion ? '- ' + product.expansion.name : '')}}</span>

              <ng-container *ngIf="product.expansion && product.expansion?.icon && validExpansionIcon(product.brand) ">
                <span>{{' ' + '-' + ' '}}</span>

                <app-column-icon-field-select [item]="product.expansion"
                  [brandId]="product.brandId"></app-column-icon-field-select>


              </ng-container>
            </div>

          </div>


        </div>
      </div>


    </mat-option>
  </mat-autocomplete>
</mat-form-field>