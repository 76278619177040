import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {WarehouseMovementDTO} from "../../features/warehouse/models/entities";
import {BehaviorSubject, Observable} from "rxjs";
import {DynacrudApi} from "../models/dynacrud";
import {TypeSaveInsert} from "../components/table/table-edit-rows/table-edit-rows.component";
import {DynaCrudRequest} from "../../store/shared/shared.reducers";


interface DataJson<key> {
  data: key;
}

export interface Loads {filter: DynaCrudRequest , items: any[] | Object[] | Object }


@Injectable({
  providedIn: 'root'
})
export class SetInsertService {

  constructor(private http: HttpClient) { }

  public saveMovements(WhMovement: WarehouseMovementDTO, type: TypeSaveInsert, sessionId?: number): Observable<DynacrudApi<WarehouseMovementDTO>> {
    if (sessionId) {
      return this.http.post<DynacrudApi<WarehouseMovementDTO>>(`${environment.apiUrl}/v2/sessions/${sessionId}/${ type == TypeSaveInsert.SINGLE ?  'movements' : 'movementsbulk' }`, WhMovement);
    } else {
      return this.http.post<DynacrudApi<WarehouseMovementDTO>>(`${environment.apiUrl}/v2/sessions/${ type == TypeSaveInsert.SINGLE ?  'movements' : 'movementsbulk' }`, WhMovement);
    }

  }

  public youCanClickButtonReload = new BehaviorSubject(true);

  public youCanClickButton() {

    return this.youCanClickButtonReload.asObservable();

  }


  private subjectLoads = new BehaviorSubject<any[] | undefined>(undefined);
  public readonly readLoads = this.subjectLoads.asObservable();

  saveObjectKey (key: string, loads: Loads  ) {
    return this.http.post<DataJson<Loads>>(`${environment.apiUrl}/v2/userDrafts/${key}`, loads);
  }

  getLoads (key: string) {
    return this.http.get<DataJson<Loads>>(`${environment.apiUrl}/v2/userDrafts/${key}`);
  }

  deleteLoads (key: string) {
    return this.http.delete<DataJson<Loads>>(`${environment.apiUrl}/v2/userDrafts/${key}`);
  }

  setLoadsAsync (values: any[]) {
    this.subjectLoads.next(values);
  }


}
