import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { isArray } from 'is-what';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged, of, take } from 'rxjs';
import { Filter, OperationType, Sort, SortDirection } from 'src/app/core/models/dynacrud';
import {
  Brand,
  Category,
  CategoryStructure,
  Entity,
  ProductStructure,
  ProductStructureFieldType,
  Warehouse
} from 'src/app/shared/models/entities';
import { DynaConfActionType, DynaCrudRequest, selectDynaCrudRequest, State, } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import {
  ProductDoubleSearchComponent
} from 'src/app/structure/components/structure/product-double-search/product-double-search.component';
import { ProductSearch, StructureDataFilter } from 'src/app/structure/models/Structure';
import { ProductStructureService } from 'src/app/structure/services/product-structure.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from "@angular/router";
import { SetInsertService } from "../../../../../core/services/set-insert.service";
import { SharedService } from "../../../../../shared/services/shared.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-set-insert-filter',
  templateUrl: './set-insert-filter.component.html',
  styleUrls: ['./set-insert-filter.component.scss'],

})
export class SetInsertFilterComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('productDouble2') productRef!: ProductDoubleSearchComponent;
  structureReady = false;

  comment!: string;
  sortItems: Sort[] = [{ field: 'numberOrder', direction: SortDirection.ASC }];

  animatedButton = false;

  private subs = new SubSink();
  filter: StructureDataFilter = { brand: [], category: [] };
  private crudRequest: DynaCrudRequest = {
    searchRequest: {},
    customData: this.filter
  };
  productStructure: ProductStructure | undefined;
  private categoryStructures: CategoryStructure[] = [];
  brandFilter: CallableFunction = (w: Brand) => !w.obsolete;
  categoryFilter: CallableFunction = (w: Category) => w.macroCategory?.id != 2;
  warehouseFilter: CallableFunction = (w: Warehouse) => !w.virtual && !w.obsolete;

  constructor(private store: Store<State>, private productStructureService: ProductStructureService, private el: ElementRef, private router: Router,
    private activateRouter: ActivatedRoute, private setInsertService: SetInsertService, private sharedService: SharedService, private dialog: MatDialog) {
  }


  get productSearch(): ProductSearch | undefined {
    return this.filter.productSearch;
  }

  set productSearch(productSearch: ProductSearch | undefined) {
    this.filter.productSearch = productSearch;

    if (this.filter?.productStructure?.id || this.filter.productSearch?.product?.id) {

      this.loadSearchProductStructure(true);
    

     

     
    }
  }

  get brands(): Brand[] | undefined {
    return this.filter.brand;
  }

  set brands(brands: Brand[] | undefined) {
    if (brands) {

      this.filter.brand = brands;
    }
    this.loadStructures();
  }

  get categories(): Category[] | undefined {
    return this.filter.category;
  }

  set categories(categories: Category[] | undefined) {

    if (categories) {

      this.filter.category = categories;
    }
    this.loadStructures();
  }

  get productCategory(): Category | undefined {
    return this.filter?.productSearch?.product?.category;
  }

  set productCategory(cat: Category | undefined) {
    if (cat && this.filter.productSearch?.product) {
      this.filter.productSearch.product.category = cat;
    }
  }

  get productBrand(): Brand | undefined {
    return this.filter?.productSearch?.product?.brand;
  }

  set productBrand(bd: Brand | undefined) {
    if (bd && this.filter.productSearch?.product) {
      this.filter.productSearch.product.brand = bd;
    }
  }

  get numberProduct(): number | undefined {
    return this.filter?.productSearch?.product?.number;
  }

  set numberProduct(nm: number | undefined) {
    if (nm && this.filter.productSearch?.product) {
      this.filter.productSearch.product.number = nm;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  isMax(value: string) {
    return parseInt(value);
  }


  sessionId: number | undefined;

  goSession() {
    this.router.navigateByUrl('app/warehouse/session' + '/' + this.sessionId).then(() => {
      this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
    });
  }

  // fixme importante
  public validBrand() {
    const [brand, category] = [this.brands as unknown as Brand, this.categories as unknown as Category];

    return brand && brand.id && category && category.id;
  }

  ngOnInit(): void {

    this.subs.add(
      this.activateRouter.params.pipe().subscribe(pr => {
        const id: number | undefined = pr['id'];
        if (id) {
          this.sessionId = id;
        }
      })
    );

    let first = true;
    this.subs.add(this.productStructureService.isReadyObs().subscribe(ret => {
      if (ret) {
        this.subs.add(this.store.pipe(select(selectDynaCrudRequest), debounceTime(environment.defaultDebounceTime), distinctUntilChanged()).subscribe(request => {

          this.crudRequest = request ? { ...request } : this.crudRequest;

          if (this.crudRequest.customData) {
            this.filter = { ...this.crudRequest.customData };
            if (this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.id) {
              this.firstHasProduct = false;
            }
          }

          this.filter.brand = this.filter.brand && this.filter.brand.length > 0 ? [...this.filter.brand] : this.filter.brand;
          this.filter.category = this.filter.category && this.filter.category.length > 0 ? [...this.filter.category] : this.filter.category;
          this.filter.expansion = this.filter.expansion || [];
          this.sortItems = this.crudRequest.searchRequest && this.crudRequest.searchRequest.sort && this.crudRequest.searchRequest.sort.length > 0 ? this.crudRequest.searchRequest.sort : this.sortItems;
          this.structureReady = true;
          if (!this.filter.productStructure || !this.productStructure) {
            this.loadStructures();
          }

          if (first) {
            first = false;
            this.search();
          }
        }
        ));
      }
    }
    ));
  }

  private loadSearchProductStructure(refresh: boolean) {
    // this.loadStructures();
    if (this.filter.productSearch?.click) {
      this.filter.productSearch.click = false;
      if (refresh) {

        this.aps();
      }
      const OBS = of(1);
      this.subs.add(
        OBS.pipe(take(1), distinctUntilChanged()).subscribe(() => {

          this.search(new Event('click'));
        })
      );
    }
  }

  protected loadStructures() {
    const productSearch: ProductSearch = this.filter.productSearch as ProductSearch;
    if (productSearch && productSearch.product && productSearch.product.id) {
      this.productStructure = this.productStructureService.findStructure(productSearch.product.category.id, productSearch.product.brandId);
    } else {
      this.categoryStructures = [];
      const brandIds: number[] = this.brands && this.brands.length > 0 && this.brands.filter(b => b != null).length > 0 ? this.brands.map(b => b.id) : (this.brands as Brand) && (this.brands as Brand).id ? [(this.brands as Brand).id] : [];

      if (this.categories && this.categories.length > 0) {
        this.categories?.filter(c => c.id != null).map(c => c.id).forEach(cId => {
          brandIds.forEach(bId => {
            this.productStructureService.getCategoryStructures(cId, bId).forEach(cs => this.categoryStructures.push(cs));
          });
        });

      } else {

        this.productStructureService.getCategoryStructures((this.categories as Category | undefined)?.id, brandIds.find(d => d)).forEach(cs => this.categoryStructures.push(cs));

      }


      // FIXME K3 potremmo anche prevederne più di uno!! e fare un merge :) dei fields...
      const catStruct = _.uniqBy(this.categoryStructures, 'productStructureId');
      if (catStruct.length === 1) {

        const found = this.productStructureService.findStructure(catStruct[0].categoryId, catStruct[0].brandId);
        if (found && found.id !== this.productStructure?.id) {
          this.productStructure = found;
        }

      } else {
        this.productStructure = undefined;
      }
    }

    this.filter.productStructure = this.productStructure;

    if ((!this.filter.productStructure || (this.hasProduct() && this.filter.productStructure)) && this.firstHasProduct) {
      this.firstHasProduct = false;
      this.filter['color'] = [];
      this.filter['condition'] = [];
      this.filter['expansion'] = [];
      this.filter['firstEdition'] = undefined;
      this.filter['foil'] = undefined;
      this.filter['language'] = [];
      this.filter['rarity'] = undefined;

      this.filter['reserved'] = undefined;
      this.filter['signed'] = undefined;
      this.filter['altered'] = undefined;
      this.filter['stamped'] = undefined;
      this.filter['maxNumber'] = undefined;
      this.filter['minNumber'] = undefined;

      this.filter['shadowless'] = undefined;
      this.filter['inked'] = undefined;
      this.filter['firstEdition'] = undefined;
      this.filter['minNumber'] = undefined;

      if (this.filter && this.filter['holo']) {
        this.filter['holo'] = undefined;
      }
    }

    if (this.hasProduct()) {
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit() {
    if (this.hasProduct()) {

      setTimeout(() => {
        this.el.nativeElement.querySelector('.searchFocus')?.focus();
      }, 0);

    } else {
      setTimeout(() => {
        this.el.nativeElement.querySelector('.mat-mdc-input-element')?.focus();
      }, 0);
    }
  }

  firstHasProduct = false;

  hasProduct(): boolean {
    if (!(this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.id)) {
      this.firstHasProduct = true;
    }
    return this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.id;
  }

  search(click?: Event) {
    const possibleFilters: Filter[] = [{
      operation: OperationType.EQ,
      field: 'obsolete',
      value: false
    }, { operation: OperationType.GT, field: 'id', value: 0 }];

    const hasProduct = this.hasProduct();
    let textFilter = this.filter.productSearch ? this.filter.productSearch.text : this.productRef?.productSuggestInput ? this.productRef.productSuggestInput.nativeElement.value : null;
    if (!hasProduct) {
      if (this.brands && this.brands.length > 0) {
        possibleFilters.push({ operation: OperationType.IN, field: 'brand.id', value: this.brands.map(b => b.id) });
      } else if (this.brands && (this.brands as Brand).id) {

        possibleFilters.push({ operation: OperationType.EQ, field: 'brand.id', value: (this.brands as Brand).id });

      }

      if (this.categories && this.categories.length > 0) {
        possibleFilters.push({
          operation: OperationType.IN,
          field: 'category.id',
          value: this.categories.map(b => b.id)
        });

      } else if (this.categories && (this.categories as unknown as Category).id) {

        possibleFilters.push({
          operation: OperationType.EQ,
          field: 'category.id',
          value: (this.categories as unknown as Category).id
        });

      }

    } else {
      // textFilter = null; // questo dice se rimane il testo oppure no
      textFilter = null;
      if (this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.id > 0) {
        possibleFilters.push({ operation: OperationType.EQ, field: 'id', value: this.filter.productSearch.product.id });
      }

      if (this.filter && this.filter.productSearch && this.filter.productSearch.brand != undefined && this.filter.productSearch.category != undefined) {
        this.brands = this.filter.productSearch.brand as any[];
        this.categories = this.filter.productSearch.category as unknown as any[];

        possibleFilters.push({
          operation: OperationType.IN,
          field: 'brand.id',
          value: (this.brands as Brand)?.id
        });

        possibleFilters.push({
          operation: OperationType.IN,
          field: 'category.id',
          value: (this.categories as unknown as Category)?.id
        });


      } else if (this.filter && this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.brand != undefined && this.filter.productSearch.product.category != undefined) {
        this.brands = this.filter.productSearch.product.brand as any[];
        this.categories = this.filter.productSearch.product.category as unknown as any[];

        possibleFilters.push({
          operation: OperationType.IN,
          field: 'brand.id',
          value: (this.brands as Brand)?.id
        });
        possibleFilters.push({
          operation: OperationType.IN,
          field: 'category.id',
          value: (this.categories as unknown as Category)?.id
        });
      }
    }

    if (this.filter.productSearch?.product?.idMetaProduct && this.filter.productSearch?.product?.id < 0) {
      const valueMetaProduct = this.filter.productSearch.product.idMetaProduct;
      possibleFilters.push({ operation: OperationType.EQ, field: 'idMetaProduct', value: valueMetaProduct });
    }


    if (this.filter.minNumber) {
      const ValueFilter = _.padStart(this.filter.minNumber || '00000000000000000000', 20, '0');
      possibleFilters.push({ operation: OperationType.GTE, field: 'numberOrder', value: ValueFilter });
    }
    if (this.filter.maxNumber || this.filter.maxNumber == '0') {
      const ValueFilterMax = _.padStart(this.filter.maxNumber || '90000000000000000000', 20, '0');
      possibleFilters.push({
        operation: OperationType.LTE,
        field: 'numberOrder',
        value: ValueFilterMax == '90000000000000000000' ? '00000000000000000000' : ValueFilterMax
      });
    }


    if (this.productStructure && this.productStructure.allFields) {
      this.productStructure.allFields.forEach(f => {
        if (this.filter[f.field as string] != null) {
          if (!hasProduct || f.stock) {
            const fieldName = (f.stock ? 'combination.' : '') + f.field + (f.type === ProductStructureFieldType.LOOKUP ? '.id' : '');
            if (isArray(this.filter[f.field as string])) {
              if (this.filter[f.field as string].length > 0) {
                possibleFilters.push({
                  operation: OperationType.IN,
                  field: fieldName,
                  value: this.filter[f.field as string].map((b: Entity) => b.id)
                });
              }
            } else {
              possibleFilters.push({
                operation: OperationType.IN,
                field: fieldName,
                value: this.filter[f.field as string]
              });
            }
          }
        }
      });
    }

    for (const f of possibleFilters) {
      if (f.operation === OperationType.IN) {
        const arrayValue = f.value as any[];
        if (arrayValue.length === 1) {
          f.operation = OperationType.EQ;
          f.value = arrayValue[0];
        }
      }
    }

    let filterByObj: Filter | undefined = undefined;

    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = { operation: OperationType.AND, filters: possibleFilters };
      } else {
        filterByObj = possibleFilters[0];
      }
    }

    this.crudRequest.customData = this.filter;

    if (click) {
      if (click.type) {
        this.crudRequest.searchRequest = {
          filter: { text: textFilter, filterBy: filterByObj },
          pagination: { page: 0, size: 2000, active: true }
        };
        this.clearPagination();
      }
    } else {
      this.crudRequest.searchRequest = {
        filter: { text: textFilter, filterBy: filterByObj },
        pagination: { page: 0, size: 2000 }
      };
    }

    this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));

    this.sharedService.nextNormalDataSave({ reload: true, crudRequest: this.crudRequest });

    this.focusSearch();
  }

  focusSearch() {
    setTimeout(() => {
      this.el.nativeElement.querySelector('.mat-mdc-input-element')?.focus();
    }, 100);

    setTimeout(
      () => {
        this.animatedButton = false;
      }, 600
    )


  }

  selectsReset(f: NgForm) {
    const form = f;
    if (this.productSearch) {

      this.productRef.brandCategory = { brand: undefined, category: undefined };

      this.productRef.productSuggestInput.nativeElement.value = '';
      this.productSearch.text = '';
      this.productSearch.product = undefined;
    }
    form.reset();
    this.search();
    this.clearSort(false);

  }

  private aps() {
    const ap = _.cloneDeep(this.productStructure);
    if (this.productStructure && ap) {
      this.productStructure.fields = [];
      this.productStructure.fields = ap.fields;

    }
  }



  public getBrandCategory(value: any) {

    console.log(value);

    if (value != undefined && value != 'refresh') {
      this.filter.brand = this.productRef.brandCategory.brand ? this.productRef.brandCategory.brand as any : undefined;
      this.filter.category = this.productRef.brandCategory.category ? this.productRef.brandCategory.category as any : undefined;
      
      console.log(this.filter);
      this.aps();
    }
    if (value && value == 'refresh') {
      this.aps();

    }
  }

  private clearPagination() {
    const paginationClear: any = { pagination: { page: 0, size: 2000 }, sort: this.sortItems };
    this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, paginationClear));
  }

  clearSort(onlySort: boolean) {
    this.sortItems = [];
    if (onlySort) {
      if (this.crudRequest && this.crudRequest.searchRequest && this.crudRequest.searchRequest.sort) {
        this.crudRequest.searchRequest.sort = [];
      }

    } else {

      if (this.crudRequest && this.crudRequest.searchRequest && this.crudRequest.searchRequest.sort) {
        this.crudRequest.searchRequest.sort = [];
      }

      if (this.crudRequest.searchRequest && this.crudRequest.searchRequest.pagination) {
        this.crudRequest.searchRequest.pagination = { page: 0, size: 2000 };
      }

    }


    this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, this.crudRequest.searchRequest));

  }

}
