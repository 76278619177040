<div class="table-container" *ngIf=" tableData && config?.columnsFields">
    <!-- sort remove -->

    <mat-table class="tableSummary mat-elevation-z2" #table [dataSource]="tableData.data">
        <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
        <ng-container *ngIf="isSelectionMultiple()">
            <ng-container [cdkColumnDef]="'multi_selection'">
                <mat-header-cell class="table-header" *cdkHeaderCellDef>
                    <div class="select-field-selectable in_header">
                        <!-- {{'table.page_selection' | translate}} -->
                        <!-- !disabledOnWrapp() -->
                        <mat-checkbox [disabled]="tableData.data.length == 0 || disableForSession()"
                            [(ngModel)]="selectAll"></mat-checkbox>
                    </div>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                    <div class="select-field-selectable in_row" (click)=" $event.stopPropagation(); ">
                       

                        <mat-checkbox name="single_select"
                                [ngClass]="{'disabl__checkbox': !isEnabled('select_row', row) }"
                                [disabled]="!isEnabled('select_row', row)"
                                (click)=" onSelect({row, not: true}); $event.stopPropagation(); "
                                [checked]="isSelected(row)"></mat-checkbox>
                    </div>
                </mat-cell>
            </ng-container>
        </ng-container>

        <!-- Var columns -->
        <ng-container *ngFor="let columnField of config?.columnsFields">
            <ng-container [cdkColumnDef]="columnField.id">
                <mat-header-cell class="table-header" *cdkHeaderCellDef>
                    <div style="display: flex;flex-direction: column;">
                        <div style="display: flex; flex-direction: row; width: 100%;"
                            [ngClass]="{'pointerMouse' : columnField?.sortable}">
                            <div class="select-field" [ngClass]="{'pointerMouse' : columnField?.sortable}"
                                (click)="setSort(columnField)"> {{columnField.label | translate}}</div>

                            <span style="cursor:pointer" matBadgeSize="small" *ngIf="sortStatus[columnField.id]"
                                matBadgePosition="before" [matBadge]="sortStatus[columnField.id].position + 1">
                                <mat-icon>

                                    {{sortStatus[columnField.id].direction == 'ASC' ? 'arrow_drop_up' :
                                    'arrow_drop_down'}}

                                </mat-icon>
                            </span>
                        </div>
                        <ng-container *ngIf="columnField.editable">
                            <ng-container *ngIf="columnField.typeField; else noTypeField">
                                <div class="input-group input-group-sm default_item__">
                                    <div class="input__">
                                        <input [disabled]="setAvailable" [min]="0"
                                            name="{{columnField}}_{{columnField.id}}"
                                            [(ngModel)]="bindingType(columnField.id).typeIdInput"
                                            [type]="columnField.typeField" class="form-control item__"
                                            aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                                    </div>
                                    <div class="all_available">
                                        <button matTooltip="{{setAvailable}}" disableRipple="{{true}}" class="btn__"
                                            (click)="setQuantity($event)" mat-icon-button>
                                            <mat-icon [color]="setAvailable == true ? 'primary' : null"
                                                class="icon__">{{ setAvailable ? 'swipe_down' : 'swipe_up'}}</mat-icon>
                                        </button>

                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #noTypeField>
                                <div class="input-group input-group-sm default_item__cust">
                                    <div class="c_1">
                                        <input type="number" [min]="0" [(ngModel)]="bindingType(columnField.id).typeId"
                                            class="form-control item__">
                                    </div>
                                    <div class="c_2">
                                        <select #typeSelect="ngModel" class="form-select form-select-sm item__"
                                            style="background-position: center !important;"
                                            [(ngModel)]="bindingType(columnField.id).typeId" [name]="columnField.id">
                                            <option [ngValue]="null"></option>
                                            <!-- fixme santiago cablato il whHost -->
                                            <option *ngFor="let item of whHost[whHost.length - 1]?.warehouses"
                                                [ngValue]="item">
                                                {{item?.id}} - {{item?.name}}
                                            </option>

                                        </select>
                                    </div>
                                </div>
                            </ng-template>

                        </ng-container>
                    </div>
                </mat-header-cell>

                <mat-cell *cdkCellDef="let row"
                    [ngClass]="{'success': (statusPer && row && statusPer.row && (row.id == statusPer.row.id) ), 'danger': (!statusPer || statusPer == undefined || statusPer == null)}">

                    <div class="_isNewHeader non_show" [ngClass]="{'pointerMouse' : columnField?.sortable}"
                        (click)="setSort(columnField); $event.stopPropagation(); ">
                        <span class="mobile-label">{{columnField.label | translate}}</span>

                        <span style="cursor:pointer" matBadgeSize="small" *ngIf="sortStatus[columnField.id]"
                            matBadgePosition="before" [matBadge]="sortStatus[columnField.id].position + 1">
                            <mat-icon>

                                {{sortStatus[columnField.id].direction == 'ASC' ? 'arrow_drop_up' :
                                'arrow_drop_down'}}

                            </mat-icon>
                        </span>

                    </div>
                    <app-column style="width: auto;" [instanceCustom]="" [field]="columnField" [row]="row"></app-column>
                </mat-cell>
            </ng-container>
        </ng-container>

        <ng-template #defaultGetActions>
            <ng-container *ngIf="tableData && tableData.tableStatus && tableData.tableStatus.sort  ">

                <button *ngIf="tableData.tableStatus.sort.length > 0" matBadgeSize="small"
                    matBadge="{{tableData.tableStatus.sort.length}}" [matTooltip]="'clear sort' | translate"
                    mat-icon-button color="warn" (click)="clearSort(); $event.preventDefault();">
                    <mat-icon>sort</mat-icon>
                </button>

            </ng-container>
        </ng-template>

        <ng-container [cdkColumnDef]="'actions'"
            *ngIf="getActions('RECORD') && getActions('RECORD').length > 0; else defaultGetActions">
            <mat-header-cell class="table-header" *cdkHeaderCellDef>

                <ng-container *ngIf="featureCode === 'buylistTemplate'">
                    <button (click)="modifyModeIcon()" mat-icon-button>
                        <mat-icon>switch_access_shortcut</mat-icon>
                    </button>
                </ng-container>

                <ng-container *ngIf="getActions('GLOBAL') && getActions('GLOBAL').length > 0; else action_descr ">
                    <ng-container
                        *ngTemplateOutlet="!mediumScreenAndDown ? defaultActions : dropdownActions; context: { item: null, type: 'GLOBAL'}"></ng-container>
                </ng-container>
                <ng-template #action_descr>{{'default.actions' | translate}} </ng-template>

            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                <ng-container
                    *ngTemplateOutlet="!mediumScreenAndDown ? defaultActions : dropdownActions; context: { item: row, type: 'RECORD'}"></ng-container>
            </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns;  sticky: true" class="st_header"></mat-header-row>
        <mat-row [ngClass]="{'selected':isSelected(row), 'selectable':selectMode}"
            (click)="onRowClick(row, $event); $event.preventDefault();"
            *cdkRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>

    <ng-container *ngIf="config?.paginatorMode == 'none'; else default_pagination">
        <mat-paginator style="display: none" [pageSize]="tableData.tableStatus.page.pageSize"
            [pageIndex]="tableData.tableStatus.page.pageIndex" [pageSizeOptions]="[20,50,100]"
            [showFirstLastButtons]="true" [length]="tableData.tableStatus.page.length">
        </mat-paginator>
    </ng-container>

</div>



<ng-template #default_pagination>

    <mat-paginator class="mat-elevation-z2" [pageSize]="tableData.tableStatus.page.pageSize"
        [pageIndex]="tableData.tableStatus.page.pageIndex" [pageSizeOptions]="[20,50,100]" [showFirstLastButtons]="true"
        [length]="tableData.tableStatus.page.length">
    </mat-paginator>

        <ng-container *ngIf="!notInputPage ">
            <mat-form-field class="custm_">
                <mat-label>to page</mat-label>
                <input [disabled]="(tableData.tableStatus.page.length == 0) || !tableData.tableStatus.page.length"
                    #inputSingle_ matInput name="input_single" [min]="1" type="number" (keyup.enter)="goToPage($event)"
                    [(ngModel)]="pageIndex">
            </mat-form-field>
        </ng-container>

</ng-template>

<ng-template #dropdownActions let-item="item" let-type="type" let-tableComponent="tableComponent">
    <button (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <ng-container *ngFor="let ac of getActions(type)">
            <ng-container *ngIf="ac.event && isEnabled(ac.event, item)">
                <ng-container *ngIf="ac.actionComponent">

                    <!-- fixme santiago nuevo dato per app action -->
                    <app-action [instanceCustom]="" [action]="ac" [item]="item"
                        (event)="customEvent($event.name, $event.data)"></app-action>
                </ng-container>
                <ng-container *ngIf="!ac.actionComponent">
                    <button mat-icon-button *ngIf="isEnabled(ac.event, item)"
                        [matTooltip]="(ac.label || ac.event) | translate"
                        (click)="customEvent(ac.event, item);$event.stopPropagation()">
                        <mat-icon *ngIf="ac.icon; else label">{{ac.icon}}</mat-icon>
                        <ng-template #label>{{ac.label || '' | translate}}</ng-template>
                    </button>
                </ng-container>
            </ng-container>

        </ng-container>
    </mat-menu>
</ng-template>

<ng-template #defaultActions let-item="item" let-type="type" let-instanceValue="instanceValue"
    let-tableComponent="tableComponent">



    <ng-container *ngFor="let ac of getActions(type)">
        <ng-container *ngIf="ac.event && isEnabled(ac.event, item)">
            <ng-container *ngIf="ac.actionComponent">
                <!-- fixme santiago nuevo dato per app action -->
                <app-action [instanceCustom]="instanceValue" [action]="ac" [item]="item"
                    (event)="customEvent($event.name, $event.data)"></app-action>
            </ng-container>
            <ng-container *ngIf="!ac.actionComponent">
                <button mat-icon-button="" [matTooltip]="(ac.label || ac.event ) | translate"
                    (click)="$event.stopPropagation();customEvent(ac.event, item);">
                    <mat-icon *ngIf="ac.icon; else label">{{ac.icon}}</mat-icon>
                    <ng-template #label>{{ac.label || '' | translate}}</ng-template>
                </button>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>