import { Component, HostListener, OnDestroy } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Data, ResolveEnd, ResolveStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RouteConf } from './core/models/route-config';
import { selectLayoutTitle, LayoutActionType, State as StateLayout } from './store/layout/layout.reducers';
import { DynaConfActionType, State as StateShared } from './store/shared/shared.reducers';
import { BaseAction } from './store/store.actions';
import { DialogService } from './shared/services/dialog.service';
import { MatIconRegistry } from '@angular/material/icon';
import { back_to_tab, foward_2, pip } from './shared/models/entities';
import { ScrollComponentForComponent } from './shared/components/scroll-component-for/scroll-component-for.component';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends ScrollComponentForComponent implements OnDestroy {

  public nameProd = environment.name || undefined;

  // ir a set insert

  @HostListener('document:keydown.shift.control.z')
  doSomething() {
    if (environment.test || environment.dev) {
      if (!this.router.url.includes('set-insert')) {
  
        this.router.navigateByUrl('app/set-insert-out').then();
      } else {
  
        this.dialogService.openSnack('Sei nella URL richiesta.', 'info', 3000);
      }
    }
  }



  title = 'Gestionale15';
  constructor(public router: Router, private storeShared: Store<StateShared>, private storeLayout: Store<StateLayout>, private dialogService: DialogService,
    private titleService: Title, private translateService: TranslateService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,) {
      super();
      // icons
      iconRegistry.addSvgIconLiteral('back_to_tab', sanitizer.bypassSecurityTrustHtml(back_to_tab));
      iconRegistry.addSvgIconLiteral('pip', sanitizer.bypassSecurityTrustHtml(pip));
      iconRegistry.addSvgIconLiteral('foward_2', sanitizer.bypassSecurityTrustHtml(foward_2));

    storeLayout.select(selectLayoutTitle).pipe(
      debounceTime(environment.defaultDebounceTime),
      distinctUntilChanged(),
      switchMap((tit: string) => this.translateService.get(tit))
    ).subscribe((tit: string) => this.titleService.setTitle(tit));

    router.events.pipe(
      filter(e => e instanceof ResolveStart)
    ).subscribe((e: any) => {
      // this.store.dispatch(new BaseAction(LayoutActionType.Loading, true));
      this.storeShared.dispatch(new BaseAction(DynaConfActionType.ConfChange, null));
    });

    router.events.pipe(
      filter(e => e instanceof ResolveEnd)
    ).subscribe((e: any) => {
      const routeConf: RouteConf | null = this.findData(e.state.root);
      if (routeConf) {
        this.storeLayout.dispatch(new BaseAction(LayoutActionType.Title, `${routeConf.code}.title`));
      }

      this.storeShared.dispatch(new BaseAction(DynaConfActionType.ConfChange, routeConf));
    });
  }


  private findData(routeSnapshot: ActivatedRouteSnapshot): RouteConf | null {
    if (!routeSnapshot) return null;

    if (routeSnapshot.data && routeSnapshot.data['routeConf']) {
      return routeSnapshot.data['routeConf'];
    }



    for (let child of routeSnapshot.children) {
      return this.findData(child);
    }

    return null;

  }

  ngOnDestroy(): void {
    localStorage.clear();
  }
}
