import {DynacrudApiWrapper, DynaCrudService} from '../../core/api/dynacrud-api';
import {Observable, of} from 'rxjs';
import {DynacrudApi, MovementRequest, SearchRequest} from '../../core/models/dynacrud';
import {Type} from '@angular/core';
import {ContextService} from '../../core/services/context.service';
import {Entity} from '../models/entities';
import {filter, map, tap} from 'rxjs/operators';

export abstract class DynacrudWeakDataService<T extends Entity> implements DynaCrudService<T> {

    constructor(contextKey: string, type: Type<any>, dynacrudApiWrapper: DynacrudApiWrapper) {
        ContextService.add(contextKey + 'DynaCrudService', type);
        this.service = dynacrudApiWrapper.getDefaultImpl(this.getType());
    }

    private service: DynaCrudService<T>;

    //FIXME WeakMap da errore
    private cache: Map<string, DynacrudApi<any>> = new Map<string, DynacrudApi<any>>();

    public abstract  getType(): Type<T> | string;

    delete(entity: T): Observable<DynacrudApi<T>> {
        return this.service.delete(entity);
    }

    get(id: any): Observable<DynacrudApi<T>> {
        const key: string = 'get_' + id;
        if (this.cache.has(key)) {
            return of(this.cache.get(key) as DynacrudApi<any>);
        }
        return this.service.get(id).pipe(tap( ret => this.cache.set(key, ret) ));
    }

    save(entity: T): Observable<DynacrudApi<T>> {
        return this.service.save(entity);
    }

    search(searchRequest?: SearchRequest): Observable<DynacrudApi<T[]>> {
        const key: string = 'search_' + searchRequest ? JSON.stringify(searchRequest) : 'none';
        if (this.cache.has(key)) {
            return of(this.cache.get(key) as DynacrudApi<any> );
        }
        // non metto nella mappa le fulltext
        if ( !searchRequest?.filter?.text ) {
            return this.service.search(searchRequest).pipe(tap(ret => {
                    this.cache.set(key, ret);
                }
            ));
        }
        return this.service.search(searchRequest);
    }

    searchCustomer(searchRequest?: SearchRequest, movementRequest?: MovementRequest): Observable<DynacrudApi<T[]>> {
        // movementRequest
        const key: string = searchRequest && movementRequest && 'search_' + searchRequest ? JSON.stringify(searchRequest) : 'none';
        if (this.cache.has(key)) {
            return of(this.cache.get(key) as DynacrudApi<any> );
        }
        // non metto nella mappa le fulltext
        if ( !searchRequest?.filter?.text ) {
            return this.service.search(searchRequest).pipe(tap(ret => {
                    this.cache.set(key, ret);
                }
            ));
        }
        return this.service.search(searchRequest);
    }
}
