import { Component, OnInit } from '@angular/core';
import { DefaultColumnComponent } from '../default-column/default-column.component';

@Component({
  selector: 'app-default-check-column',
  templateUrl: './default-check-column.component.html',
  styleUrls: ['./default-check-column.component.scss']
})
export class DefaultCheckColumnComponent extends DefaultColumnComponent implements OnInit {

  enabled!: boolean;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    this.enabled = this.data.row.enabled != null ? this.data.row.enabled : null;
  }

}
