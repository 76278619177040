import { Injectable, Injector, ProviderToken } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  private static $values: {[key: string]: any} = {};
  private static $instance: ContextService;

  constructor(private injector: Injector) {
    ContextService.$instance = this;
  }

  public static add(name: string, service: any) {
    this.$values[name] = service;
  }
  
  private static get(name: string) {
    return this.$values[name];
  }

  public static instance(): ContextService {
    return this.$instance;
  }

  getService(name: string, typeName?: string ) {
    let fromMap = null;
    if ( typeName ) {
      fromMap = ContextService.get(name + typeName) ||  ContextService.get('default' +  typeName);
    }
    fromMap = fromMap || ContextService.get(name);
    if (fromMap) {
      return this.injector.get(fromMap);
    }
    return null;

  }
}
