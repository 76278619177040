import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WarehouseMovementDTO } from '../models/entities';
import {BehaviorSubject, Observable} from 'rxjs';
import { DynacrudApi } from 'src/app/core/models/dynacrud';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DialogEditService {


  private reloadTable = new BehaviorSubject(false);
  public readonly reloadTableRead = this.reloadTable.asObservable();

  public setReload(v: boolean) {
    this.reloadTable.next(v);
  }

  constructor(private http: HttpClient) { }


  public updateItemModal(idSession: number, movementId: number, body: WarehouseMovementDTO[] ): Observable<DynacrudApi<WarehouseMovementDTO>> {

    return this.http.patch<DynacrudApi<WarehouseMovementDTO>>( environment.apiUrl + '/v2/' + `sessions/${idSession}/movements/${movementId}`, body);

  }
}
