import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule as _StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers as storageMetaReducers,  reducers } from './store.reducers';
import { RouterStateSerializer } from '@ngrx/router-store';
import { CustomSerializer } from './router/custom-serializer';



@NgModule({
  declarations: [],
  imports: [
    _StoreModule.forRoot(reducers , {

      metaReducers: storageMetaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },

    }),
    EffectsModule.forRoot([]),
    CommonModule
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer }
  ]
})
export class StoreModule { }
