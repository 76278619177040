<ng-container *ngIf="workingMovement">

  <ng-container *ngIf="workingMovement && workingMovement.chain && workingMovement.chain.length > 0; else single_template">
      
          <ng-container *ngFor="let m of workingMovement.chain">
              <div class="not_current" [ngClass]="{'current': workingMovement.warehouseId == m.warehouseId}">
                  <div class="container_in_current">
                          {{m.description}}
                  </div>
              </div>
          </ng-container>
  </ng-container>

</ng-container>

<ng-template #single_template>
  <span style="padding: 0 2px;">{{workingMovement.chain[0].description}}</span>
</ng-template>