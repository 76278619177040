import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, throwError } from 'rxjs';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { TableEvent } from 'src/app/core/models/table-config';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { RowSelect, DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { SubSink } from 'subsink';
import { WarehouseSessionService } from '../../../services/warehouse-session.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { ActivatedRoute } from '@angular/router';
import { WarehouseSessionStatuses } from '../../../models/entities';

@Component({
  selector: 'app-delete-movement-action',
  templateUrl: './delete-movement-action.component.html',
  styleUrls: ['./delete-movement-action.component.scss']
})
export class DeleteMovementActionComponent implements OnInit, TableActionComponent, OnDestroy {

  subs = new SubSink();
  toRow: boolean = false;
  currSession: any;
  statusSession = WarehouseSessionStatuses

  constructor(private sessionCompleteService: WarehouseSessionService, private dialogSnack: DialogService, private store: Store<State>, private sessionService: SessionService, private route: ActivatedRoute) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.currSession = undefined;
  }
  data!: ActionData;
  event?: EventEmitter<TableEvent>;

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      const id = params['id'];

      const session = this.sessionService.sessionMov;

      if (id) {

        this.currSession = session;

      }


    });
  }

  deleteMovement(sessionId: number, movementId: number) {

    const dataRow: RowSelect = { row: this.data.item, select: this.toRow = !this.toRow }
    this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, dataRow));

    const options = {
      title: `dialog.deleted_movement.title`,
      message: `dialog.deleted_movement.message_delete`,
      cancelText: `dialog.deleted_movement.cancel`,
      confirmText: `dialog.deleted_movement.confirm`
    };
    this.dialogSnack.open(options);

    this.dialogSnack.confirmed().subscribe(confirmed => {
      if (confirmed) {

        this.subs.add(
          this.sessionCompleteService.deleteMovement(sessionId, movementId).pipe(
            catchError(err => {
              this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));
              return throwError(err);
            })
          ).subscribe(data => {
            this.dialogSnack.openSnack('dettaglo_sessione.notification.DeleteSessionMovement', 'delete');
            this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
            this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));

          })
        );

      }
    });

    this.dialogSnack.dialogRef.backdropClick().subscribe(data => {

      if (data) {
        this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));
      }
    });

    this.dialogSnack.cancel().subscribe(close => {

      this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));

    })

  }

}
