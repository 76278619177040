import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynacrudApi } from 'src/app/core/models/dynacrud';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor (private http: HttpClient) {}

  public EventCode(): Observable<DynacrudApi<any>> {
    return this.http.get<DynacrudApi<any>>(`${environment.apiUrl}/v2/event`);
  }


  public EventMKM(): Observable<DynacrudApi<any>> {
    return this.http.get<DynacrudApi<any>>(`${environment.apiUrl}/v2/mkmrequestcounter`);
  }
}
