import { SetInsertConfComponent } from './features/warehouse/components/bodies/set-insert-conf/set-insert-conf.component';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MENU_ITEMS, SIDENAV_CONFIG } from "./layout/models/data-sidenav";
import { StoreModule as StoreCustomModule } from './store/store.module';
import { MaterialModule } from './material/material/material.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { WindowRef } from './window';


registerLocaleData(localeIt);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    '.json'
  );
};


@NgModule({
  declarations: [
    AppComponent,
    SetInsertConfComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    NgxUiLoaderRouterModule.forRoot({showForeground: false}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreCustomModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    MaterialModule,
    AppRoutingModule,
    RouterModule.forRoot([]),
    EffectsModule.forRoot([]),

  ],
  providers: [
    {provide: SIDENAV_CONFIG, useValue: MENU_ITEMS},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'auto', appearance: 'outline'}},
    WindowRef,
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: ''},
    {
      provide: APP_INITIALIZER,
      useFactory: (translator: TranslateService) => function () {
        translator.addLangs(['it', 'en']);
        translator.setDefaultLang('it');
        translator.use('it');
        return true;
      },
      deps: [TranslateService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
