import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAuth, State } from 'src/app/store/auth/auth.reducers';
import { Auth } from '../models/auth';

@Injectable({
  providedIn: 'root'
})
export class CheckAuthService {
  private auth!: Auth;

  constructor(private store: Store<State>, private _router: Router) {
    this.store.pipe(select(selectAuth)).subscribe(auth => this.auth = auth);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(route);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean  {
    return this.check(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(childRoute);
  }

  private check(route: Route | ActivatedRouteSnapshot) {
    return new Observable<boolean>((observer) => {

      if (this.auth) {
        observer.next(this.checkAuth(this.auth, route.data && route.data['roles'] ? route.data['roles'] : null));
      } else {
        this.store.pipe(select(selectAuth)).subscribe(auth => {
          observer.next(this.checkAuth(auth, route.data && route.data['roles'] ? route.data['roles'] : null));
        });
      }
    });
  }



  private checkAuth(auth: Auth, roles?: string[]): boolean {
    this.auth = auth;
    let valid = false;
    if (!auth.isAuthenticated) {
      
      this._router.navigateByUrl('/auth');

      valid = false;
    } else if (roles) {

      valid = roles.findIndex(r => this.isEnabled(r)) !== -1;
    } else {
      valid = true;
    }

    return valid;
  }
  isEnabled(...roles: string[]) {
    
      if (this.auth && this.auth.isAuthenticated) {
        if ( roles && roles.length > 0) {
          return roles.every( needRole => this.auth.roles.findIndex( role => role == needRole) !== -1);
        }
        return false;
      }
     
      return false;
  }
}
