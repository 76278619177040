import { Component, Input, OnInit } from '@angular/core';
import { ColumnData, ColumnFieldComponent } from '../../Column';
import * as _ from 'lodash';

@Component({
  selector: 'app-default-column',
  templateUrl: './default-column.component.html',
  styleUrls: ['./default-column.component.scss']
})
export class DefaultColumnComponent implements ColumnFieldComponent, OnInit {

  @Input()
  data!: ColumnData;

  constructor() { }
  ngOnInit() { }

  getValue() {
    const row = this.data.row;
    const field = this.data.field.field;
    // string ricursivo
    let value: any = _.result(row, field, '' );
    if ( value && value.id != null && value.id != undefined ) {
      value  =  value.defaultDescription || value.name || value.description || value.code || value.id ;
    }
    return value;
  }

}
