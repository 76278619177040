import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HostColumnEditService {

  // for tableIcons

  private showIcons = new BehaviorSubject<boolean>(false);
  public readShowIcons = this.showIcons.asObservable();

  public actualizateMode (value: boolean) {
    this.showIcons.next(value);
  }

  /** */
  
  public _resetData = new BehaviorSubject<boolean>(false);
  public _resetStateData = this._resetData.asObservable();

  /** */

  /**ID SESSION */

  public _newIdSession = new BehaviorSubject<{id: number | undefined, code?: string | null}>({id: undefined, code: null});
  public _obIdSession = this._newIdSession.asObservable();


  private dataSource = new BehaviorSubject<any>(null);
  dataCol = this.dataSource.asObservable();

  
  private dataSourceInput = new BehaviorSubject<any>(null);
  dataColInput = this.dataSourceInput.asObservable();


  private selectRow = new BehaviorSubject<any>(null);
  obsSelectRow = this.selectRow.asObservable();

  private setAllRows = new BehaviorSubject<boolean | undefined>(undefined);
  setAllrowsQuantityObs = this.setAllRows.asObservable();

  hostSetAllQuantity (value: boolean) {
    // boolean value
    this.setAllRows.next(value);

  }


  updatedRow(select: any, all?: boolean){
    // bene :) invia anche il array vuoto
    this.selectRow.next(select);
  }
  

  updatedDataSelectionALL(data: any){
    this.dataSource.next(data);
  }

  updatedDataSelectionALLInput(data: any){
    this.dataSourceInput.next(data);
  }
}
