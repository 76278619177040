import { DefaultSearchHeaderComponent } from "src/app/core/components/default-search-header/default-search-header.component";
import { DeleteRowComponent } from "src/app/core/components/delete-row/delete-row.component";
import { DefaultDateColumnComponent } from "src/app/core/components/table/column-impl/default-date-column/default-date-column.component";
import { DefaultImageColumnComponent } from "src/app/core/components/table/column-impl/default-image-column/default-image-column.component";
import { RouteConf, DynamicListConf } from "src/app/core/models/route-config";
import { TableField, SelectionMode, TableActionType, ConfigTypeColumndField } from "src/app/core/models/table-config";
import { TitleComponent } from "src/app/layout/components/title/title.component";
import { TransferType } from "src/app/shared/models/entities";
import { StructureInfoColumnComponent } from "src/app/structure/components/table/structure-info-column/structure-info-column.component";
import { StructureInfoFirstFieldsColumnComponent } from "src/app/structure/components/table/structure-info-first-fields-column/structure-info-first-fields-column.component";
import { ActionCompleteMovementComponent } from "../components/actions/action-complete-movement/action-complete-movement.component";
import { AddMovementSessionActionAllComponent } from "../components/actions/add-movement-session-action-all/add-movement-session-action-all.component";
import { AddMovementSessionActionComponent } from "../components/actions/add-movement-session-action/add-movement-session-action.component";
import { CompleteSessionActionComponent } from "../components/actions/complete-session-action/complete-session-action.component";
import { DeleteMovementActionComponent } from "../components/actions/delete-movement-action/delete-movement-action.component";
import { EditSessionRecordComponent } from "../components/actions/edit-session-record/edit-session-record.component";
import { SessionStatusActionComponent } from "../components/actions/session-status-action/session-status-action.component";
import { AddSessionMovementComponent } from "../components/bodies/add-session-movement/add-session-movement.component";
import { DescriptionTooltipComponent } from "../components/columns/description-tooltip/description-tooltip.component";
import { MovementChainColumnComponent } from "../components/columns/movement-chain-column/movement-chain-column.component";
import { QuantityMovementChainComponent } from "../components/columns/quantity-movement-chain/quantity-movement-chain.component";
import { QuantitySessionMovementColumnComponent } from "../components/columns/quantity-session-movement-column/quantity-session-movement-column.component";
import { SelectWarehouseChangeTransColumnComponent } from "../components/columns/select-warehouse-change-trans-column/select-warehouse-change-trans-column.component";
import { WarehouseChangeColumnComponent } from "../components/columns/warehouse-change-column/warehouse-change-column.component";
import { SessionFilterComponent } from "../components/filters/session-filter/session-filter.component";
import { StockFilterComponent } from "../components/filters/stock-filter/stock-filter.component";
import { StockTemplateFiltersComponent } from "../components/filters/stock-template-filters/stock-template-filters.component";
import { TransferTypeFilterDerivatoStockComponent } from "../components/filters/transfer-type-filter-derivato-stock/transfer-type-filter-derivato-stock.component";
import { WorkingMovementsFiltersComponent } from "../components/filters/working-movements-filters/working-movements-filters.component";
import { WarehouseHeaderComponent } from "../components/layout/warehouse-header/warehouse-header.component";
import { MovementStatuses, WarehouseSessionStatuses } from "./entities";
import { EditMovementSessionButtonActionAllComponent } from "../components/actions/edit-movement-session-button-action-all/edit-movement-session-button-action-all.component";
import { EditMovementSessionButtonActionComponent } from "../components/actions/edit-movement-session-button-action/edit-movement-session-button-action.component";
import { SetInsertFilterComponent } from "../components/bodies/set-insert-filter/set-insert-filter.component";
import { SetInsertConfComponent } from "../components/bodies/set-insert-conf/set-insert-conf.component";
import {
  IconsStructureSingleComponent
} from "../../../shared/components/columns/icons-structure-single/icons-structure-single.component";
import { WrapMeStockFilterComponent } from "../components/filters/wrap-me-stock-filter/wrap-me-stock-filter.component";
import { OperationType } from "src/app/core/models/dynacrud";
import { WrappAllSessionsActionComponent } from "../components/actions/wrapp-all-sessions-action/wrapp-all-sessions-action.component";
import { WrappSessionActionComponent } from "../components/actions/wrapp-session-action/wrapp-session-action.component";
import { PurchasePriceSessionColumnComponent } from "../components/columns/purchase-price-session-column/purchase-price-session-column.component";
import { environment } from "src/environments/environment";
import { DeleteSessionComponent } from "../components/actions/delete-session/delete-session.component";
import { WrappHandlingActionComponent } from "../components/actions/wrapp-handling-action/wrapp-handling-action.component";
import { DetailSessionWrapperComponent } from "../components/bodies/detail-session-wrapper/detail-session-wrapper.component";

const LayoutWarehouse: RouteConf = {
  code: '',
  position: {
    header: {
      children: [{ component: WarehouseHeaderComponent }]
    }
  }
};


export const StockRoutConfig: RouteConf = {
  
  roles: ['warehouse'],
  code: 'stock',
  featureType: 'stock',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: null }]
    },
    // subheader: {
    //   children: [{ component: StockFilterComponent }]
    // }
    subheader: {
      children: [{ component: WrapMeStockFilterComponent }]
    }


  }
};


export const SessionRoutConfig: RouteConf = {
  ...DynamicListConf,
  roles: ['warehouse'],
  code: 'session',
  featureType: 'session',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: DefaultSearchHeaderComponent }]
    },
    subheader: {
      children: [{ component: SessionFilterComponent }]
    },
    AdSessionMovement: {
      children: [{ component: AddSessionMovementComponent }]
    }
  },
  attributes: {
    DefaultListComponent: {
      loadOnInit: false,
      displayedColumns: ['id', 'name', 'user', 'dateCreated', 'status', 'priority', 'motivation.description'],
      baseRoute: '/app/warehouse/session/',
      tableConfig: {
        columnsFields: [
          new TableField('id', undefined, 'id'),
          new TableField('name', undefined, 'Name'),
          new TableField('user', undefined, 'User'),
          new TableField('dateCreated', DefaultDateColumnComponent, 'dateCreated'),
          new TableField('status', undefined, 'Status'),
          new TableField('priority', undefined, 'Priority'),
          new TableField('motivation.description', undefined, 'Motivations')
        ],
        // selectionMode: environment.test ? SelectionMode.multipleWithBaseRoute : SelectionMode.single,
        selectionMode: SelectionMode.multipleWithBaseRoute,
        isRecordEnabled(event: any, item: any): boolean {

          if (event == 'delete' || event == 'status_handling') {
            if (item?.status?.id == WarehouseSessionStatuses.COMPLETED) {
              return false;
            }
          }

          // if (event == 'select_row') {
          //   if ( (item && ((item.hasNegativeQuantity) || ( item.stateId < 3 || ( !item.motivation?.name || item.wrapper ) )) || !environment.test ) ) {
          //     return false;
          //   }
          // }

          if (event == 'select_row') {
            if ((item && ((item.hasNegativeQuantity) || (item.stateId < 3 || (!item.motivation?.name || item.wrapper))))) {
              return false;
            }
          }

          // ! era solo per i test
          // if (event == 'addAll' && !environment.test) {
          //   return false
          // }

          return true;
        },
        actions: [
          { type: TableActionType.GLOBAL, icon: 'add', event: 'add' },
          { type: TableActionType.GLOBAL, event: 'addAll', actionComponent: WrappAllSessionsActionComponent },
          { type: TableActionType.RECORD, event: 'wrapp_handling', actionComponent: WrappHandlingActionComponent },
          { type: TableActionType.RECORD, event: 'addSingle', actionComponent: WrappSessionActionComponent },
          { type: TableActionType.RECORD, event: 'status_handling', actionComponent: SessionStatusActionComponent },
          { type: TableActionType.RECORD, event: 'delete', actionComponent: DeleteSessionComponent },
        ]
      }
    }
  }
};

// session id and no config global

export const SessionRoutConfigNoGlobal: RouteConf = {

  roles: [''],
  authenticated: true,

  code: 'santiSession',
  featureType: 'sessions',

  first: true,
  searchRequestDefault: {

    pagination: { page: 0, size: 20 },
    filter: {
      filterBy: {
        operation: OperationType.AND, filters: [
          { field: 'obsolete', operation: OperationType.EQ, value: false },
          { field: "stateId", operation: OperationType.GTE, value: 3 }
        ]
      }
    }

  },

  attributes: {
    DefaultListComponent: {


      loadOnInit: true,
      displayedColumns: ['id', 'name', 'user', 'dateCreated', 'status', 'priority'],
      baseRoute: '/app/warehouse/session/',
      tableConfig: {
        columnsFields: [
          new TableField('id', undefined, 'id'),
          new TableField('name', undefined, 'name'),
          new TableField('user', undefined, 'user'),
          new TableField('dateCreated', DefaultDateColumnComponent, 'dateCreated'),
          new TableField('status', undefined, 'status'),
          new TableField('priority', undefined, 'priority'),
        ],
        selectionMode: SelectionMode.single,
        isRecordEnabled(event: any, item: any): boolean {
          if (event == 'delete' || event == 'status_handling') {
            if (item?.status?.id == WarehouseSessionStatuses.COMPLETED) {
              return false;
            }
          }

          return true;
        },
        actions: [
          { type: TableActionType.GLOBAL, icon: 'add', event: 'add' },
          { type: TableActionType.GLOBAL, icon: 'reply_all', event: 'wrapAll' },
          { type: TableActionType.RECORD, event: 'status_handling', actionComponent: SessionStatusActionComponent },
          { type: TableActionType.RECORD, event: 'delete', actionComponent: DeleteRowComponent },
        ]
      }
    }
  }
};

export const WorkingMovementsRoutConfig: RouteConf = {
  ...DynamicListConf,
  roles: ['warehouse'],
  code: 'workingMovements',
  featureType: 'workingMovements',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: DefaultSearchHeaderComponent }]
    },
    subheader: {
      children: [{ component: WorkingMovementsFiltersComponent }]
    },
  },
  attributes: {
    DefaultListComponent: {
      loadOnInit: false,
      displayedColumns: ['warehouseId', 'product.brand.code', 'product.imageName', 'quantity',
        'product.name', 'product.rarity', 'product.expansion',
        'product.number', 'combination.language', 'combination.condition',
        'extra_info', 'comments', 'description', 'priority', /*'actionsColumn'*/],
      tableConfig: {
        columnsFields: [
          new TableField('warehouseId', MovementChainColumnComponent, 'Warehouse'),
          new TableField('product.imageName', DefaultImageColumnComponent, 'stock.image', undefined, false),
          new TableField('quantity', QuantityMovementChainComponent),
          new TableField('product.brand.code', undefined, 'Brand'),
          new TableField('product.name', undefined, 'stock.product.name'),
          new TableField(`product.rarity`, undefined, 'Rarity'),
          new TableField(`product.expansion`, undefined, 'Expansion'),
          new TableField('product.number', undefined, 'stock.product.number', undefined, true, 'product.numberOrder'),
          new TableField('combination.language', undefined, 'Language'),
          new TableField('combination.condition', undefined, 'Condition'),
          new TableField('extra_info', StructureInfoColumnComponent, 'stock.extra.info', undefined, false, undefined, { productPath: 'product', stockPath: 'combination', omitFields: ['rarity', 'expansion', 'number', 'language', 'condition'] }),
          new TableField('comments', undefined, 'Comments'),
          new TableField('description', DescriptionTooltipComponent, 'Description'),
          new TableField('priority', undefined, 'Priority'),
        ],
        actions: [
          { type: TableActionType.RECORD, event: 'complete', actionComponent: ActionCompleteMovementComponent },
        ],
        selectionMode: SelectionMode.multiple,
        isRecordEnabled(action: any, row: any): boolean {
          if (action === 'select') {
            return row && row.id;
          }

          return true;
        }
      }
    }
  }
};

export const StockTemplateInventory: RouteConf = {
  ...DynamicListConf,
  roles: ['inventory'],
  code: 'stock_template',
  featureType: 'stockInventoryTemplates',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: { // search stock-filter
      children: [{ component: DefaultSearchHeaderComponent }]
    },
    subheader: {
      children: [{ component: StockTemplateFiltersComponent }]
    }
  },
  attributes: {
    DefaultListComponent: {
      displayedColumns: ['id', /*'category', */'brand', 'description', /*'foil', 'firstEdition',*/ 'extra_info'],
      baseRoute: '/app/warehouse/stock_template/',
      tableConfig: {
        columnsFields: [
          new TableField('id'),
          new TableField('brand'),
          // new TableField('category'),
          new TableField('description'),
          // new TableField('condition'),
          // new TableField('foil'),
          // new TableField('firstEdition'),
          new TableField('extra_info', StructureInfoFirstFieldsColumnComponent, 'Extra info', undefined, false, undefined, {
            inventoryStockPath: ['foil', 'firstEdition', 'holo'],
            productPath: 'product',
            stockPath: 'combination',

            omitFields: ['rarity', 'expansion', 'number', 'language', 'condition', 'signed', 'stamped', 'inked', 'altered']
          }),
        ],
        actions: DynamicListConf?.attributes?.['DefaultListComponent'].tableConfig.actions || []
      }
    }
  }
};

export const SessionWrapperConfig: RouteConf = {
  ...DynamicListConf,
  roles: [''],
  code: 'sessions_wrappers',
  featureType: 'sessionWrappers',
  authenticated: true,

  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: DefaultSearchHeaderComponent }]
    }

  },

  attributes: {
    DefaultListComponent: {
      displayedColumns: ['description', 'instruction.name', 'motivation.description', 'totalPurchasePrice'],
      baseRoute: '/app/warehouse/sessionWrapper/',
      tableConfig: {
        columnsFields: [
          new TableField('description', undefined, 'Description',),
          new TableField('instruction.name', undefined, 'Formula'),
          new TableField('motivation.description', undefined, 'Motivation'),
          new TableField('totalPurchasePrice', undefined, 'Total')
        ].map(d => {
          if (d.id == 'instruction.name') {
            d.sortable = false;

          }
          d.field = '';
          return d
        }),
        actions: DynamicListConf?.attributes?.['DefaultListComponent'].tableConfig.actions || []
      }
    }
  },

}

export const SessionWrapperConfigWithIdAndAdd: RouteConf = {
  ...DynamicListConf,
  roles: [''],
  code: 'santiSession',
  featureType: 'session',
  authenticated: true,

  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }],

    },

  },
}

export const StockInTemplateInventory: RouteConf = {
  ...DynamicListConf,
  roles: ['inventory'],
  code: 'stock_template',
  featureType: 'stockInventoryTemplates',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: DefaultSearchHeaderComponent }]
    }
  },
};

export const NewSessionNotId: RouteConf = {
  ...DynamicListConf,
  roles: ['warehouse'],
  code: 'session',
  featureType: 'session',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: null }]
    },
    AdSessionMovement: {
      children: [{ component: AddSessionMovementComponent }]
    },
  }
};

export const SetInsertDetailConfig: RouteConf = {
  code: 'products_setInsert',
  featureType: 'products',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    subheader: {
      children: [{ component: SetInsertFilterComponent }]
    },

    filter: {
      children: [{ component: SetInsertFilterComponent }]
    },
    conf: {
      children: [{ component: SetInsertConfComponent }]
    }
  },
  attributes: {
    DefaultListComponent: {
      tableConfig: {
        actions: []
      }
    }
  }
};



export const SessionDetailoutConfig: RouteConf = {
  roles: ['session_detail'],
  code: 'session_detail',
  featureType: 'session_detail',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    subheader: {
      children: [{ component: null }]
    },
    title: {
      children: [{ component: TitleComponent }]
    },
    AdSessionMovement: {
      children: [{ component: AddSessionMovementComponent }]
    },
    search: {
      children: [{ component: DefaultSearchHeaderComponent }]
    },
  },

  // ['product.imageName', 'product.name', 'product.rarity', 'product.expansion',
  // 'product.number', 'combination.language', 'combination.condition', 'extra_info', 'comments', 'quantity', 'warehouse'],

  attributes: {
    DefaultListComponent: {
      displayedColumns: ['product.imageName', 'product.name', 'product.rarity', 'product.expansion',
        'product.number', 'combination.language', 'combination.condition', 'purchasePrice', 'extra_info', 'comments', 'quantity', 'warehouse'],

      tableConfig: {
        columnsFields: [
          new TableField('product.imageName', DefaultImageColumnComponent, 'stock.image', undefined, false),
          new TableField('product.name', undefined, 'stock.product.name'),
          new TableField(`product.rarity`, undefined, 'rarity'),
          new TableField(`product.expansion`, undefined, 'expansion'),
          new TableField('product.number', undefined, 'stock.product.number', undefined, true, 'product.numberOrder'),
          new TableField('combination.language', undefined, 'language'),
          new TableField('combination.condition', undefined, 'condition'),
          new TableField('purchasePrice', PurchasePriceSessionColumnComponent, 'Prezzo acquisto', undefined, false),
          new TableField('extra_info', StructureInfoColumnComponent, 'stock.extra.info', undefined, false, undefined, { productPath: 'product', stockPath: 'combination', omitFields: ['rarity', 'expansion', 'number', 'language', 'condition'] }),
          new TableField('comments'),
          new TableField('quantity'),
          new TableField('warehouse', WarehouseChangeColumnComponent, 'warehouse'),
        ],
        isRecordEnabled(event: any, item: any) {


          if (event === 'delete') {
            // metti costante
            if ((item?.status == WarehouseSessionStatuses.APPROVED) || item.warehouseSession.obsolete || (item?.warehouseSession?.status?.id == WarehouseSessionStatuses.COMPLETED)) {
              return false;
            }
          }

          if (event === 'completa') {
            if ((item.status != MovementStatuses.TEMPORARY) || (item.warehouseSession.status.id != WarehouseSessionStatuses.APPROVED)) {
              return false;
            }
          }

          // acceptRole: ['user']

          return true;
        },
        actions: [
          { type: TableActionType.RECORD, event: 'completa', actionComponent: CompleteSessionActionComponent },
          { type: TableActionType.RECORD, event: 'delete', actionComponent: DeleteMovementActionComponent },
          { type: TableActionType.RECORD, event: 'status_handling', actionComponent: SessionStatusActionComponent },
          { type: TableActionType.RECORD, event: 'edit_record', actionComponent: EditSessionRecordComponent }
        ],
      }
    }
  }
};


export const SessionDetailoutOnModalConfig: RouteConf = {
  roles: ['session_detail'],
  code: 'sessions',
  featureType: 'sessions',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    subheader: {
      children: [{ component: null }]
    },
    title: {
      children: [{ component: TitleComponent }]
    },
    AdSessionMovement: {
      children: [{ component: AddSessionMovementComponent }]
    },
    search: {
      children: [{ component: DefaultSearchHeaderComponent }]
    },
  },
  attributes: {
    DefaultListComponent: {
      displayedColumns: ['product.imageName', 'product.name', 'product.rarity', 'product.expansion',
        'product.number', 'combination.language', 'combination.condition', 'extra_info', 'comments', 'quantity', 'warehouse'],
      tableConfig: {

        columnsFields: [
          new TableField('product.imageName', DefaultImageColumnComponent, 'stock.image', undefined, false),
          new TableField('product.name', undefined, 'stock.product.name'),
          new TableField(`product.rarity`, undefined, 'rarity'),
          new TableField(`product.expansion`, undefined, 'expansion'),
          new TableField('product.number', undefined, 'stock.product.number', undefined, true, 'product.numberOrder'),
          new TableField('combination.language', undefined, 'language'),
          new TableField('combination.condition', undefined, 'condition'),
          new TableField('extra_info', StructureInfoColumnComponent, 'stock.extra.info', undefined, false, undefined, { productPath: 'product', stockPath: 'combination', omitFields: ['rarity', 'expansion', 'number', 'language', 'condition'] }),
          new TableField('comments'),
          new TableField('quantity'),
          new TableField('warehouse', WarehouseChangeColumnComponent, 'warehouse'),
        ],

        isRecordEnabled(event: any, item: any) {
          if (event === 'delete') {

            if (item?.status == WarehouseSessionStatuses.APPROVED || item.warehouseSession.obsolete || item?.warehouseSession?.status?.id == WarehouseSessionStatuses.COMPLETED) {
              return false;
            }
          }
          if (event === 'completa') {
            if (item?.status != WarehouseSessionStatuses.NOT_APPROVED) {
              return false;
            }
          }
          return true;
        },
        actions: [
          { type: TableActionType.RECORD, event: 'completa', actionComponent: CompleteSessionActionComponent },
          { type: TableActionType.RECORD, event: 'delete', actionComponent: DeleteMovementActionComponent },
          { type: TableActionType.RECORD, event: 'status_handling', actionComponent: SessionStatusActionComponent },
          { type: TableActionType.RECORD, event: 'edit_record', actionComponent: EditSessionRecordComponent }
        ]
      }
    }
  }
};

// TRANS
export const NewMovementNotIdTRANSRoutConfssig: RouteConf = {

  ...DynamicListConf,
  roles: ['warehouse'],
  code: TransferType.TRANS,
  featureType: 'stock',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: null }]
    },
    subheader: {
      children: [{ component: TransferTypeFilterDerivatoStockComponent }]
    },
  },

  // IconsStructureSingleComponent

  attributes: {
    DefaultListComponent: {

      loadOnInit: false,
      displayedColumns: ['product.imageName', 'product.name', 'product.rarity', 'product.expansion', 'product.number', 'combination.language',
        'combination.condition', 'extra_info', 'comments', 'actualPrice', 'avaible', 'insertQuantity', 'warehouse', 'warehouses'/*, 'action'*/],
      tableConfig: {

        columnsFields: [
          new TableField('product.imageName', DefaultImageColumnComponent, 'stock.image', undefined, false),
          new TableField('product.name', undefined, 'stock.product.name'),
          new TableField(`product.rarity`, undefined, 'rarity'),
          new TableField(`product.expansion`, undefined, 'expansion'),
          new TableField('product.number', undefined, 'stock.product.number', undefined, true, 'product.numberOrder'),
          new TableField('combination.language', IconsStructureSingleComponent, 'language'),
          new TableField('combination.condition', IconsStructureSingleComponent, 'condition'),
          new TableField('extra_info', StructureInfoColumnComponent, 'stock.extra.info', undefined, false, undefined, { productPath: 'product', stockPath: 'combination', omitFields: ['rarity', 'expansion', 'number', 'language', 'condition'] }),
          new TableField('avaible'),
          new TableField('comments'),
          new TableField('actualPrice', undefined, 'Price'),
          new TableField('insertQuantity', QuantitySessionMovementColumnComponent, 'stock.insertQuantity', 'fieldquantity', false, undefined, undefined, true, ConfigTypeColumndField.number),
          new TableField('warehouse', WarehouseChangeColumnComponent, 'warehouse'),
          new TableField('warehouses', SelectWarehouseChangeTransColumnComponent, 'warehouse', undefined, false, undefined, { source: 'warehouse' }, true, undefined),

        ],
        actions: [
          { type: TableActionType.RECORD, event: 'addMovement', actionComponent: AddMovementSessionActionComponent },
          // {type: TableActionType.GLOBAL, event: 'applyToAll', actionComponent: ApplySelectionMovementsAllComponent},
          { type: TableActionType.GLOBAL, event: 'addMovementAll', actionComponent: AddMovementSessionActionAllComponent },
        ],
        selectionMode: SelectionMode.multiple,
        isRecordEnabled(action: any, row: any): boolean {
          if (action === 'select') {
            return row && row.id;
          }

          return true;
        }
      }
    }
  }

};


// UNLOAD
export const NewMovementNotIdUNLOADRoutConfssig: RouteConf = {
  ...DynamicListConf,
  roles: ['warehouse'],
  code: TransferType.UNLOAD,
  featureType: 'stock',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: null }]
    },
    subheader: {
      children: [{ component: TransferTypeFilterDerivatoStockComponent }]
    },
  },
  attributes: {
    DefaultListComponent: {
      loadOnInit: false,
      displayedColumns: ['product.imageName', 'product.name', 'product.rarity', 'product.expansion', 'product.number', 'combination.language',
        'combination.condition', 'extra_info', 'comments', 'actualPrice', 'avaible', 'insertQuantity', 'warehouse', /*'warehouses'/*, 'action'*/],
      tableConfig: {
        columnsFields: [
          new TableField('product.imageName', DefaultImageColumnComponent, 'stock.image', undefined, false),
          new TableField('product.name', undefined, 'stock.product.name'),
          new TableField(`product.rarity`, undefined, 'rarity'),
          new TableField(`product.expansion`, undefined, 'expansion'),
          new TableField('product.number', undefined, 'stock.product.number', undefined, true, 'product.numberOrder'),
          new TableField('combination.language', undefined, 'language'),
          new TableField('combination.condition', undefined, 'condition'),
          new TableField('extra_info', StructureInfoColumnComponent, 'stock.extra.info', undefined, false, undefined, { productPath: 'product', stockPath: 'combination', omitFields: ['rarity', 'expansion', 'number', 'language', 'condition'] }),
          new TableField('actualPrice', undefined, 'Price'),
          new TableField('avaible'),
          new TableField('comments'),
          new TableField('insertQuantity', QuantitySessionMovementColumnComponent, 'stock.insertQuantity', 'fieldquantity', false, undefined, undefined, true, ConfigTypeColumndField.number),
          new TableField('warehouse', WarehouseChangeColumnComponent, 'warehouse'),

        ],
        actions: [
          { type: TableActionType.RECORD, event: 'addMovement', actionComponent: AddMovementSessionActionComponent },
          { type: TableActionType.GLOBAL, event: 'addMovementAll', actionComponent: AddMovementSessionActionAllComponent },
        ],
        selectionMode: SelectionMode.multiple,
        isRecordEnabled(action: any, row: any): boolean {
          if (action === 'select') {
            return row && row.id;
          }

          return true;
        }
      }
    }
  }

};

export const NewMovementNotIdRoutConfig: RouteConf = {
  ...DynamicListConf,
  roles: ['warehouse'],
  code: 'session',
  featureType: 'session',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: null }]
    },
    AdSessionMovement: {
      children: [{ component: AddSessionMovementComponent }]
    },
  }
};

export const NewMovementNotIdRoutConfigWithId: RouteConf = {
  ...DynamicListConf,
  roles: ['warehouse'],
  code: 'session',
  featureType: 'session',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: null }]
    },
    AdSessionMovement: {
      children: [{ component: AddSessionMovementComponent }]
    },
  }
};



// per tabella session editare

// TRANS
export const NewMovementNotIdTRANSRoutConfssigPerEDIT: RouteConf = {

  ...DynamicListConf,
  roles: ['warehouse'],
  code: TransferType.TRANS,
  featureType: 'stock',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: null }]
    },
    subheader: {
      children: [{ component: TransferTypeFilterDerivatoStockComponent }]
    },
  },

  attributes: {
    DefaultListComponent: {

      loadOnInit: false,
      displayedColumns: ['product.imageName', 'product.name', 'product.rarity', 'product.expansion', 'product.number', 'combination.language',
        'combination.condition', 'extra_info', 'comments', 'actualPrice', 'avaible', 'insertQuantity', 'warehouse', 'warehouses'/*, 'action'*/],
      tableConfig: {

        columnsFields: [
          new TableField('product.imageName', DefaultImageColumnComponent, 'stock.image', undefined, false),
          new TableField('product.name', undefined, 'stock.product.name'),
          new TableField(`product.rarity`, undefined, 'rarity'),
          new TableField(`product.expansion`, undefined, 'expansion'),
          new TableField('product.number', undefined, 'stock.product.number', undefined, true, 'product.numberOrder'),
          new TableField('combination.language', undefined, 'language'),
          new TableField('combination.condition', undefined, 'condition'),
          new TableField('extra_info', StructureInfoColumnComponent, 'stock.extra.info', undefined, false, undefined, { productPath: 'product', stockPath: 'combination', omitFields: ['rarity', 'expansion', 'number', 'language', 'condition'] }),
          new TableField('avaible'),
          new TableField('comments'),
          new TableField('actualPrice', undefined, 'Price'),
          new TableField('insertQuantity', QuantitySessionMovementColumnComponent, 'stock.insertQuantity', 'fieldquantity', false, undefined, undefined, true, ConfigTypeColumndField.number),
          new TableField('warehouse', WarehouseChangeColumnComponent, 'warehouse'),
          new TableField('warehouses', SelectWarehouseChangeTransColumnComponent, 'warehouse', undefined, false, undefined, { source: 'warehouse' }, true, undefined),

        ],
        actions: [
          { type: TableActionType.RECORD, event: 'addMovement', actionComponent: EditMovementSessionButtonActionComponent },
          // {type: TableActionType.GLOBAL, event: 'applyToAll', actionComponent: ApplySelectionMovementsAllComponent},
          { type: TableActionType.GLOBAL, event: 'addMovementAll', actionComponent: EditMovementSessionButtonActionAllComponent },
        ],
        selectionMode: SelectionMode.multiple,
        isRecordEnabled(action: any, row: any): boolean {
          if (action === 'select') {
            return row && row.id;
          }

          return true;
        }
      }
    }
  }

};


// UNLOAD
export const NewMovementNotIdUNLOADRoutConfssigPerEdit: RouteConf = {
  ...DynamicListConf,
  roles: ['warehouse'],
  code: TransferType.UNLOAD,
  featureType: 'stock',
  authenticated: true,
  position: {
    ...DynamicListConf.position,
    ...LayoutWarehouse.position,
    title: {
      children: [{ component: TitleComponent }]
    },
    search: {
      children: [{ component: null }]
    },
    subheader: {
      children: [{ component: TransferTypeFilterDerivatoStockComponent }]
    },
  },
  attributes: {
    DefaultListComponent: {
      loadOnInit: false,
      displayedColumns: ['product.imageName', 'product.name', 'product.rarity', 'product.expansion', 'product.number', 'combination.language',
        'combination.condition', 'extra_info', 'comments', 'actualPrice', 'avaible', 'insertQuantity', 'warehouse', /*'warehouses'/*, 'action'*/],
      tableConfig: {
        columnsFields: [
          new TableField('product.imageName', DefaultImageColumnComponent, 'stock.image', undefined, false),
          new TableField('product.name', undefined, 'stock.product.name'),
          new TableField(`product.rarity`, undefined, 'rarity'),
          new TableField(`product.expansion`, undefined, 'expansion'),
          new TableField('product.number', undefined, 'stock.product.number', undefined, true, 'product.numberOrder'),
          new TableField('combination.language', undefined, 'language'),
          new TableField('combination.condition', undefined, 'condition'),
          new TableField('extra_info', StructureInfoColumnComponent, 'stock.extra.info', undefined, false, undefined, { productPath: 'product', stockPath: 'combination', omitFields: ['rarity', 'expansion', 'number', 'language', 'condition'] }),
          new TableField('actualPrice', undefined, 'Price'),
          new TableField('avaible'),
          new TableField('comments'),
          new TableField('insertQuantity', QuantitySessionMovementColumnComponent, 'stock.insertQuantity', 'fieldquantity', false, undefined, undefined, true, ConfigTypeColumndField.number),
          new TableField('warehouse', WarehouseChangeColumnComponent, 'warehouse'),

        ],
        actions: [
          { type: TableActionType.RECORD, event: 'addMovement', actionComponent: EditMovementSessionButtonActionComponent },
          { type: TableActionType.GLOBAL, event: 'addMovementAll', actionComponent: EditMovementSessionButtonActionAllComponent },
        ],
        selectionMode: SelectionMode.multiple,
        isRecordEnabled(action: any, row: any): boolean {
          if (action === 'select') {
            return row && row.id;
          }

          return true;
        }
      }
    }
  }

};

