
<div class="input-group input-group-sm default_item__" >
  <div class="input__">
      <input min="0"
      (ngModelChange)=" valueObt(bindingSingle(data.field.id).item); "
      name="{{data.row?.id}}_column_{{data.row?.product?.enName}}"
      [value]="rowTrue"
      [required]="(qtyToTransf && (qtyToTransf > data.row.avaible)) || (qtyToTransf != undefined && qtyToTransf < 0) "
      [ngClass]="{ 'error' : qtyToTransf != undefined && (qtyToTransf > data.row.avaible || qtyToTransf < 0), 'noError': (qtyToTransf === 0) }"
      [(ngModel)]="bindingSingle(data.field.id).item" type="number" class="form-control item2__"
      aria-label="Small" aria-describedby="inputGroup-sizing-sm">
  </div>
  <div class="all_available">
      <button [disabled]="isElement" disableRipple="{{true}}" class="btn__" (click)="setQuantity($event)" mat-icon-button>
          <mat-icon [color]="setAvailable == true ? 'primary' : null" class="icon__">{{ setAvailable ? 'swipe_down' : 'swipe_up'}}</mat-icon>
      </button>
  </div>
</div>
