<ng-container *ngIf="sessionMovementChange.previousMovement || sessionMovementChange.nextMovement; else normalWarehouse">
  <ng-container *ngIf="sessionMovementChange.previousMovement;">
      <span [matTooltip]="sessionMovementChange.warehouse.name + '<--' +  sessionMovementChange.previousMovement.warehouse.name"
          class="changeSClass" *ngIf="sessionMovementChange.previousMovement">
          {{sessionMovementChange.warehouse.name}}
          <mat-icon color="primary" class="icon_change">arrow_back</mat-icon>
          {{sessionMovementChange.previousMovement.warehouse.name}}
      </span>

  </ng-container>

  <ng-container *ngIf="sessionMovementChange.nextMovement && sessionMovementChange.warehouse && sessionMovementChange.nextMovement">
      
      <span [matTooltip]="sessionMovementChange.warehouse.name + '-->' +  sessionMovementChange.nextMovement.warehouse.name"
          class="changeSClass" *ngIf="sessionMovementChange.nextMovement">
          {{sessionMovementChange.warehouse.name}}
          <mat-icon color="primary" class="icon_change">arrow_forward</mat-icon>
          {{sessionMovementChange.nextMovement.warehouse.name}}
      </span>
  </ng-container>
</ng-container>


<ng-template #normalWarehouse>
  <!-- matTooltip="{{sessionMovementChange.warehouse?.name}}" -->
  <span  class="changeSClass" *ngIf="sessionMovementChange.warehouse">
          {{sessionMovementChange.warehouse.name}}
      </span>
</ng-template>