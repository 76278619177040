<ng-container *ngIf="layout?.loading?.normal === true">

  <mat-progress-bar style="position: fixed; top: 0; z-index: 999999999; height: 4px; margin: 0; padding: 0;"
    color="primary" mode="query"></mat-progress-bar>
</ng-container>
<ng-container *ngIf="layout?.loading?.block === true">
  <ngx-ui-loader></ngx-ui-loader>
</ng-container>

<mat-drawer-container class="example-container_setInsert">
  <mat-drawer [disableClose]="!mobile" (keyup.esc)="noClose(); $event.preventDefault();  $event.stopPropagation();"
    class="main_sidenav" #sidenavEl (openedChange)="opened($event)" [mode]="layout.sidenav.mode || 'over'"
    [opened]="layout.sidenav.opened || false">

    <app-sidenav class="d-flex w-100 p-0 m-0 flex-column"></app-sidenav>
  </mat-drawer>
  <mat-drawer-content class="example-container_content">

    <app-layout-position position="header" class="header"></app-layout-position>

    <div class="d-flex flex-column w-100 h-100 align-items-center ">

      <div class="d-flex filters">
        <app-layout-position position="filter" class="filter_"></app-layout-position>
      </div>

      <div class="d-flex tableSet">
        <app-layout-position position="conf" class="conf"></app-layout-position>
      </div>

    </div>

  </mat-drawer-content>
</mat-drawer-container>