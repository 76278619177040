import {Type} from '@angular/core';

import {DefaultColumnComponent} from '../components/table/column-impl/default-column/default-column.component';
import {ColumnFieldComponent} from '../components/table/Column';
import {TableActionComponent} from '../components/table/Actions';

export enum SelectionMode {
  single = 'single',
  multiple = 'multiple',
  none= 'none',
  multipleWithBaseRoute = 'multiple_agile'
}


export interface TableConfig {
  version?: number;
  pageSize?: number;
  actions?: TableAction[];
  columnsFields?: TableField[];
  selectionMode?: SelectionMode;
  paginatorMode?: string;
  isRecordEnabled?: Function;
  rowDialog?: boolean; //questo serve per cliccare apre modale
}

export class TableAction {
  event?: string;
  icon?: string;
  label?: string;
  type?: string;
  position?: string;
  acceptRole?: string[];
  actionComponent?: Type<TableActionComponent>;
}


export const TableActionType = {
  RECORD: 'RECORD',
  GLOBAL: 'GLOBAL'
};

export interface TableEvent {
  name: string | undefined;
  data: any;
}

export enum ConfigTypeColumndField {
  number = 'number',
  text = 'text',
  email = 'email',
  tel = 'tel',
}


export class TableField {

  constructor(
    private $id: string,
    private $type?: Type<ColumnFieldComponent>,
    private $label?: string,
    private $field?: string,
    private $sortable = true,
    private $sortExpression?: string,
    public  attributes?: {[key: string]: any},
    private $editable = false,
    private $typeField?: ConfigTypeColumndField | string | any ) {

  }

  public get typeField(): string | Type<any> {
    return this.$typeField != null ? this.$typeField :  null;
  }

  public set typeField(typeField: string | Type<any>) {
    this.$typeField = typeField;
  }




  public get sortable(): boolean {
    return this.$sortable != null ? this.$sortable :  true;
  }

  public set sortable(sortable: boolean) {
    this.$sortable = sortable;
  }

  public get editable(): boolean {
    return this.$editable != null ? this.$editable :  true;
  }

  public set editable(editable: boolean) {
    this.$editable = editable;
  }

  public get sortExpression(): string {
    return this.$sortExpression || this.field;
  }


  public get id() {
    return this.$id;
  }

  public get label() {
    return this.$label || this.id;
  }

  public set label(label: string) {
    this.$label = label;
  }

  public get field() {
    return this.$field || this.id;
  }

  public set field(field: string) {
    this.$field = field;
  }

  public get type(): Type<ColumnFieldComponent> {
    return this.$type || DefaultColumnComponent;
  }

  public set type(type: Type<ColumnFieldComponent>) {
    this.$type = type;
  }
}

