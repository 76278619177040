<ng-container>
    <div class="filter_container">
        <ng-container>
            <form [formGroup]="filterFormGroup" class="containers-fields-filter" *ngIf="filterFormGroup" (keyup.enter)="search($event); $event.stopPropagation();">
                <div class="row_1">
                    <!--per quick fix-->
                    <mat-card class="filter_column">
                        <mat-card-subtitle class="format">
                            {{'filters.warehouseMovements.title-card' | translate}}
                        </mat-card-subtitle>
                        <div class="container_multiples">

                            <div class="container_select">
                                <mat-form-field class="field_generic">
                                    <mat-label>{{'filters.warehouseMovements.sessionsOrders' | translate}}</mat-label>
                                    <mat-select formControlName="sessionOrder" name="sessionOrder">
                                        <mat-option *ngFor="let sessionOrderItem of sessionOrder"
                                            [value]="sessionOrderItem">{{sessionOrderItem.name |
                                            translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="container_select">
                                <app-default-select name="warehouse" formControlName="warehouse"
                                    label="Select Warehouse" [multiple]="true" source="warehouse"
                                    [filterBy]="warehouseFilter"></app-default-select>
                            </div>
                            <div class="container_select">
                                <app-default-select name="brand" formControlName="brand" label="Select Brand"
                                    [multiple]="true" source="brand" [filterBy]="brandFilter"></app-default-select>
                            </div>
                            <div class="container_select">
                                <app-default-select name="category" [filterBy]="categoryFilter"
                                    formControlName="category" label="Select Category" [multiple]="true"
                                    source="category"></app-default-select>
                            </div>
                            <div class="container_select">
                                <app-default-select name="warehouseFrom" matTooltip="Select Warehouse From" formControlName="warehouseFrom"
                                    label="Select Warehouse From" [multiple]="true" source="warehouse"
                                    [filterBy]="warehouseFilter"></app-default-select>
                            </div>
                            <div class="container_select">
                                <app-default-select name="warehouseTo" formControlName="warehouseTo"
                                    label="Select Warehouse To" [multiple]="true" source="warehouse"
                                    [filterBy]="warehouseFilter"></app-default-select>
                            </div>
                            <div class="container_select">
                                <mat-form-field class="select_default">
                                    <mat-label>Presale</mat-label>
                                    <mat-select formControlName="presale">
                                        <mat-option
                                            *ngFor="let item of [{value: true, label: 'Yes'}, {value: false, label: 'No'}, {value: null, label: 'All'}]"
                                            [value]="item.value">
                                            {{item.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>

                            <div class="container_input">
                                <mat-form-field class="input_default">

                                    <mat-label>{{ 'default.customerSearch' | translate }}</mat-label>

                                    <input class="search_label_general" maxlength="60"
                                        #productsAutocompleteWorkTrigger="matAutocompleteTrigger" type="text" matInput
                                        #productSuggestInputWork [matAutocomplete]="productsWorkingAutocomplete"
                                        [placeholder]="'default.customerSearch' | translate">

                                        <mat-spinner *ngIf="loading" matSuffix class="spn_ext"></mat-spinner>
                                        

                                    <mat-autocomplete class="panelHover_Working" #productsWorkingAutocomplete="matAutocomplete" (optionSelected)="selectProduct( $event.option.value );">
                                        <mat-option *ngFor="let customer of customerObs$ | async" [value]="customer">
                                            {{ customer.name + ' ' + (customer?.surname || '') }}
                                        </mat-option>
                                    </mat-autocomplete>

                                   

                                </mat-form-field>

                                <button *ngIf="value?.customer?.id" color="warn" (click)="clearCustomer($event)" id="id_Delete" mat-icon-button >
                                    <mat-icon>
                                        delete
                                    </mat-icon>
                                </button>

                            </div>

                        </div>
                        <div class="container-other-buttons">
                            <div class="complete-all-movements">
                                <app-complete-all-movements></app-complete-all-movements>
                            </div>
                            <div class="filters-buttons">
                                <button mat-raised-button class="btn_cs" color="primary"
                                    (click)="search($event)">{{'filters.warehouseMovements.buttonSearch' |
                                    translate}}</button>
                                <button mat-raised-button class="btn_cs_delete" color="warn"
                                    (click)="reset()">{{'filters.warehouseMovements.resetFilters' | translate}}</button>
                            </div>
                        </div>
                    </mat-card>
                </div>

            </form>
        </ng-container>
    </div>
</ng-container>