import {Injectable, Type} from '@angular/core';
import {DynacrudWeakDataService} from './DynacrudWeakDataService';
import {DynacrudApiWrapper} from '../../core/api/dynacrud-api';
import { Condition } from '../models/entities';

@Injectable({
  providedIn: 'root'
})
export class ConditionService extends DynacrudWeakDataService<Condition> {

  constructor(dynacrudApiWrapper: DynacrudApiWrapper) {
    super('conditions', ConditionService, dynacrudApiWrapper);
  }

  getType(): Type<Condition> | string {
    return 'condition';
  }
}
