import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { TableField } from 'src/app/core/models/table-config';
import { ColumnFieldComponent } from '../Column';

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss']
})
export class ColumnComponent implements OnInit {

  @Input()
  field!: TableField;

  @Input()
  row: any;

  @Input()
  instanceCustom: any;

  constructor(private viewContainerRefHost: ViewContainerRef) { }

  ngOnInit() {
    // now i dont need componentFactory --> https://stackoverflow.com/questions/70946038/replace-deprecated-angular-componentfactoryresolver-componentfactory
    const component = this.viewContainerRefHost.createComponent(this.field.type);
    (component.instance as ColumnFieldComponent).data = { field: this.field, row: this.row /*, instanceValue: this.instanceCustom ? this.instanceCustom : null */ };
  }

}
