import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) {}

  default(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'default-notification-overlay'
    });
  }

  info(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'info-notification-overlay'
    });
  }

  success(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'success-notification-overlay'
    });
  }

  warn(message: string) {
    this.show(message, {
      duration: 2500,
      panelClass: 'warning-notification-overlay'
    });
  }

  error(message: string) {
    this.show(message, {
      // duration: 3600000,
      panelClass: 'error-notification-overlay'
    });
  }

  dismiss() {
    this.snackBar.dismiss();
  }


  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run( () => {
      this.snackBar.open(message, undefined, configuration);
      const snackEl = document.getElementsByClassName('mat-mdc-snack-bar-container error-notification-overlay', );
      snackEl.item(0)?.addEventListener('click', () => { this.dismiss(); });
      snackEl.item(1)?.addEventListener('click', () => { this.dismiss(); });
      snackEl.item(2)?.addEventListener('click', () => { this.dismiss(); });
      snackEl.item(3)?.addEventListener('click', () => { this.dismiss(); });
      snackEl.item(4)?.addEventListener('click', () => { this.dismiss(); });
      snackEl.item(5)?.addEventListener('click', () => { this.dismiss(); });
    });
  }
}
