<button mat-button [matMenuTriggerFor]="menu" id="user-dropdown-toggler">
  <mat-icon>account_circle</mat-icon>
  <span class="username">
    {{username}}
  </span>
</button>
<mat-menu #menu="matMenu" xPosition="before" class="menu">
  <small class="usernameMenu" style="color:rgb(209, 209, 209);">{{username}}</small>
  <button mat-menu-item disabled> Refresh</button>
  <button mat-menu-item disabled> Profilo</button>
  <button mat-menu-item (click)="logout()"> Logout</button>
</mat-menu>
<hr style="border-color: transparent;">
<div style="font-style: italic; font-size: 13px;"
  class="d-flex mr-1 ml-1 justify-content-between mat-elevation-z1 color-eventi">

  <div class="d-flex flex-column">

    <span class="ml-2">Eventi in coda : {{eventCoda}}</span>
    <span class="ml-2">Chiamata mkm : {{chiamataMkm}}</span>
  </div>

  <div class="d-flex align-items-center ">

    <ng-container *ngIf="atLeastOne">
      <div class="d-flex justify-content-end " >
        <div class="d-flex expand_cl" (click)="collapseAll(); atLeastOne = false; $event.preventDefault(); ">
          <mat-icon class="expand_cl_icon"   matTooltip="{{'sidenav.chiudiAll' | translate}}" >unfold_less</mat-icon>

        </div>
      </div>

    </ng-container>
  </div>


</div>

  <!--Clear expanded da lo routing??-->


<mat-tree class="tree_" [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node [ngClass]="{'showTree': true}" #nodeEl *matTreeNodeDef="let node">
    <button mat-icon-button disabled></button>
    <div class="btn-mirror_2">
      <button class="icon-route" (click)="go(node)" disableRipple="true" mat-button>
        <span [ngClass]="{'node_selected': selectedRoute == node?.data?.route && node?.data?.selectable }"
          >{{node.name}}</span>

      </button>
    </div>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let parent; when: hasChild" matTreeNodePadding>
    <div class="btn-mirror_">
      <button (click)="toogle(parent)" mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + parent.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(parent) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
    </div>

    <div class="btn-mirror_2">
      <button class="icon-route" mat-button (click)="go(parent)">
        <span [ngClass]="{'node_selected': selectedRoute == parent.data.route && parent?.data?.selectable }"
          >{{parent.name}}</span>

        <!--ng-container *ngIf="validNotify(parent, selectedRoute )"-->
        <ng-container *ngIf="existNotify[parent.name]" >
          <span
            class="position-absolute noty_custom start-100 translate-middle bg-danger border border-light rounded-circle">
            <span class="visually-hidden">New alerts</span>
          </span>

        </ng-container>
      </button>
    </div>
  </mat-tree-node>




</mat-tree>





<!--[matTooltip]="defaultTesto" matTooltipClass="mytool_tip"-->

<div class="d-flex justify-content-end p-1 scm_ popover-hover_ "  data-bs-toggle="popover" data-bs-content="Contenido del popover" data-bs-title="Informazione aiuto">
  <mat-icon>
    help
  </mat-icon>
</div>




<!--span class="popover-hover d-flex justify-content-end p-1 scm_ " data-bs-toggle="popover" data-bs-content="Contenido del popover" data-bs-title="Título del popover">
  Pasa el mouse aquí
</span-->
