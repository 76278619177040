
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DynacrudApiWrapper } from 'src/app/core/api/dynacrud-api';
import { DefaultListComponent } from 'src/app/core/components/default-list/default-list.component';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { WarehouseChangeMovement } from 'src/app/shared/models/entities';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HostColumnEditService } from 'src/app/shared/services/host-column-edit.service';
import { State } from 'src/app/store/shared/shared.reducers';
import { SubSink } from 'subsink';
import { TypeModalSessionProduct, WarehouseSessionStatuses } from '../../../models/entities';
import { DialogEditRowTableComponent } from '../../modals/dialog-edit-row-table/dialog-edit-row-table.component';
import { SessionService } from 'src/app/shared/services/session.service';

export const IDMODALSESSION = 'editSw_';


@Component({
  selector: 'app-edit-session-record',
  templateUrl: './edit-session-record.component.html',
  styleUrls: ['./edit-session-record.component.scss']
})
export class EditSessionRecordComponent implements OnInit, OnDestroy, TableActionComponent {

  @Input()
  data!: ActionData;

  modelEdit: any = {};




  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router, private sessionService: SessionService,
    private dynacrudApiService: DynacrudApiWrapper, private store: Store<State>, private dialogService: DialogService, private hostColumnService: HostColumnEditService) { }

  // private defaultListComponent = new DefaultListComponent(this.router, this.activatedRoute, this.dynacrudApiService, this.store, this.dialogService, this.hostColumnService  );

  private subs = new SubSink();

  private idSession = '';

  statusSession = WarehouseSessionStatuses

  currSession: any;

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.currSession = undefined;
  }


  ngOnInit(): void {
    this.subs.add(this.activatedRoute.params.subscribe(d => {
      this.idSession = d['id'];

      const session = this.sessionService.sessionMov;
      if (this.idSession) {
        this.currSession = session;

      }

    }));

  }



  typeModal = TypeModalSessionProduct;
  typeModalValue = '';


  goEdit() {

    const valueSessionItem: WarehouseChangeMovement = this.data.item;


    // transfer
    if (valueSessionItem && ((valueSessionItem.previousMovement != undefined) || (valueSessionItem.nextMovement != undefined))) {
     
      this.dialog.open(DialogEditRowTableComponent, {



        data: { item: this.data.item, idSession: this.idSession, featureType: this.typeModal.TRANSFER },
        height: '70%',
        width: '100%',
        maxHeight: '99%',
        maxWidth: '99%',
      
        id: IDMODALSESSION,
       
      });

    } else if (valueSessionItem && !(valueSessionItem.previousMovement) && !(valueSessionItem.nextMovement)) {

     

      if (valueSessionItem.quantity > 0) {

        // ''

        this.dialog.open(DialogEditRowTableComponent, {
          data: { item: this.data.item, idSession: this.idSession, featureType: this.typeModal.LOAD },
          height: '70%',
          width: '100%',
          maxHeight: '99%',
          maxWidth: '99%',
          disableClose: true
          // id: IDMODALSESSION
        });

      } else if (valueSessionItem.quantity < 0) {

        // ''

        this.dialog.open(DialogEditRowTableComponent, {
          data: { item: this.data.item, idSession: this.idSession, featureType: this.typeModal.UNLOAD },
          height: '70%',
          width: '100%',
          maxHeight: '99%',
          maxWidth: '99%',
          id: IDMODALSESSION
        });

      }

    }
  }

}
