import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionMode, TableAction, TableActionType, TableConfig, TableEvent, TableField } from 'src/app/core/models/table-config';
import { RowSelect, selectCurrentRow, selectFeatureCode, State } from 'src/app/store/shared/shared.reducers';
import { debounceTime, distinctUntilChanged, map, merge, Observable, Subject, take } from 'rxjs';
import { HostColumnEditService } from 'src/app/shared/services/host-column-edit.service';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort, SortDirection } from '../../../models/dynacrud';
import { selectAuth } from 'src/app/store/auth/auth.reducers';
import { Auth } from 'src/app/auth/models/auth';
import { isFunction, isObject } from 'lodash';
import { select, Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { DialogRowComponent } from '../dialogs/dialog-row/dialog-row.component';
import { strategySelect } from 'src/app/features/warehouse/components/bodies/all-sessions-into-wrap/all-sessions-into-wrap.component';
import { Motivation } from 'src/app/features/warehouse/models/entities';


export type Roles = Pick<Auth, "roles">;


// https://stackblitz.com/angular/eglxlnboper?file=src%2Fapp%2Fad-banner.component.ts

export interface TableStatus {
  page: PageEvent;
  sort: Sort[];
}

export interface TableData {
  data: [];
  // potremmo avere già search ecc...
  tableStatus: TableStatus;
}

interface SortItem {
  field: TableField;
  position: number;
  direction: SortDirection;
}

interface SortStatus {
  [id: string]: SortItem;
}
export interface TableSelection {
  all?: boolean;
  rows: any[];
  code?: string;
  ctrlKey?: boolean;
}

export enum customPaginatiorMode {
  customSelectPage = 'CUSTOMSELECTPAGE'
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  featureCode: string | undefined = '';
  statusPer!: RowSelect;

  customPaginatiorModev = customPaginatiorMode;

  modeIcon = false;
  setAvailable = false;
  private static largeViewportWidth = 992;
  private $displayedColumns!: string[] | undefined;
  selection: TableSelection = { all: false, rows: [] };
  tableData: TableData = { data: [], tableStatus: { page: { pageIndex: 0, pageSize: 0, length: 0 }, sort: [] } };
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  selectMode = SelectionMode.single;
  whHost: any[] = [];

  private subs = new SubSink();

  sortStatus: { [id: string]: SortItem } = {};
  private sortSubject: Subject<SortStatus> = new Subject<SortStatus>();
  readonly sort: Observable<SortStatus> = this.sortSubject.asObservable();

  typeObjectId: { [key: string]: any } = {};

  private selectionStatus: { [prop: string]: { row: any, selected: boolean | undefined, not?: boolean, ctrlKey?: boolean } } = {};

  private actions: { [key: string]: TableAction[] } = {};

  @Output()
  rowSelect = new EventEmitter<TableSelection>();

  @Output()
  status = new EventEmitter<TableStatus>(true);

  @Output()
  custom = new EventEmitter<TableEvent>();

  @Input()
  dataSource!: Observable<TableData>;

  @Input()
  notInputPage!: boolean;


  @Input()
  getRowTable: boolean | undefined;


  @Input()
  modelPaginatior: customPaginatiorMode | undefined;



  @Input() strategySelect: strategySelect | undefined;


  @Input()
  config!: TableConfig | undefined;

  definitionValid: string | undefined;

  constructor(public store: Store<State>, private hostColumnEditService: HostColumnEditService, private dialogService: DialogService, public dialog: MatDialog) {

  }

  modifyModeIcon() {
    const v = this.modeIcon = !this.modeIcon;
    this.hostColumnEditService.actualizateMode(v);
  }

  disableForSession(): boolean {


    if (this.featureCode == 'session') {
      const miArray: any[] = _.cloneDeep(this.tableData.data || []);

      if (miArray && miArray.length > 0) {

        const valorV = miArray.find(d => d && d.isSelect);

        let todosIguales = false;
        if (valorV && valorV?.motivation ) {
          const valorComparacion = valorV?.motivation?.name;

          if (valorComparacion != undefined) {
            todosIguales = false;
          }


        } else if(miArray && miArray.length > 0 && miArray.find(own => own && own?.motivation)?.motivation )  {
          const withMotivation: Motivation = miArray.find(own => own && own.motivation).motivation;
          const valorComparacion = withMotivation.name;

          for (const objeto of miArray) {
            if (objeto.motivation && objeto.motivation.name !== valorComparacion) {
              // Si encuentras un valor diferente, establece la variable "todosIguales" en false y rompe el bucle
              todosIguales = true;
              break;
            }
          }

        }

        if (miArray.every(e => !e.motivation)) {
          todosIguales = true;
        }

        return todosIguales;
      }
    }

    return false;

  }

  get mediumScreenAndDown(): boolean {
    return window !== undefined ? window.matchMedia(`(max-width: ${TableComponent.largeViewportWidth}px)`).matches : false;
  }

  @Input()
  set displayedColumns(displayedColumns: string[] | undefined) {
    this.$displayedColumns = displayedColumns;
  }
  get displayedColumns(): string[] | undefined {
    return this.$displayedColumns || this.config?.columnsFields?.map(value => value.id);
  }

  set selectAll(val: boolean | undefined) {
    if (this.config?.selectionMode === SelectionMode.multiple || (this.config?.selectionMode === SelectionMode.multipleWithBaseRoute)) {
      this.selection.all = val;


      let sum = 0;
      let sumaVerdaderaIn = 0;
      if (this.strategySelect && this.strategySelect.value) {

        this.tableData.data.forEach((item: any) => {
          if (this.isEnabled('select_row', item)) {

            sum += item.fixedPrice;
            
            if ((this.strategySelect && (item.fixedPrice <= this.strategySelect.value) && (sum <= this.strategySelect.value)) ||
            item.fixedPrice != undefined && item.fixedPrice === 0) {
               
              this.selectionStatus[this.getRowId(item)] = { selected: this.selection.all, row: item, not: true };
              sumaVerdaderaIn += item.fixedPrice;

            } else {
              if (this.strategySelect && (sum > this.strategySelect.value) ) {
                sum = sumaVerdaderaIn;
              }
            }

          }
        });

      } else {
        this.tableData.data.forEach(item => {
          if (this.isEnabled('select_row', item)) {

            this.selectionStatus[this.getRowId(item)] = { selected: this.selection.all, row: item, not: true };
          }
        });

      }



      const selections: any[] = [];
      Object.keys(this.selectionStatus).forEach(k => {
        if (this.selectionStatus[k]?.selected) {
          this.selectionStatus[k].row.isSelect = true;
          selections.push(this.selectionStatus[k]);
        } else {
          this.selectionStatus[k].row.isSelect = false;
          // selections.push(this.selectionStatus[k]);
        }
      });

      this.selection.rows = [...selections];

      // fixme santiago rows all selecttions
      if (val == true) {
        this.hostColumnEditService.updatedRow(selections);
      }


      // qua viene la logica





      this.rowSelect.emit({ ...this.selection });



    }
  }

  get selectAll(): boolean | undefined {
    return this.selection.all;
  }

  private roles: Roles['roles'] = [];

  @ViewChild('inputSingle_') inputSingle_: any;

  ngOnInit(): void {

    this.subs.add(
      this.store.pipe(select(selectAuth)).subscribe(auth => {
        this.roles = auth && auth.roles && auth.roles.length > 0 ? [...auth.roles] : [];
      })
    );


    this.subs.add(
      this.store.pipe(select(selectFeatureCode), debounceTime(50), distinctUntilChanged()).subscribe(featureCode => {
        this.featureCode = featureCode || undefined;

      })
    );
    this.init();
    this.setAvailable = false;
    this.hostColumnEditService.hostSetAllQuantity(false); // arriva il false al pulsante header in memoria
    this.subs.add(this.dataSource.subscribe(ds => {

      if ((this.featureCode == 'TRANS' || this.featureCode == 'OUT' || this.featureCode == 'session_detail')   /** && code == transferType */) {
        this.hostColumnEditService._resetStateData.pipe(distinctUntilChanged()).subscribe(des => {

          if (des) {
            this.clearValuesInputs();
          }
        })
      }

      if (this.getRowTable) {
        let cont = 0;

        ds.data = ds.data.map((d: any) => Object.assign(d, { ...d, idRowTable: cont += 1 })) as [];

      }

      this.sortStatus = {};
      this.clearSelection();
      ds.tableStatus.sort = ds.tableStatus.sort || [];
      this.tableData = ds;

      // fixme IMPORTANTE santiago questo solo in featureCode == 'transferType'
      this.whHost = (ds.data as []);
      this.pageIndex = parseInt(this.tableData.tableStatus.page.pageIndex.toString()) + 1;


      ds.tableStatus.sort.forEach((s: Sort, index: number) => {
        const tableField = this.config?.columnsFields?.find(f => f.sortExpression === s.field);
        if (tableField) {
          this.sortStatus[tableField.id] = {
            field: tableField,
            direction: SortDirection[s.direction.toUpperCase() as SortDirection],
            position: index
          };
        }
      });

      this.subs.add(
        this.store.pipe(select(selectCurrentRow), distinctUntilChanged(), debounceTime(environment.defaultDebounceTime), take(1)).subscribe(data => {
          this.selectRowEvident(data);
        })
      );
    }));
  }

  ngAfterViewInit() {

    this.subs.add(
      merge(
        this.sort,
        this.paginator?.page.pipe(debounceTime(environment.defaultDebounceTime), distinctUntilChanged())
      ).pipe(
        map(() => {
          const tableStatus: TableStatus = this.tableData.tableStatus;
          // FIXME K3 potremmo intercettare il cambio di dati e lasciare la selezione per le sole righe visibili
          this.selectionStatus = {};

          this.selection = { all: false, rows: [] };

          this.rowSelect.emit(this.selection);

          if (this.paginator && this.config) {
            this.config.pageSize = this.paginator.pageSize;
            tableStatus.page = {
              pageIndex: this.paginator.pageIndex,
              pageSize: this.paginator.pageSize,
              length: this.paginator.length
            };
          }

          tableStatus.sort = _.sortBy(Object.values(this.sortStatus), 'position').map((sortItem: SortItem) => {
            return { field: sortItem.field.sortExpression, direction: sortItem.direction };
          });


          return tableStatus;
        })
      ).subscribe(tableStatus => {
        this.status.emit(tableStatus);
      })
    );
  }

  ngOnDestroy() {
    this.clearDataTableInputs();
    this.subs.unsubscribe();
  }

  sumValue: { [k: string]: any } = {};
  sumOwn = 0;
  private takeProp = true;

  notPosible: { [k: string]: any } = {};

  fixtureSum = 0;

  isEnabled(name: string | undefined, data?: any): any {

    if (data) {
      this.notPosible[data.id];
    }
    // get name of event
    const nameOfEvent = this.config?.actions?.find(d => name?.toLowerCase() == d.event?.toLowerCase());
    const arrToValidity = _.cloneDeep(this.selection.rows);

    // *only for sessioni :/

    // console.log(data?.isSelect);
    // console.log(this.definitionValid, this.featureCode);

    if (this.definitionValid && this.featureCode == 'santiSession') {
      
      if (data && data.motivation && data.motivation.name != this.definitionValid && name == 'select_row') {
        return false;
      }
    } else if(this.featureCode && this.featureCode == 'session') {
      if (this.selection.rows.length > 0) {
        this.definitionValid = this.selection.rows[0]?.row?.motivation?.name;
      } else {
        this.definitionValid = undefined;
      }

    }

    if (this.definitionValid && data && data.motivation && (data.motivation.name != this.definitionValid) && name == 'select_row') {
      return false;
    }



    if (this.strategySelect && (name == 'select_row' || name == 'wrapSession')) {
      if (data && data.fixedPrice != undefined) {
        if (this.strategySelect) {
          const a = [...arrToValidity?.map(d => d?.row)];
          // let sumaVerdadera = 0;
          let sumaDeSelects = 0;

          if (a && a.length > 0) {
            a.forEach(item => {
              if (item) {
                // sumaVerdadera = data.id == item.id ? item.fixedPrice : data.fixedPrice + item.fixedPrice;
                if (item.isSelect) {
                  sumaDeSelects += item.fixedPrice;
                }
                if (data && !data.isSelect && (sumaDeSelects + data.fixedPrice) > this.strategySelect?.value) {
                  this.notPosible[data.id] = false;
                } else {
                  this.notPosible[data.id] = true;
                }
              }
            });
          } else {
            this.notPosible[data.id] = true;
          }

          if ((data && data.id && data.fixedPrice > this.strategySelect.value)) {
            return false;
          }

          if (this.notPosible[data.id] == false) {
            return false;
          }

          return true;
        }

      }


    }

    if ((this.config && this.config.isRecordEnabled && isFunction(this.config.isRecordEnabled))) {
      return this.config.isRecordEnabled(name, data);
    }

    if (nameOfEvent && nameOfEvent.acceptRole && nameOfEvent.acceptRole.length > 0) {
      return nameOfEvent.acceptRole.find(roleAcc => this.roles.find(role => role?.toLowerCase() == roleAcc?.toLowerCase()));
    }

    if ((this.config?.actions?.find(d => name == d.event && d?.acceptRole?.find(rl => this.roles.find(onRole => rl == onRole))))) {
      return true;
    }

    return true;
  }

  clearSelection() {
    this.selectionStatus = {};
    this.selection = { rows: [], all: false };
  }

  valuePagueIndex = 1;

  get pageIndex() {
    return this.valuePagueIndex
  }

  set pageIndex(v: any) {
    this.valuePagueIndex = v;
  }

  goToPage(ev: KeyboardEvent | Event) {

    const quantitaPage = this.tableData.tableStatus.page.length / this.tableData.tableStatus.page.pageSize;
    let value = parseFloat((ev.target as any)?.value);
    const max = Math.ceil(quantitaPage);

    if ((value - 1) >= max) {
      value = max;
      this.dialogService.openSnack(`non c'è piu pagina, il massimo e ${(max)} `, '', 5000);
    }

    if ((quantitaPage + 1) >= value || _.isNaN(value)) {
      this.paginator.pageIndex = isNaN(value) ? 0 : (value - 1);

      this.paginator.page.next({
        pageIndex: (this.paginator.pageIndex),
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });

    } else {
      this.dialogService.openSnack(`non c'è piu pagina, il massimo e ${(max)} `, '', 5000);
    }
  }

  private getRowId(row: any) {
    return row != null && row.id ? row.id : JSON.stringify(row);
  }

  getActions(type: string): TableAction[] {

    return this.actions[type];
  }

  private actionTypeSelect(value: any) {
    this.hostColumnEditService.updatedDataSelectionALL(value);

  }

  private actionTypeInput(value: any) {
    this.hostColumnEditService.updatedDataSelectionALLInput(value);
  }

  setQuantity(e?: Event) {
    if (e) {
      e.preventDefault();
      this.setAvailable = !this.setAvailable;
    }

    if (this.setAvailable) {
      this.bindingType('insertQuantity').typeIdInput = '0';
      this.hostColumnEditService.hostSetAllQuantity(true);
    } else {
      this.hostColumnEditService.hostSetAllQuantity(false);
    }
  }

  bindingType(id: any) {
    const _me = this;
    return {
      get typeId(): any {
        return _me.typeObjectId[id]?.id ? _me.typeObjectId[id]?.id : _me.typeObjectId[id];
      },

      set typeId(typeOn: any | object) {
        if (typeOn == '' || typeOn == null) {
          typeOn = null;
        }

        if (isObject(typeOn)) { // cuando retorne un problema . obtengo el id y mando solo el numero al subject y hago el calculo en base al numero y no en base al objeto. :)
          _me.typeObjectId[id] = typeOn;
        } else if (!Number.isNaN(typeOn)) {
          _me.typeObjectId[id] = typeOn;
        }

        _me.actionTypeSelect(_me.typeObjectId);
      },

      get typeIdInput() {
        return _me.typeObjectId[id];
      },
      set typeIdInput(valueInput) {
        if (valueInput == '' || valueInput == null) {
          valueInput = null;
        }
        _me.typeObjectId[id] = valueInput;
        _me.actionTypeInput(_me.typeObjectId);

      },
    }
  }


  private init() {
    this.actions = {};

    if (this.config && !this.config.columnsFields && !this.displayedColumns) {
      this.displayedColumns = ['no_col_defined'];
    }
    if (this.config && !this.config.columnsFields && this.displayedColumns) {
      this.config.columnsFields = this.displayedColumns.map(value => new TableField(value, undefined, 'default.' + value));
    } /*else if (!this.displayedColumns && this.config.columnsFields) {
      this.displayedColumns = this.config.columnsFields.map( value => value.id);
    }*/

    this.loadConfig();

  }

  private loadConfig() {
    if (this.config) {
      this.selectMode = this.config.selectionMode || this.selectMode;
      if (!this.config.pageSize) {
        this.config.pageSize = 20;
      }

      // facciamo vedere sempre il sort clear
      if (this.config.actions || true) {
        const _SELF = this;
        if (this.config.actions) {
          Object.keys(TableActionType).forEach(value => {
            if (_SELF.config?.actions) {
              _SELF.actions[value] = _SELF.config.actions.filter(ac => ac?.type === value)
            }
          });
        }


        if (this.displayedColumns && this.getActions('RECORD').length > 0 && !this.displayedColumns.includes('actions')) {
          this.displayedColumns.push('actions');
        }



      }

      if (this.config.selectionMode === SelectionMode.multiple || (this.config.selectionMode === SelectionMode.multipleWithBaseRoute)) {

        if (this.displayedColumns && !this.displayedColumns.includes('multi_selection')) {
          this.displayedColumns.splice(0, 0, 'multi_selection');
        }

      }
    }
  }

  // !non mi serve
  // disabledOnWrapp (): boolean {
  //   if ((this.featureCode == 'santiSession') && this.strategySelect) {
  //     const vSelection = _.cloneDeep(this.selection);
  //     return !!(vSelection && vSelection.rows && vSelection.rows.length > 0 && (vSelection.rows.filter(d => d.row.fixedPrice)) as any[])
  //   }
  //   return true;
  // }

  private clearValuesInputs() {
    // fixme santiago controlla il dato
    this.config?.columnsFields?.forEach(column => {
      this.typeObjectId = {};
      this.setAvailable = false;
      this.setQuantity();
      this.bindingType(column.id).typeIdInput = null;
      this.bindingType(column.id).typeId = null;
    });
  }

  clearSort() {
    this.sortStatus = {};
    this.sortSubject.next(this.sortStatus);
  }
  selectRowEvident(data: RowSelect) {
    this.statusPer = data;
  }

  // TODO: qua dovrei lavorare per aprire il modale

  onRowClick(row: any, ev: PointerEvent | MouseEvent) {


    if (this.selectMode === SelectionMode.single || this.selectMode === SelectionMode.multipleWithBaseRoute) {
      this.onSelectOnlySingle(row, ev.ctrlKey);
    }
    return false;
  }

  isSelected(row: any) {
    const rowId = this.getRowId(row);

    return this.selectionStatus && this.selectionStatus[rowId] && this.selectionStatus[rowId]?.selected && !this.selectionStatus[rowId]?.ctrlKey || false;
  }

  onSelectOnlySingle (selectedRow: any, ctrlKey?: boolean) {
    const rowId = this.getRowId(selectedRow?.row || selectedRow);

    // if (this.selectMode === SelectionMode.single) { }

    const rowSelectionStatus = (selectedRow?.row != null) || (selectedRow != null) && !this.isSelected(selectedRow.row || selectedRow);
    this.selectionStatus = {};
    this.selectionStatus[rowId] = { row: selectedRow?.row || selectedRow, selected: !rowSelectionStatus, ctrlKey: ctrlKey };

    if (this.isEnabled('select', selectedRow?.row || selectedRow)) {
      this.selection = selectedRow?.row || selectedRow ? { rows: [selectedRow?.row || selectedRow] } : { rows: [] };
      this.selection.ctrlKey = ctrlKey;
      this.rowSelect.emit({ ...this.selection });
    } else {
      this.selection = { rows: [] };
    }


    if (this.config && this.config.rowDialog) {

      this.dialog.open(DialogRowComponent, {
        data: selectedRow?.row || selectedRow,
        height: '80%',
        width: '80%',
        disableClose: true

      });

    }

  }

  onSelect(selectedRow: any, ctrlKey?: boolean) {

    const rowId = this.getRowId(selectedRow?.row || selectedRow);

    if (this.selectMode === SelectionMode.single) {

      const rowSelectionStatus = (selectedRow?.row != null) || (selectedRow != null) && !this.isSelected(selectedRow.row || selectedRow);
      this.selectionStatus = {};
      this.selectionStatus[rowId] = { row: selectedRow?.row || selectedRow, selected: !rowSelectionStatus, ctrlKey: ctrlKey };

      if (this.isEnabled('select', selectedRow?.row || selectedRow)) {
        this.selection = selectedRow?.row || selectedRow ? { rows: [selectedRow?.row || selectedRow] } : { rows: [] };
        this.selection.ctrlKey = ctrlKey;
        this.rowSelect.emit({ ...this.selection });
      } else {
        this.selection = { rows: [] };
      }


      if (this.config && this.config.rowDialog) {

        this.dialog.open(DialogRowComponent, {
          data: selectedRow?.row || selectedRow,
          height: '80%',
          width: '80%',
          disableClose: true

        });

      }


    } else if (this.selectMode === SelectionMode.multiple || (this.selectMode === SelectionMode.multipleWithBaseRoute)) {

      if (selectedRow.row && selectedRow.row.motivation && selectedRow.row.motivation.name) {

        if (!selectedRow.row.isSelect) {
          this.definitionValid = selectedRow.row.motivation.name;

        } else {
          if (this.selection?.rows?.length == 1) {

            this.definitionValid = undefined;
          }
        }

      }


      if (this.isEnabled('select', selectedRow?.row || selectedRow)) {

        const rowSelectionStatus = (this.selectionStatus[rowId]?.selected || false);
        this.selectionStatus[rowId] = this.selectionStatus[rowId] || selectedRow?.not ? { selected: false, row: selectedRow?.row || selectedRow, not: selectedRow.not, ctrlKey: ctrlKey } : { selected: false, row: selectedRow?.row || selectedRow, ctrlKey: ctrlKey };
        this.selectionStatus[rowId].selected = (selectedRow?.row != null || selectedRow != null) && !rowSelectionStatus;
        const selections: any[] = [];


        Object.keys(this.selectionStatus).forEach(k => {
          if (this.selectionStatus[k]?.selected) {
            this.selectionStatus[k].row.isSelect = true;
            selections.push(this.selectionStatus[k]);
          } else {
            this.selectionStatus[k].row.isSelect = false;
          }
        });

        this.selection = { rows: [...selections] };
        this.selection.all = this.tableData.data.filter(row => this.isEnabled('select', row)).every(row => this.selectionStatus[this.getRowId(row)]?.selected);
        this.selection.ctrlKey = ctrlKey;
        if (ctrlKey) {
          this.selection.rows = this.selection.rows.filter(d => d.row.id == selectedRow.id);
        }

        this.rowSelect.emit({ ...this.selection });
      }
    }
  }

  customEvent(names: string | undefined, data?: any) {
    this.custom.emit({ name: names, data: data ? { ...data } : null });
  }

  private clearDataTableInputs() {
    this.actionTypeSelect(null);
    this.actionTypeInput(null);
  }

  setSort(columnField: TableField) {


    if (!columnField || !columnField.sortable) { return; }
    const sortItem: SortItem = this.sortStatus[columnField.id];
    // prima volta
    if (!sortItem) {
      this.sortStatus[columnField.id] = {
        field: columnField,
        position: Object.keys(this.sortStatus).length,
        direction: SortDirection.ASC
      };
    } else if (sortItem && sortItem.direction === SortDirection.ASC) {
      sortItem.direction = SortDirection.DESC;
    } else {
      this.sortStatus[columnField.id] = undefined as any;
      delete this.sortStatus[columnField.id];
    }
    _.sortBy(Object.values(this.sortStatus), 'position').forEach((si: SortItem, index: number) => si.position = index);
    this.sortSubject.next(this.sortStatus);
  }

  isSelectionMultiple() {
    return (this.selectMode === SelectionMode.multiple) || (this.selectMode === SelectionMode.multipleWithBaseRoute);
  }



}
