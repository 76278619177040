import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-delete',
  templateUrl: './snack-delete.component.html',
  styleUrls: ['./snack-delete.component.scss'],
  styles: [`
    .example-content {
      color: hotpink;
      display: flex;
      justify-content: space-between;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  
})
export class SnackDeleteComponent {

  
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {
      content: string;
      icon?: string;
    },
    private matSnackBarRef: MatSnackBarRef<SnackDeleteComponent>
  ) { }

  close() {
    this.matSnackBarRef.dismiss();
  }

}
