import {isNull, isNullOrUndefined, isUndefined} from 'is-what';

export class ComponentUtils {

  public isNull(o: any) {
    return isUndefined(o) || isNull(o);
  }

 public compareEntity(o: any, o2: any) {
    if ( isNullOrUndefined(o) && isNullOrUndefined(o2) ) {
      return true;
    }

    if ((isNullOrUndefined(o) && !isNullOrUndefined(o2)) || (isNullOrUndefined(o2) && !isNullOrUndefined(o))) {
      return false;
    }

    return o == o2 || o.id == o2.id;
  }
}
