import {Component, OnInit} from '@angular/core';
import {debounceTime, catchError, throwError, distinctUntilChanged, BehaviorSubject} from 'rxjs';
import {ActionData, TableActionComponent} from 'src/app/core/components/table/Actions';
import {TransferType} from 'src/app/shared/models/entities';
import {LayoutActionType} from 'src/app/store/layout/layout.reducers';
import {DynaConfActionType, State, selectFeatureCode} from 'src/app/store/shared/shared.reducers';
import {BaseAction} from 'src/app/store/store.actions';
import {WarehouseMovementDTO} from '../../../models/entities';
import {SubSink} from 'subsink';
import {Store, select} from '@ngrx/store';
import {HostAllRowsInColumnsService} from 'src/app/shared/services/host-all-rows-in-columns.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {HostColumnEditService} from 'src/app/shared/services/host-column-edit.service';
import {AddMovementService} from '../../../services/add-movement.service';
import {CountRowsService} from '../../../services/count-rows.service';
import {
  ReloadRowItemsSingleService
} from '../../columns/quantity-session-movement-column/quantity-session-movement-column.component';
import {whMovements} from '../add-movement-session-action/add-movement-session-action.component';
import {
  SuccesComponent
} from '../../columns/select-warehouse-change-trans-column/select-warehouse-change-trans-column.component';
import {DialogEditService} from '../../../services/dialog-edit.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {IDMODALSESSION} from '../edit-session-record/edit-session-record.component';

@Component({
  selector: 'app-edit-movement-session-button-action',
  templateUrl: './edit-movement-session-button-action.component.html',
  styleUrls: ['./edit-movement-session-button-action.component.scss']
})
export class EditMovementSessionButtonActionComponent implements TableActionComponent, OnInit {

  constructor(private store: Store<State>, private hostallRows: HostAllRowsInColumnsService, private _snackBar: MatSnackBar, private dialogService: DialogService, private dialog: MatDialog,
              private _countRowsService: CountRowsService, private addMovement: AddMovementService, private dialogServiceEditSession: DialogEditService,
              private route: ActivatedRoute, private router: Router, private hostRow: HostColumnEditService, private _DATASERVICESINGLE: ReloadRowItemsSingleService) {
    this.instanceDialog = this.dialog.getDialogById(IDMODALSESSION);

  }

  public data!: ActionData;
  currentSelectProp: any;
  private subs = new SubSink();
  private codeFeature!: string;
  go = new BehaviorSubject<boolean | null>(null);
  private goSub = this.go.asObservable();

  itemSessionId!: number | undefined;
  instanceDialog: MatDialogRef<any> | undefined;
  instanceDataDialog: any;
  codeFromDialog = '';


  private result: any;
  error!: boolean;

  ngOnInit(): void {

    const TYPE_ = TransferType;

    this.itemSessionId = this.instanceDialog?.componentInstance?.dataOver2?.idSession || '';

    this.instanceDataDialog = this.instanceDialog?.componentInstance?.dataOver2;

    this.codeFromDialog = this.instanceDataDialog?.featureType || '';


    /**get feature code */
    this.codeFeature = '';

    this.subs.add(
      this.store.pipe(select(selectFeatureCode), distinctUntilChanged()).subscribe(data => {
        if (data) {
          this.codeFeature = this.getCode(data);
          this.initValues();
        }
      })
    );


    this.goSub.pipe(distinctUntilChanged(), debounceTime(50)).subscribe(d => {

      if (d) {
        if (this.data.item.id && this.valid()) {
          this.currentSelectProp = this.data.item;
          if (this.currentSelectProp?.id) {
            // trans / unload / other
            if ((TYPE_.TRANS == this.getCode(this.codeFeature)) || ((this.codeFeature == 'session_detail' || this.codeFeature == 'session') && this.codeFromDialog == TYPE_.TRANS)) {

              if ((this.currentSelectProp.instanceValue <= this.data.item.avaible && this.currentSelectProp.instanceValue > 0) && (this.currentSelectProp?.instanceValueWarehouse?.id)) {
                const itemRow_ = {selected: true, row: this.currentSelectProp};
                this._countRowsService.pushRow(itemRow_);
              }
            } else if ((TYPE_.UNLOAD == this.getCode(this.codeFeature)) || ((this.codeFeature == 'session_detail' || this.codeFeature == 'session') && this.codeFromDialog == 'UNLOAD')) {

              if ((this.currentSelectProp.instanceValue <= this.data.item.avaible && this.currentSelectProp.instanceValue > 0) /*&& (this.currentSelectProp?.instanceValueWarehouse?.id)*/) {
                const itemRow_ = {selected: true, row: this.currentSelectProp};
                this._countRowsService.pushRow(itemRow_);
              }
            }
          }
        }
      }
    });
  }

  private getCode(f: string): string {
    // controllo il codice ? posso modificarlo , ma non lo sovrascrivo
    return f;
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public valid(): boolean | undefined {
    const TYPE_ = TransferType;
    if (this.currentSelectProp) {
      if (this.currentSelectProp.id == this.data.item.id) {
        if ((TYPE_.TRANS == this.getCode(this.codeFeature)) || ((this.codeFeature == 'session_detail' || this.codeFeature == 'session') && this.codeFromDialog == TYPE_.TRANS)) {
          if (((this.currentSelectProp.instanceValue <= this.data.item.avaible) && this.currentSelectProp?.instanceValue > 0 && this.currentSelectProp.instanceValueWarehouse?.id) && (this.currentSelectProp?.instanceValueWarehouse?.id !== this.data?.item?.warehouse.id)) {
            // push to array with values
            this.error = false;
            this.initValues();
            return true;
          } else {
            // removes to array with id  --> id object
            this.go.next(false);
            this.error = true;
            return false;
          }
        } else if ((TYPE_.UNLOAD == this.getCode(this.codeFeature)) || ((this.codeFeature == 'session_detail' || this.codeFeature == 'session') && this.codeFromDialog == 'UNLOAD')) {

          if (((this.currentSelectProp.instanceValue <= this.data.item.avaible) && this.currentSelectProp?.instanceValue > 0)) {
            // push to array with values
            this.error = false;
            this.initValues();
            return true;
          } else {
            // removes to array with id  --> id object
            this.go.next(false);
            this.error = true;
            return false;
          }
        }
      }

    }

    return undefined;
  }

  private preSave() {
    return <WarehouseMovementDTO>Object.assign(new whMovements(), {
      productId: this.data?.item.product.id,
      quantity: this.data?.item.instanceValue,
      conditionId: this.data.item?.combination?.conditionId || null,
      languageId: this.data.item?.combination?.languageId || null,
      holoId: ( this.data.item?.combination?.holo?.id != null ) ? this.data.item?.combination?.holo?.id : null,
      signed: this.data.item?.combination?.signed != null ? this.data.item.combination.signed : null,
      stamped: this.data.item?.combination?.stamped != null ? this.data.item.combination.stamped : null,
      inked: this.data.item?.combination?.inked != null ? this.data.item.combination.inked : null,
      altered: this.data.item?.combination?.altered != null ? this.data.item.combination.altered : null,
      foil: this.data.item?.combination?.foil != null ? this.data.item.combination.foil : null,
      firstEdition: this.data.item?.combination?.firstEdition != null ? this.data.item.combination.firstEdition : null,
      shadowless: this.data.item?.combination?.shadowless != null ? this.data.item.combination.shadowless : null,
      staff: this.data.item.combination?.staff != null ? this.data.item.combination.staff : null,
      warehouseFromId: this.data.item.warehouse?.id,
      warehouseToId: this.data.item?.instanceValueWarehouse?.id || null,
      comments: this.data.item.comments || null,
    });

  }

  openSnackBar() {

    this._snackBar.openFromComponent(SuccesComponent, {
      duration: 5000,
    });

  }


  initValues() {
    const TYPE_ = TransferType;
    if ((TYPE_.TRANS == this.getCode(this.codeFeature)) || (this.codeFeature == 'session_detail' || this.codeFeature == 'session')) {
      this.subs.add(
        this.hostallRows.dataRowToAll.pipe().subscribe(result => {


          if (result != null || !this.currentSelectProp) {
            this.currentSelectProp = this.data.item;
            this.go.next(true);
          } else {
            this.go.next(false);
          }

        })
      );
    } else if ((TYPE_.UNLOAD == this.getCode(this.codeFeature)) || (this.codeFeature == 'session_detail' || this.codeFeature == 'session')) {
      this.subs.add(
        this.hostallRows.dataRowToAll.pipe().subscribe(result => {

          if (result != null) {
            this.currentSelectProp = this.data.item;
            this.go.next(true);
          } else {
            this.go.next(false);
          }
        })
      );

    }


  }

  saveInsert(e: Event) {
    e.preventDefault();
    if (this.currentSelectProp?.id) {
      const itemToSave = this.preSave();

      this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));

      this.subs.add(
        this.dialogServiceEditSession.updateItemModal(this.itemSessionId as number, this.instanceDataDialog.item.id, [itemToSave]).pipe(debounceTime(50), catchError(err => {
          this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
          return throwError(err);
        })).subscribe(dataResult => {
          if (dataResult) {
            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
            this.result = dataResult;
            (this.result.data as WarehouseMovementDTO[]).forEach((cards) => {
              if (cards.productId == this.data.item.productId) {
                this.data.item.instanceValue = 0;
                this.data.item.instanceValueWarehouse = null;
                this._DATASERVICESINGLE._data.next({data: this.data.item.productId, reload: true});
                this.hostRow._resetData.next(false);

              }
            });
            this.openSnackBar();

            this.hostRow._newIdSession.next({id: this.itemSessionId, code: this.codeFeature});

            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));

            (this.result.data as WarehouseMovementDTO[]).forEach((cards) => {
              if (cards.productId == this.data.item.productId) {
                this.data.item.instanceValue = 0;
                this.data.item.instanceValueWarehouse = null;
                this._DATASERVICESINGLE._data.next({data: this.data.item.productId, reload: true});
                this.hostRow._resetData.next(false);
                this.hostRow.hostSetAllQuantity(false);
                this._countRowsService.deleteRow();
                this.hostRow._newIdSession.next({id: this.itemSessionId, code: this.codeFeature});

              }
            });

            this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));

            this.dialogServiceEditSession.setReload(true);

            setTimeout(() => {
              this.instanceDialog?.close();
            }, 50);


          }
        }));
    }
  }


}
