<div class="d-flex align-items-center gap-2">
  <ng-container *ngIf="!systemSession && isEditTitle; else normalEdit">



    <div class="d-flex" *ngIf="!editName ; else EDITNAME">
      {{titleService.getTitle()}}
    </div>



    <ng-template #EDITNAME>
      <ng-container *ngIf=" session.status.id && session.status.id != 4 ; else normalEdit ">

        <div class="input-group">
          <span class="input-group-text">Nome</span>
          <input type="text" #inputTitle aria-label="First name" [disabled]="(session.obsolete === true || session.status.id === 4 || isCompletedDaTable)" [(ngModel)]="editNameModel" class="form-control"
            style="width: 380px;">
        </div>

      </ng-container>

    </ng-template>

    <div class="d-flex" *ngIf="session && session.status">
      <button mat-icon-button [color]="editName ? 'warn' : 'primary'" [disabled]="(session.obsolete === true || session.status.id === 4 || isCompletedDaTable)" (click)=" openEditare(); editName = !editName;  ">
        <mat-icon>
          edit
        </mat-icon>
      </button>
    </div>

    <div class="d-flex" *ngIf="editName">
      <button matTooltip="save nome" mat-mini-fab color="primary" (click)="save()">
        <mat-icon>
          done
        </mat-icon>
      </button>
    </div>
  </ng-container>


</div>

<ng-template #normalEdit>
  {{titleService.getTitle()}}
</ng-template>