import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CheckAuthService} from './auth/services/check-auth.service';
import {HomeComponent} from './features/home/components/home/home.component';
import {SetInsertDetailConfig} from "./features/warehouse/models/route-config";
import {MainSetInsertComponent} from "./layout/components/main-set-insert/main-set-insert.component";
const routes: Routes = [


  {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},


  {
    path: 'app',
    canActivate: [CheckAuthService],
    canActivateChild: [CheckAuthService],
    children: [


      {path: '', redirectTo: 'home', pathMatch: 'full'},

      {path: 'home', component: HomeComponent},

      {path: 'shop', loadChildren: () => import('./features/shop/shop.module').then(m => m.ShopModule)}, // ⚡🚀

      {path: 'prices', loadChildren: () => import('./features/prices/prices.module').then(m => m.PricesModule)}, // ⚡🚀

      {
        path: 'warehouse',
        loadChildren: () => import('./features/warehouse/warehouse.module').then(m => m.WarehouseModule),
      }, // ⚡🚀

      {
        path: 'marketplaces',
        loadChildren: () => import('./features/marketplaces/marketplaces.module').then(m => m.MarketplacesModule)
      },  // ⚡🚀

      {
        path: 'ecommerce',
        loadChildren: () => import('./features/market-ecommerce/market-ecommerce.module').then(m => m.MarketEcommerceModule)
      }, // ⚡🚀

      {
        path: 'ecommerce/shippings',
        loadChildren: () => import('./features/shippings/shippings.module').then(m => m.ShippingsModule)
      }, // ⚡🚀

      {path: 'set-insert-out', component: MainSetInsertComponent, data: {routeConf: SetInsertDetailConfig}},
      {path: 'set-insert-out/:id', component: MainSetInsertComponent, data: {routeConf: SetInsertDetailConfig}},


    ]
  },


  {path: '**', redirectTo: 'auth', pathMatch: 'prefix'},

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
