import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {BehaviorSubject, map, combineLatest, Observable} from 'rxjs';
import {DynacrudApiWrapper} from 'src/app/core/api/dynacrud-api';
import {CategoryStructure, ProductStructure} from 'src/app/shared/models/entities';

@Injectable({
  providedIn: 'root'
})
export class ProductStructureService {

  private categoryStructures: CategoryStructure[] = [];
  private productStructures: ProductStructure[] = [];

  private findStructureCache: { [key: string]: ProductStructure } = {};
  private avaibleStructuresCache: { [key: string]: CategoryStructure[] } = {};
  private enabledStructuresCache: { [key: string]: boolean } = {};
  private readySub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(private dynacrudApiWrapper: DynacrudApiWrapper) {

    // FIXME K3 ? PASSIAMO PER LO STORE per category, brands, categoryStructures, productStructures devono essere sempre disponibili senza chiamate
    const all = {pagination: {page: 0, size: 10000}};
    const categoryStructuresObs = this.dynacrudApiWrapper.getFor('categoryStructures').search(all).pipe(map(ret => ret.data));
    const productStructuresObs = this.dynacrudApiWrapper.getFor('productStructures').search(all).pipe(map(ret => ret.data));
    this.dynacrudApiWrapper.getFor('categoryStructures').search(all).subscribe(ret => this.categoryStructures = ret.data as CategoryStructure[]);
    this.dynacrudApiWrapper.getFor('productStructures').search(all).subscribe(ret => this.productStructures = ret.data as ProductStructure[]);

    combineLatest([categoryStructuresObs, productStructuresObs]).pipe().subscribe(ret => {
      this.categoryStructures = ret[0] as CategoryStructure[];
      this.productStructures = ret[1] as ProductStructure[];
      if (ret && ret.length > 1) {
        this.readySub.next(true);

      }
    });


  }

  public isReady() {
    return this.categoryStructures.length > 0 && this.productStructures.length > 0;
  }

  public isReadyObs(): Observable<boolean> {
    return this.readySub.asObservable();
  }

  public findStructure(categoryId: number, brandId: number): ProductStructure {


    const key = categoryId + '-' + brandId;
    if (!this.findStructureCache[key]) {

      let productStructure: ProductStructure | undefined;

      if (categoryId) {
        let found: CategoryStructure[] = this.categoryStructures.filter(cs => cs.categoryId == categoryId);
        // avaibleBrands = found.map( cs => cs.brandId);
        if (found.length > 1) {
          const brandFound = found.filter(cs => cs.brandId == brandId) || found.filter(cs => cs.brandId == 0);
          if (brandFound.length == 1) {
            found = brandFound;
          }
        }
        if (found.length > 0) {
          const catStruct: CategoryStructure = found[0];
          productStructure = this.productStructures.find(ps => ps.id == catStruct.productStructureId);
          if (productStructure) {

            productStructure.fields = productStructure.fields?.map(f => {
              return {...f, orderKey: '0_' + f.order, stock: false};
            });

            productStructure.stockfields = productStructure.stockfields?.map(f => ({
              ...f,
              orderKey: '1_' + f.order,
              stock: true
            }));

            if (productStructure.stockfields) {
              productStructure.allFields = productStructure.fields?.concat(productStructure.stockfields);
            }
          }

        }
      }

      this.findStructureCache[key] = productStructure as ProductStructure;
    }
    return this.findStructureCache[key] ? _.cloneDeep(this.findStructureCache[key]) : this.findStructureCache[key];
  }

  public getCategoryStructures(categoryId?: number, brandId?: number): CategoryStructure[] {

    const key = categoryId + '-' + brandId;
    if (!this.avaibleStructuresCache[key]) {

      let avaibleStructures: CategoryStructure[] = [...this.categoryStructures];

      if (categoryId) {
        avaibleStructures = avaibleStructures.filter(cs => cs.categoryId == categoryId);
      }
      if (brandId) {
        avaibleStructures = avaibleStructures.filter(cs => cs.brandId == brandId);
      }

      this.avaibleStructuresCache[key] = avaibleStructures;
    }
    return this.avaibleStructuresCache[key];
  }


  public isEnabledStruct(categoryId: number, brandId: number, fieldName: string): boolean {
    const key = categoryId + '-' + brandId + '-' + fieldName;
    if (this.enabledStructuresCache[key] == undefined) {
      let ret = false;
      const productStructure: ProductStructure = this.findStructure(categoryId, brandId);
      ret = productStructure ? true : false;

      if (ret) {
        const fieldsStruct =
          productStructure.fields?.find(f => f.enabled && f.field == fieldName)
          ||
          productStructure.stockfields?.find(f => f.enabled && f.field == fieldName)
          || null;
        ret = fieldsStruct ? true : false;
      }

      this.enabledStructuresCache[key] = ret;
    }


    return this.enabledStructuresCache[key];
  }

}
