import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { isObject } from 'is-what';
import { distinctUntilChanged } from 'rxjs';
import { ColumnFieldComponent, ColumnData } from 'src/app/core/components/table/Column';
import { Warehouse } from 'src/app/shared/models/entities';
import { HostAllRowsInColumnsService } from 'src/app/shared/services/host-all-rows-in-columns.service';
import { HostColumnEditService } from 'src/app/shared/services/host-column-edit.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-select-warehouse-change-trans-column',
  templateUrl: './select-warehouse-change-trans-column.component.html',
  styleUrls: ['./select-warehouse-change-trans-column.component.scss']
})
export class SelectWarehouseChangeTransColumnComponent implements ColumnFieldComponent, OnInit, OnDestroy {

  subs = new SubSink();
  constructor(
    private _snackBar: MatSnackBar,
    private hostServiceColumn: HostColumnEditService,
    private hostRowsAll: HostAllRowsInColumnsService
  ) { }

  data!: ColumnData;
  warehouse: any | any[];
  ToTransf: Warehouse | null | undefined;
  elementdaClick!: boolean;
  error: boolean = false;
  private init(): void {
    this.elementdaClick = false;
  }

  get whHost(): Warehouse[] {
    return this.data.row?.warehouses?.filter((res: Warehouse) => { return res.id != this.data.row.warehouse.id }) as Warehouse[];
  }

  get bindingResultWarehouse () {
    this.ToTransf = this.data.row.instanceValueWarehouse;
    if (this.ToTransf?.id) {
      return this.ToTransf.id;
    } else {
      return this.bindingSingle(this.data.row.id).item;
    }
  }

  set bindingResultWarehouse (val) {
    this.ToTransf = val;
  }

  openSnackBar() {
    this._snackBar.openFromComponent(ErrorComponent, {
      data: this.data.row.warehouse,
      duration: 5000,
    });
  }

  rowSelect(n: number) {
    if (this.data.row?.isSelect == false || this.data.row?.isSelect == true /**puo essere false */ && n /**essite il id */) {
      return this.data.row.isSelect;
    }
  }
  getidRow(): number {
    return this.data.row.id;
  }
  
  //binding single :)
  bindingSingle(idNum: any) {
    let instance: {[key: string]: any} = {};
    const me = this;
    return {
      get item(): Warehouse | null | undefined {
        return instance[idNum] || me.ToTransf;
      },

      set item(item) {
        if (item != null || item == null) {
          me.ToTransf = item;
          instance[idNum] = me.ToTransf;
          if (me.data.row ) {
            me.data.row.instanceValueWarehouse = me.ToTransf;
            // if ( me.data.row.instanceValueWarehouse?.id === instance[me.data.row?.instanceValueWarehouse?.id] ) {

            // }
            me.hostRowsAll.updatedDataSelection2(me.data.row);
            
          }
        }
      },

      get valId(): string | number {
        return instance['valInput'];
      },
      set valId(inputVal) {
        instance['valInput'] = inputVal;
        
      }

    }
  }

  ngOnDestroy(): void {
    this.clear();
    this.subs.unsubscribe();
  }

  clear(): void {
    /**FIXME SANTIAGO CONTROLLA ITEMS CHE DEVONO MORIRE PRIMA DI ARRIVARE AL ngOnInit */
    this.ToTransf = null;
    this.bindingSingle(this.data.row.id).item = this.ToTransf;

  }


  ngOnInit(): void {
    if (this.data.row.id) {
      //fixme santiago mi prendo la data init
      this.init();
    }
    this.subs.add(
      this.hostServiceColumn.obsSelectRow.pipe(distinctUntilChanged()).subscribe((d: any[]) => {
        if (d && d.length > 0) {
          d.forEach(item => {
            if (item && item.row && item.row.id) {
              if (item.row.id == this.data.row.id) {
                if (item.row.id ) { // fixme santiago se puo eliminare

                  
                  
                  this.subs.add(
                    this.hostServiceColumn.dataCol.pipe().subscribe((actionVal: any) => {



                          if (actionVal /*&& actionVal[this.data?.field?.id] != null*/) {
                              if (actionVal?.warehouses === null && !this.elementdaClick ) {
                                this.bindingSingle(this.data.row.id).item = null;
                                // this.bindingResultWarehouse = null;
                              }

                              if (this.rowSelect(this.getidRow()) == true) {


                                
                                if (Number(actionVal[this.data.field.id])) {
                                  if (this.data.row.warehouses?.length > 0) {
                                    (this.data.row.warehouses as []).forEach((d: Warehouse) => {
                                      if (d.id == actionVal[this.data.field.id]) {
                                        if (d.id == this.data.row.warehouse.id) {
                                          if ( /*(!this.ToTransf?.id || !this.ToTransf) && */  !this.elementdaClick) {
                                            this.error = true;
                                            this.openSnackBar();
                                            this.bindingSingle(this.data.row.id).item = null;
                                          }
                                        } else {
                                          this.error = false;
                                          if ( /*(!this.ToTransf?.id || !this.ToTransf) && */  !this.elementdaClick) {
                                            this.ToTransf = d;
                                            this.data.row.instanceValueWarehouse = this.ToTransf;
                                            this.bindingSingle(this.data.row.id).item = this.data.row.instanceValueWarehouse;
                                            // this.bindingResultWarehouse = this.data.row.instanceValueWarehouse;
                                          }
                                        }
                                      }
                                    });
                                  }
                                } else {
                                  if (!Number(actionVal[this.data.field.id])) {
                                    if (actionVal[this.data.field.id]?.id == this.data.row.warehouse.id) {
                                      if ( /*(!this.ToTransf?.id || !this.ToTransf) && */  !this.elementdaClick) {
                                        this.error = true;
                                        this.openSnackBar();
                                        this.bindingSingle(this.data.row.id).item = null;
                                      }
                                    } else {
                                      this.error = false;
                                      if ( /*(!this.ToTransf?.id || !this.ToTransf) && */  !this.elementdaClick) {
                                        this.ToTransf = actionVal[this.data.field.id];
                                        this.data.row.instanceValueWarehouse = this.ToTransf;
                                        this.bindingSingle(this.data.row.id).item = this.data.row.instanceValueWarehouse;
                                        // this.bindingResultWarehouse = this.data.row.instanceValueWarehouse;
                                      }
                                    }
                                  }
                                }





                              }


                          }
                    })
                  );



                }
              }
            }
          });
        }
        
      })
    );  
  }

  objectCont(eventObject: any) {
    if ((eventObject != null) && eventObject != undefined ) {

      this.elementdaClick = true;
      let res: any;
      if (isNumeric( eventObject ) ) {
        res = this.whHost.find(d => d.id == eventObject) || null;
      } else {
        if (isObject(eventObject)) {
          res = this.whHost.find(d => (d.id == eventObject['id']));
        }
      }
      if (res == null && eventObject != '') {
        this.error = true;
        if (this.error) {
          // this.bindingSingle(this.data.row.id).item = null;
          this.openSnackBar();
        }
      } else if (res?.id) {
        this.error = false;
        this.ToTransf = res;
        this.bindingSingle(this.data.row.id).item = res;
      }

    } else {
      if (eventObject == null) {
        this.bindingSingle(this.data.row.id).item = null ;
        this.error = false;

      }
      this.elementdaClick = false;
    }
  }

}



@Component({
  selector: 'snack-bar-component-example-snack',
  template: `
    <span *ngIf="_warehouse">{{('error.failedWarehouse' | translate: {warehouse: _warehouse.name} )}}</span>
  `,
  styles: [`
    
  `],
})
export class ErrorComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public _warehouse: Warehouse) {}
}


@Component({
  selector: 'snack-bar-component-example-snack',
  template: `
    <span>{{'SUCCESS.ok' | translate}}</span>
  `,
  styles: [`
    
  `],
})
export class SuccesComponent {}


function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}