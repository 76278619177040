import { Component, Input } from '@angular/core';
import { Expansion } from 'src/app/shared/models/entities';

@Component({
  selector: 'app-column-icon-field-select',
  templateUrl: './column-icon-field-select.component.html',
  styleUrls: ['./column-icon-field-select.component.scss']
})
export class ColumnIconFieldSelectComponent {

  private filterOwn: any[] = [1, 6];
  constructor() { }
  @Input() item!: Expansion;
  @Input() brandId!: number;

  getFloor(x: any) {
    return Math.floor(x);
  }

  getPositionSprite(i: any): {x?: number, y?: number} {
    if (i) {
      let x = this.getFloor((i % 10)) * 21;
      let y = this.getFloor((i / 10)) * 21;
      return {x: x, y: y};
    }
    return {x: undefined, y: undefined};
  }

  public filtersIcon (brandId: number) {
    return this.filterOwn.find(id => id == brandId);
  }

}
