import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, throwError } from 'rxjs';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { SubSink } from 'subsink';
import { WarehouseMovementsService } from '../../../services/warehouse-movements.service';

@Component({
  selector: 'app-action-complete-movement',
  templateUrl: './action-complete-movement.component.html',
  styleUrls: ['./action-complete-movement.component.scss']
})
export class ActionCompleteMovementComponent implements TableActionComponent, OnDestroy {
  @Input()
  data!: ActionData;
  subSink = new SubSink();

  constructor(private movementService: WarehouseMovementsService, private store: Store<State>, private dialogSnack: DialogService) { }

  completeMovement() {
    if (this.data.item?.id) {
      const sessionId: number = this.data.item.id;
      this.subSink.add(
        this.movementService.completeMovement(sessionId).pipe(
          catchError(err => {
            this.dialogSnack.openSnack('workingMovements.errorMovement', 'update');
            return throwError(err);
          })
        ).subscribe(
          dat => {
            if ( dat.data != null) {
              this.dialogSnack.openSnack('workingMovements.updatedMovement', 'update');
              this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
            }
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

}
