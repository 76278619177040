export const environment = {
  production: false,
  test : true,
  dev: false,
  i18nPrefix: '',
  DefaultImageMagic: '/assets/img/default-card-image-magic.jpg',
  defaultDebounceTime: 50,
  apiKey: 'solo_test_proprio_test',
  cacheControl: 'no-cache',
  homePage: '/app/shop',
  coreUrl: 'http://nonenone.galactus.it',
  appVersion: 'galactus_ui.15.0.0',
  name: 'stai su test',

  timeToDefaultDate: new  Date('07/09/2023'),

  apiUrl: 'https://ui-test.galactus.it/api',

  gestionale_V1URL_PRICE: 'gestionale-test',
  gestionale_V2_CHECKOUT: 'ui-test',
  
  productStructure: {
    stockFullColumnFields: ['language', 'condition']
  },

  SELECTS_AVAIVABLE : [
    {name : 'IN', valid: true, translate: 'addLoad', icon: 'hourglass_bottom', route: 'app/warehouse/session/movement/IN', tooltip: ''},
    {name : 'TRANS', valid: true, translate: 'addTransfer', icon: 'published_with_changes', route: 'app/warehouse/session/movement/TRANS', tooltip: ''},
    {name : 'OUT', valid: true, translate: 'addUnload', icon: 'downloading', route: 'app/warehouse/session/movement/OUT', tooltip: ''},
    {name : 'Set Insert out', valid: true, translate: 'setInsert', icon: 'dynamic_form', route: 'app/set-insert-out', tooltip: 'shift.control.z'}
  ],

  menu: [
    {
      name: 'Warehouse',
      route: '/app/warehouse/stock',
      selectable: false,
      children: [
        {
          name: 'Session', route: '/app/warehouse/session', selectable: true
        },
        {
          name: 'Stock', route: '/app/warehouse/stock', selectable: true
        },
        {
          name: 'Inventory template', route: '/app/warehouse/stock_template', selectable: true
        },
        {
          name: 'Working Movements', route: '/app/warehouse/workingMovements', selectable: true
        },
        {
          name: 'Session Wrapper', route: '/app/warehouse/sessionWrapper', selectable: true
        }
      ]
    },
    {
      name: 'Prices',
      route: '/app/prices/templates',
      selectable: false,
      children: [
        {
          name: 'Templates',
          route: '/app/prices/templates', selectable: true
        },
        {
          name: 'BuyList',
          route: '/app/prices/buylist_templates', selectable: true
        },
        {
          name: 'Read Buylist',
          route: '/app/prices/read_buy_templates', selectable: true
        },
      ]
    },
    {
      name: 'Shop',
      route: '/app/shop/dashboard',
      selectable: false,
      children: [
        {
          name: 'Complete',
          route: '/app/shop/complete', selectable: true
        },
        {
          name: 'Dashboard',
          route: '/app/shop/dashboard', selectable: true
        },
        {
          name: 'Reports', roles: ['supervisor', 'admin'],
          route: '/app/shop/reports', selectable: true
        }
      ]
    },
    {
      name: 'Marketplaces',
      route: '/app/marketplaces/orders',
      selectable: false,
      children: [
        {
          name: 'Orders',
          route: '/app/marketplaces/orders', selectable: true
        },
        {
          name: 'Market Stock',
          route: '/app/marketplaces/stock', selectable: true
        }
      ]
    },

    // per ecommerce

    // {
    //   name: 'Ecommerce',
    //   route: '/app/ecommerce',
    //   children: [
    //     {
    //       name: 'View MarketPlace',
    //       route: '/app/ecommerce/orders'
    //     },
    //   ]
    // },

    // {
    //   name: 'Shippings',
    //   route: '/app/ecommerce/shippings',
    //   children: [
    //     {
    //       name: 'Methods',
    //       route: '/app/ecommerce/shippings/methods'
    //     },
    //     {
    //       name: 'Nations',
    //       route: '/app/ecommerce/shippings/nations'
    //     },
    //     {
    //       name: 'Types',
    //       route: '/app/ecommerce/shippings/type'
    //     }
    //   ]
    // }


  ]
};
