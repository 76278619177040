import {isNullOrUndefined} from 'is-what';
import * as rootReducers from '../store.reducers';
import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {BaseAction} from '../store.actions';
import { Layout, MenuItem, Sidenav } from 'src/app/layout/models/layout-models';
import { MatDrawerMode } from '@angular/material/sidenav';

export interface State extends rootReducers.State {
  layout: Layout;
}

const initialState: Layout = {
  layout: { type: 'default', theme: 'default' },
  sidenav: { mode: 'side', opened: false, opencart: false},
  header: {},
  loading: {block: false, normal: false},
  error: false,
  title: 'Gestionale15',
  menu: {items: undefined, selectedItem: undefined }
};

export enum LayoutActionType {
  SidenavButtonClick = '[Layout] SidenavButtonClick',
  SidenavMode= '[Layout] SidenavMode',
  Loading = '[Layout] Loading',
  LoadingBlock = '[Layout] LoadingBlock',
  Title = '[Layout] Title',
  Menu = '[Layout] Menu',
  MenuOpen = '[Layout] MenuOpen',
  SidenavButtonClickCart = '[Layout] SidenavButtonClickCart',
  Reset = '[Layout] Reset'
}

export function layoutReducer(state: Layout = initialState, action: BaseAction): Layout {
  switch (action.type) {
    case LayoutActionType.SidenavButtonClick: {
      let curOpened: boolean = action.payload || !initialState.sidenav.opened;

      if (state.sidenav && !isNullOrUndefined(state.sidenav.opened)) {
        curOpened = action.payload || !state.sidenav.opened;
      }

      return {
        ...state,
        sidenav: {
          mode: state.sidenav.mode,
          opened: curOpened,
          opencart: false
        }
      };
    }
    case LayoutActionType.SidenavButtonClickCart: {
      let curOpened: boolean = action.payload || !initialState.sidenav.opencart;

      if (state.sidenav && !isNullOrUndefined(state.sidenav.opencart)) {
        curOpened = action.payload || !state.sidenav.opencart;
      }

      return {
        ...state,
        sidenav: {
          mode: state.sidenav.mode,
          opened: false,
          opencart: curOpened
        }
      };
    }
    case LayoutActionType.SidenavMode: {
      let currMode: MatDrawerMode = action.payload || initialState.sidenav.mode;

      if (state.sidenav && !isNullOrUndefined(state.sidenav.mode)) {
        currMode = action.payload || state.sidenav.mode;
      }

      return {
        ...state,
        sidenav: {
          mode: currMode,
          opened: state.sidenav.opened
        }
      };
    }
    case LayoutActionType.Reset: {
      const newCurrentOpen = action.payload || null;
      return {
        ...state,
        sidenav: { mode: state.sidenav.mode, opened: newCurrentOpen != null ? newCurrentOpen : false, opencart: true }
      };
    }
    case LayoutActionType.Menu: {
      const itemsData: MenuItem[] = action?.payload || initialState.menu.items;
      return {
        ...state,
        menu: { ...state.menu,  items: itemsData}
        };
      }
    case LayoutActionType.MenuOpen: {
      const open = action?.payload;
      return {
        ...state,
        menu: { ...state.menu, selectedItem: open}
      };
    }
    case LayoutActionType.Title: {

      return {
        ...state,
        title: action.payload || initialState.title
      };

    }
    case LayoutActionType.Loading: {
      const loadingStatus: boolean = action.payload === null || action.payload === undefined ? {...initialState.layout} : action.payload;
      const loading: boolean = action.payload === null || action.payload === undefined ? {...initialState.loading} : action.payload;

      return {
        ...state,
        loading: {normal: loading, block: initialState.loading.block}
      };

    }
    case LayoutActionType.LoadingBlock: {
      const loading: boolean = action?.payload;

      return {
        ...state,
        loading: {block: loading, normal: state.loading.normal}
      };

    }
    default:
  return state;
  }

}


export const FEATURE_NAME = 'layout';
export const selectLayout = createFeatureSelector<State, Layout>(
  FEATURE_NAME
);

export const selectLayoutTitle: MemoizedSelector<State, string>  = createSelector(selectLayout, (state: Layout) => state?.title );
export const selectLayoutSidenav: MemoizedSelector<State, Sidenav>  = createSelector(selectLayout, (state: Layout) => state.sidenav);
export const selectSidenavMenu: MemoizedSelector<State,  { selectedItem?: MenuItem, items?: MenuItem[]}> = createSelector(selectLayout, (state: Layout) => state.menu);
export const selectOpenMenu: MemoizedSelector<State, MenuItem | undefined> = createSelector(selectLayout, (state: Layout) => state.menu.selectedItem);
export const selectLoading: MemoizedSelector<State, any > = createSelector(selectLayout, (state: Layout) => state.loading);

