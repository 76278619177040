import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynacrudApi } from 'src/app/core/models/dynacrud';
import { Entity } from 'src/app/shared/models/entities';
import { environment } from 'src/environments/environment';

export interface statusSession {
  count: number;
  status: StatusDescription | null | undefined;

}

export interface StatusDescription extends Entity {
  description: string | null | undefined;

}

@Injectable({
  providedIn: 'root'
})
export class SessionStatusService {

  url = 'sessionStatuses/statusCount'

  constructor(private http: HttpClient) { }

  public getStatusCount () : Observable< DynacrudApi< statusSession[] >> {

    return this.http.get<DynacrudApi< statusSession[] >>(environment.apiUrl + '/v2/' + this.url);

  }
}
