import {Injectable, Type} from '@angular/core';
import {DynacrudWeakDataService} from './DynacrudWeakDataService';
import {DynacrudApiWrapper} from '../../core/api/dynacrud-api';
import { Brand } from '../models/entities';

@Injectable({
  providedIn: 'root'
})
export class BrandService extends DynacrudWeakDataService<Brand> {

  constructor(dynacrudApiWrapper: DynacrudApiWrapper) {
    super('brands', BrandService, dynacrudApiWrapper);
  }

  getType(): Type<Brand> | string {
    return 'brand';
  }
}
